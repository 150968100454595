module External.Fable.Recharts

open Fable.Core.JsInterop
open Fable.React
open Fable.React.Helpers
open Fable.Recharts
open Fable.Recharts.Props
open Fulma
open Fulma.Extensions.Wikiki

open Shared
open PreZero
open Calculation
open Rounding

// abbreviations
module P = Fable.React.Props
let Style = P.Style
let Color = P.CSSProp.Color

// chart data details are called payload
type Payload =
    { fill : string
      dataKey : string
      name : string
      color : string
      value : float
      payload : obj }

type BarFillColor =
    | JouwImpact
    | SectorAvg with
    member this.ColorCode =
        match this with
        | JouwImpact -> "#003cc1" // PreZero Royal Blue
        | SectorAvg  -> "#5ac5f2" // PreZero Light Blue

// custom tooltip props
type CustomTooltipProps =
    { active : bool
      offset : float
      viewBox : obj
      label : int
      payload : Payload array } with
    member this.Tooltip (additionalContent: ReactElement option) (ownLabel, sectorLabel) =
        let getValueFromPayload (p: Payload) : string =
            let v : float = unbox p.payload?(p.dataKey)
            v |> (roundTwoSignificantDigits >> formatNL)

        // props.payload contains own impact (at index 0) and benchmark impact (at index 1)
        // only consider own impact for now
        let ownValue, sectorValue =
            this.payload
            |> Array.map getValueFromPayload
            |> fun a -> a.[0], a.[1]

        Field.div [ Field.CustomClass "custom-tooltip" ] [
            Heading.h5 [ ]
                [ str (this.label.ToString()) ]
            additionalContent |> function None -> nothing | Some e -> e
            Text.p
                [ Props [ P.ClassName "jouw-impact" ] ]
                [ str (sprintf "%s: €%s" ownLabel ownValue ) ]
            Text.p
                [ Props [ P.ClassName "sector-avg" ] ]
                [ str (sprintf "%s: €%s" sectorLabel sectorValue) ]
        ]

let private tooltipInterpretation (impacts : Map<ImpactId, Impact>) (props : CustomTooltipProps) =
    match props.active with
    | false ->
        nothing
    | true ->

        let interpretationCard' (value : float) =
            impacts
            |> Map.toList
            |> List.map snd
            |> List.filter (fun impact -> impact.Name = props.label.ToString())
            |> List.head
            |> (fun impact ->
                Components.Interpretation.interpretationCard impact.Interpretation value)

        let ownImpactValue =
            props.payload.[0]
            |> (fun x -> unbox x.payload?(x.dataKey))

        props.Tooltip
            (Some <| interpretationCard' ownImpactValue)
            ("Jouw negatieve milieu-impact", "Gemiddelde milieu-impact sector")

let private tooltipValue (props : CustomTooltipProps) =
    match props.active with
    | false ->
        nothing
    | true ->
        props.Tooltip
            None
            ("Jouw negatieve milieu-impact", "Gemiddelde milieu-impact sector")

let private tooltipScenario (props : CustomTooltipProps) =
    match props.active with
    | false ->
        nothing
    | true ->
        props.Tooltip
            None
            ("Je huidige milieu-impact", "Je milieu-impact na interventie")

type BarchartData =
    {
        Name : string
        Impact : float
        BenchmarkImpact : float
    }
    with
    static member parseResultsAsBarchartData (by: ByDataEntry) (results : CalculatedImpact) (benchmarkResults : CalculatedImpact) (staticContent : StaticContent) =
        let unboxIdPerResultImpact (res: CalculatedImpact) =
            match by with
            | ByWasteStreamId -> res.ByWasteStreamId |> Array.map (fun ((WasteStreamId ii), mm) -> ii, mm)
            | ByImpactData    -> res.ByImpactId |> Array.map (fun ((ImpactId ii), mm) -> ii, mm)

        let showDataName (ii: int) =
            match by with
            | ByWasteStreamId -> showWasteStreamName staticContent (WasteStreamId ii)
            | ByImpactData    -> showImpactName staticContent (ImpactId ii)

        unboxIdPerResultImpact benchmarkResults
        |> Array.map
            (
                fun (idBk, benchmarkImpact) ->
                    let impact =
                        unboxIdPerResultImpact results
                        |> Array.tryFind (fun (idRes, _) -> idRes = idBk)
                        |> Option.map snd
                        |> Option.defaultValue (MonetizedImpact 0.<eur>)

                    { Name            = showDataName idBk
                      Impact          = impact.Float
                      BenchmarkImpact = benchmarkImpact.Float }
            )
        |> Array.sortByDescending (fun result -> result.Impact)

    static member BarChartResults
        (by: ByDataEntry)
        (results : string * CalculatedImpact)
        (benchmarkResults : string * CalculatedImpact)
        (staticContent : StaticContent)
        (onClick : string -> Browser.Types.MouseEvent -> unit)
        (customTooltip: obj option)
        (xaxisHeight: float) =

        let chartH, chartW, bW = 300. + xaxisHeight, 500., 20.

        let titleOwnImpact, dataOwnImpact = results
        let titleComparisonImpact, dataComparisonImpact = benchmarkResults

        let barchartData = BarchartData.parseResultsAsBarchartData by dataOwnImpact dataComparisonImpact staticContent

        let onClickBarEvent (data: obj) (index: int) (ev: Browser.Types.MouseEvent) : unit =
            let name = barchartData |> Array.item index |> fun d -> d.Name
            // printfn "data = %A" <| (barchartData |> Array.item index)
            onClick name ev

        let labels =
            let formatter : obj -> obj =
                fun v ->
                    let v' : float = unbox v
                    System.Math.Round v'
                    |> unbox
            labelList [ LabelList.DataKey "Impact"
                        LabelList.Position "top"
                        LabelList.Offset 10.
                        LabelList.Formatter formatter ] [ ]
        let legend =
            legend [ Legend.VerticalAlign "top"
                     Legend.Align "right"
                     Legend.IconType ShapeType.Circle ] [ ]
        let tooltip =
            let content =
                match customTooltip with
                | Some tp -> Tooltip.Content tp
                | None -> Tooltip.Content tooltipValue
            tooltip [ content; Tooltip.Cursor {| fill = "rgb(150, 219, 248, 0.2)" |} ] [ ]
        let xaxis =
            xaxis [ Cartesian.Type "category"
                    Cartesian.TickLine false
                    Cartesian.DataKey "Name"
                    Cartesian.Height xaxisHeight
                    Cartesian.Custom ("angle", -37.)
                    Cartesian.Custom ("textAnchor", "end")
                    Cartesian.Custom ("interval", 0)
            ] [ ]
        let yaxis =
            yaxis [ Cartesian.Type "number"
                    Cartesian.TickLine false
                    Cartesian.Tick true
                    Cartesian.AxisLine false
                    Cartesian.Unit "€" ] [ ]
        let cartesianGrid =
            cartesianGrid [ Cartesian.Horizontal true
                            Cartesian.Vertical false ] [ ]

        let barClientImpact =
            bar [ Cartesian.BarSize bW
                  Cartesian.DataKey "Impact"
                  Cartesian.Name (titleOwnImpact + " (EUR)")
                  Cartesian.OnClick onClickBarEvent
                  P.Fill JouwImpact.ColorCode ]
                [ labels ]

        let barSectorImpact =
            bar [ Cartesian.BarSize bW
                  Cartesian.DataKey "BenchmarkImpact"
                  Cartesian.Name (titleComparisonImpact + " (EUR)")
                  Cartesian.OnClick onClickBarEvent
                  P.Fill SectorAvg.ColorCode ]
                [ ]

        responsiveContainer [ Responsive.MinWidth chartW; Responsive.Height chartH ] [
            barChart [ Chart.Data barchartData
                       Chart.Margin { bottom = 0.; top = 20.; left = 20.; right = 20. } ]
                [
                    tooltip
                    cartesianGrid
                    barClientImpact
                    barSectorImpact
                    xaxis
                    yaxis
                    legend
                ]
        ]

and ByDataEntry =
    | ByWasteStreamId
    | ByImpactData

module ComparisonData =

    let barChartPerImpact
        (results : {| OwnImpact : string * CalculatedImpact; ComparisonImpact : string * CalculatedImpact |})
        (staticContent : StaticContent)
        (onClick : string -> Browser.Types.MouseEvent -> unit) =
            BarchartData.BarChartResults
                ByImpactData
                results.OwnImpact
                results.ComparisonImpact
                staticContent
                onClick
                (Some (tooltipInterpretation staticContent.Impacts :> obj))
                220.

    let barChartPerWasteStream
        (results : {| OwnImpact : string * CalculatedImpact; ComparisonImpact : string * CalculatedImpact |})
        (staticContent : StaticContent)
        (onClick : string -> Browser.Types.MouseEvent -> unit) =
            BarchartData.BarChartResults
                ByWasteStreamId
                results.OwnImpact
                results.ComparisonImpact
                staticContent
                onClick
                None
                110.

module InterventionData =
    type ResultDataSplit = {
        Name : string
        OriginalInput : float
        InputAfterIntervention : float
    }

    let resultsAsWasteStreamData (results : CalculatedImpact) (benchmarkResults : CalculatedImpact) (staticContent : StaticContent) : ResultDataSplit [] =

        benchmarkResults.ByWasteStreamId
        |> Array.map  (fun (wasteStreamId, benchmarkImpact) ->
            let impact =
                results.ByWasteStreamId
                |> Array.tryFind (fun (id,_) -> id = wasteStreamId)
                |> Option.map snd
                |> Option.defaultValue (MonetizedImpact 0.<eur>)

            { Name = showWasteStreamName staticContent wasteStreamId
              OriginalInput = impact.Float
              InputAfterIntervention = benchmarkImpact.Float })

        |> Array.sortByDescending (fun result -> result.InputAfterIntervention)


    let resultsAsImpactData (results : CalculatedImpact) (benchmarkResults : CalculatedImpact) (staticContent : StaticContent) : ResultDataSplit [] =

        benchmarkResults.ByImpactId
        |> Array.map  (fun (impactId, benchmarkImpact) ->
            let impact =
                results.ByImpactId
                |> Array.tryFind (fun (id, _) -> id = impactId)
                |> Option.map snd
                |> Option.defaultValue (MonetizedImpact 0.<eur>)

            { Name = showImpactName staticContent impactId
              OriginalInput = impact.Float
              InputAfterIntervention = benchmarkImpact.Float})

        |> Array.sortByDescending (fun result -> result.InputAfterIntervention)


    let barChartPerWasteStream (results : CalculatedImpact) (benchmarkResults : CalculatedImpact option) (staticContent : StaticContent)  (onClick : string -> Browser.Types.MouseEvent -> unit) =
        match benchmarkResults with
        | Some benchmarkResults ->
            barChart
                [ Chart.Width 500.
                  Chart.Height
                    (results.SeparatedWasteImpact |> Map.toList |> List.length |> float |> (fun x -> x * 5.))
                  Chart.Data (resultsAsWasteStreamData results benchmarkResults staticContent)
                  Chart.Layout Vertical ]
                [
                  legend [Legend.Align "right"] [ ]

                  tooltip [Tooltip.Content tooltipScenario] [ ]

                  yaxis [Cartesian.Type "category"
                         Cartesian.TickLine false
                         Cartesian.DataKey "Name"
                         Cartesian.Width 180.]
                        []
                  xaxis [Cartesian.Type "number"
                         Cartesian.TickLine true
                         Cartesian.Tick true
                         Cartesian.AxisLine true ]
                        []
                  bar [ Cartesian.BarSize 10.
                        Cartesian.DataKey "OriginalInput"
                        P.Fill JouwImpact.ColorCode
                        Cartesian.Name "Je impact nu" ] [
                    yield!
                      staticContent.WasteStreams
                      |> Map.toList
                      |> List.map (fun (id, wasteStream) ->
                          cell [
                            P.OnClick (onClick wasteStream.Name)
                            P.Fill JouwImpact.ColorCode
                            ] [] )
                  ]
                  bar [ Cartesian.BarSize 10.
                        Cartesian.DataKey "InputAfterIntervention"
                        P.Fill SectorAvg.ColorCode
                        Cartesian.Name "Je impact na interventie" ] [
                    yield!
                      staticContent.WasteStreams
                      |> Map.toList
                      |> List.map (fun (id, wasteStream) ->
                          cell [
                            P.OnClick (onClick wasteStream.Name)
                            P.Fill SectorAvg.ColorCode
                            ] [] )
                  ]
                ]
        | None ->
            nothing

    let barChartPerImpact (results : CalculatedImpact) (benchmarkResults : CalculatedImpact) (staticContent : StaticContent) (onClick : string -> Browser.Types.MouseEvent -> unit) =
            barChart
                [ Chart.Width 500.
                  Chart.Height 500.
                  Chart.Data (resultsAsImpactData results benchmarkResults staticContent)
                  Chart.Layout Vertical ]
                [
                  legend [Legend.Align "right"]
                         []
                  tooltip [Tooltip.Content tooltipScenario]
                          [ ]
                  yaxis [Cartesian.Type "category"
                         Cartesian.TickLine false
                         Cartesian.DataKey "Name"
                         Cartesian.Width 180.]
                        []
                  xaxis [Cartesian.Type "number"
                         Cartesian.TickLine true
                         Cartesian.Tick true
                         Cartesian.AxisLine true ]
                        []
                  bar [ Cartesian.BarSize 20.
                        Cartesian.DataKey "OriginalInput"
                        P.Fill JouwImpact.ColorCode
                        Cartesian.Name "Je impact nu" ] [
                    yield!
                      staticContent.Impacts
                      |> Map.toList
                      |> List.map (fun (id, impact) ->
                          cell [
                            P.OnClick (onClick impact.Name)
                            P.Fill JouwImpact.ColorCode
                            ] [] )
                  ]
                  bar [ Cartesian.BarSize 20.
                        Cartesian.DataKey "InputAfterIntervention"
                        P.Fill SectorAvg.ColorCode
                        Cartesian.Name "Je impact na interventie" ] [
                    yield!
                      staticContent.Impacts
                      |> Map.toList
                      |> List.map (fun (id, impact) ->
                          cell [
                            P.OnClick (onClick impact.Name)
                            P.Fill SectorAvg.ColorCode
                            ] [] )
                  ]
                ]
