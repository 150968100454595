module Components.Modal

open Fulma
open Fable.React
open Fable.React.Props

let modalTemplate isActive title body (dispatch: 'Msg -> unit) (msg: 'Msg) =
    Modal.modal [ Modal.IsActive isActive ] [
        Modal.background [ Props [ OnClick (fun _ -> dispatch msg) ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [
                    Text.p [] [ str title ]
                ]
                Delete.delete [ Delete.OnClick (fun _ -> dispatch msg) ] []
            ]
            Modal.Card.body [ ] body
            Modal.Card.foot [
                Props [ Style [ Padding "0px"; BorderTop "none" ] ]
            ] [ ]
        ]
    ]