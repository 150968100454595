module App

open Index
open UrlUpdate

open Elmish
open Elmish.React
open Elmish.Navigation



#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

Program.mkProgram init update view
|>Program.toNavigable (UrlParser.parseHash pageParser) urlUpdate
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run