module VerkleinImpact.Components

open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fable.Core.JsInterop
open Fulma
module Slider = Fulma.Extensions.Wikiki.Slider
module Switch = Fulma.Extensions.Wikiki.Switch

open External
open External.React.Datasheet
open External.React.Datasheet.VerkleinImpactGrid
open PreZero
open Shared


let onCellsChangedResidual (model : Model) (dispatch : Msg -> unit) : React.Datasheet.Props =
    OnCellsChanged (UpdateGridResidual >> dispatch)

let gridResidual (viewSetting : NonPublicWasteStreamSetting) (model: Model) (dispatch : Msg -> unit) =
    External.React.Datasheet.VerkleinImpactGrid.grid [
        Data (wasteAmountInputToCellsResidual viewSetting model.Interventions)
        ValueRenderer valueRenderer
        valueViewerResidual (UpdateGridResidual >> dispatch)
        onCellsChangedResidual model dispatch
        ] [ ]

let modalManualResidualWastes (model: Model) (userProfile: UserProfile option) (dispatch: Msg -> unit) =
    let title, body =
        match model.Modal with
        | Some Modal.ResidualWasteInput ->
            "Vul de samenstelling van je bedrijfsafval in",
            match userProfile with
            | Some user ->
                [
                    gridResidual ViewAll model dispatch

                    // this button should save the manual residual waste amount changes to db
                    Field.div [ Field.Props [ Style [ MarginTop "1em" ] ] ] [
                        Button.button
                            [ Button.Color IsPrimary
                              Button.OnClick (fun _ ->
                                model.ProjectSelected
                                |> function
                                   | Some project -> dispatch (SaveProject project)
                                   | None -> ()) ]
                            [ str "Opslaan" ]
                    ]
                ]
            | None ->
                [
                    gridResidual ViewPublicOnly model dispatch
                    Level.level [] []
                    p [ ] [
                        str "Wil je meer afvalstromen onderscheiden in je bedrijfsafval? Maak dan "
                        a [ Href Shared.Route.register ] [ str "hier" ]
                        str " een account aan."
                        ]
                ]

        | Some (Modal.ProjectSavedSuccess projectName) ->
            "Project opgeslagen!",
                [ str (sprintf "Je nieuwe bedrijfsafvalverdeling voor \"%s\" is succesvol opgeslagen." projectName) ]

        | _ -> "name", [ nothing ]

    Components.Modal.modalTemplate
        model.Modal.IsSome
        title
        body
        dispatch
        (SetModal None)


let projectSelection (projects: (ProjectId * ProjectInfo) list) (projectSelected: Project option) (dispatch: Msg -> unit) =
    Select.select [ ] [
        select [
            OnChange (fun ev ->
                    SelectProject (ProjectId (int ev.Value)) |> dispatch)
        ] [
            for (projectId, project) in projects do
                option [
                    if projectSelected |> Option.bind (fun p -> p.Id) = Some projectId then Selected true
                    Value projectId.Value
                ] [ str project.Name ]
        ]
    ]

let rowInterventionResidual
    (interventions: Intervention list)
    (currentImpactAll : CalculatedImpact)
    (scenarioImpactAll : CalculatedImpact)
    (dispatch: Msg -> unit) =

    let printWeight valueInKg =
        // the current design assumes unit is always Kg
        match valueInKg with
        | v when v >= 1000000. -> v / 1000000., Unit.Kton
        | v when v >= 1000. -> v / 1000., Unit.Ton
        | v -> v, Unit.Kg
        |> fun (v, unit) -> sprintf "%s %s" (System.Math.Round v |> Rounding.formatNL) unit.Abbr

    let manualResidualWasteButton (dispatch: Msg -> unit) =
        Field.div [ Field.Props [ Style [ Margin "auto .5em" ] ] ] [
            Button.button
                [ Button.OnClick (fun _ -> SetModal (Some Modal.ResidualWasteInput) |> dispatch)
                  Button.Color IsPrimary
                  Button.Size IsSmall ]
                [ Icon.icon [ Icon.Size IsSmall; Icon.Modifiers [ Modifier.TextColor IsWhite ] ]
                    [ Fa.i [ Fa.Solid.Edit ] [ ] ] ]
        ]

    let currentAmount =
        interventions
        |> List.sumBy (fun intervention ->
            intervention.AmountResidual.Current |> Option.defaultValue 0.)

    let scenarioAmount =
        interventions
        |> List.sumBy (fun intervention ->
            intervention.AmountResidual.Scenario |> Option.defaultValue 0.)

    let currentImpact =
        currentImpactAll.ResidualWasteImpact
        |> Map.toList
        |> List.sumBy (fun (_, impact) -> impact.Float)

    let scenarioImpact =
        scenarioImpactAll.ResidualWasteImpact
        |> Map.toList
        |> List.sumBy (fun (_, impact) -> impact.Float)

    let scenarioCO2 =
        scenarioImpactAll.ResidualWasteImpact
        |> Map.toList
        |> List.sumBy (fun ((wsId, impactId), impact) ->
            if impactId = CalcSetting.climateWasteId then
                impact.InEuro / CalcSetting.climateMonetisationFactor / 1.<kg>
            else 0.
        )


    tr [ Style [ BorderBottom "2px solid lightgrey" ] ] [
        // SOORT AFVAL
        td [ ] [ str "Bedrijfsafval*" ]

        // AFVALPRODUCTIE AANPASSEN
        td [ ] [
            Level.level [ ] [
                Level.item [ ]
                    [ str (printWeight scenarioAmount)]
                Level.item [ ] [ manualResidualWasteButton dispatch ]
            ]
        ]

        // HUIDIG
        td [ Class "numerical-value"; Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
            str (printWeight currentAmount)
        ]

        // GESCHEIDEN
        td [ Class "has-text-centered" ] [
            // empty for residual wastes
        ]

        // CO2-UITSTOOT
        td [ Class "numerical-value" ] [
            p [ Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
                str (scenarioCO2 |> printWeight)
              ]
        ]

        // IMPACT IN EURO'S
        td [ Class "numerical-value" ] [
            p [ Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
                str (scenarioImpact |> Rounding.formatEurNL)
                span [ Style [ Width "40px"; Display DisplayOptions.InlineBlock ] ] [
                    if scenarioImpact = currentImpact then
                        nothing
                    elif scenarioImpact > currentImpact then
                        Icon.icon [ Icon.Props [ Style [ Color "red" ] ] ]
                            [ Fa.i [ Fa.Solid.ArrowUp ] [ ] ]
                    else
                        Icon.icon [ Icon.Props [ Style [ Color "green" ] ] ]
                            [ Fa.i [ Fa.Solid.ArrowDown ] [ ] ]
                ]
            ]
        ]
    ]

let rowIntervention
    (isLoggedIn : bool)
    (intervention: Intervention)
    (currentImpactAll : CalculatedImpact)
    (scenarioImpactAll : CalculatedImpact)
    (dispatch: Msg -> unit) =

    let switchId = sprintf "switch-%s" intervention.Name

    let currentAmountSeparated  = intervention.Amount.Current |> Option.defaultValue 0.
    let currentAmountResidual   = intervention.AmountResidual.Current |> Option.defaultValue 0.
    let scenarioAmountSeparated = intervention.Amount.Scenario |> Option.defaultValue 0.
    let scenarioAmountResidual  = intervention.AmountResidual.Scenario |> Option.defaultValue 0.

    let currentSliderValue =
        if intervention.IsSeparated.Scenario then currentAmountSeparated else currentAmountResidual
    let scenarioSliderValue =
        if intervention.IsSeparated.Scenario then scenarioAmountSeparated else scenarioAmountResidual

    let sliderMaxByCurrentAmount =
        match max currentAmountSeparated currentAmountResidual with
        | 0. -> 1000. // ideally based on average of other waste streams
        | v -> v * 3.0

    let maxScenario = max scenarioAmountResidual scenarioAmountSeparated

    let sliderMax =
        if maxScenario > sliderMaxByCurrentAmount then
            maxScenario
        else
            sliderMaxByCurrentAmount

    let currentImpact =
        if intervention.IsSeparated.Current then
            currentImpactAll.SeparatedWasteImpact
        else
            currentImpactAll.ResidualWasteImpact
        |> Map.toList
        |> List.filter (fun ((wasteStreamId, _), _) -> wasteStreamId = intervention.WasteStreamId )
        |> List.sumBy (fun (_, impact) -> impact.Float )

    let scenarioImpact =
        if intervention.IsSeparated.Scenario then
            scenarioImpactAll.SeparatedWasteImpact
        else
            scenarioImpactAll.ResidualWasteImpact
        |> Map.toList
        |> List.filter (fun ((wasteStreamId, _), _) -> wasteStreamId = intervention.WasteStreamId )
        |> List.sumBy (fun (_, impact) -> impact.Float )

    let scenarioCO2 =
        if intervention.IsSeparated.Scenario then
            scenarioImpactAll.SeparatedWasteImpact
        else
            scenarioImpactAll.ResidualWasteImpact
        |> Map.toList
        |> List.filter (fun ((wasteStreamId, _), _) -> wasteStreamId = intervention.WasteStreamId)
        |> List.tryFind (fun ((_, impactId), _) -> impactId = CalcSetting.climateWasteId)
        |> Option.map snd
        |> Option.defaultValue (MonetizedImpact 0.<eur>)
        |> function | MonetizedImpact x -> x / CalcSetting.climateMonetisationFactor
        |> fun v -> v * 1.</kg>

    let isResidualWasteStream = intervention.WasteStreamId = CalcSetting.residualWasteStreamId
    let isConstructionWasteStream = intervention.WasteStreamId = CalcSetting.constructionWasteStreamId
    let rowDisabled = not (intervention.Public || isLoggedIn)

    let printWeight valueInKg =
        // the current design assumes unit is always Kg
        match valueInKg with
        | v when v >= 1000000. -> v / 1000000., Unit.Kton
        | v when v >= 1000. -> v / 1000., Unit.Ton
        | v -> v, Unit.Kg
        |> fun (v, unit) -> sprintf "%s %s" (System.Math.Round v |> Rounding.formatNL) unit.Abbr

    let stepSize =
        match sliderMax with
        | v when v >= 1000000. -> 10000.
        | v when v >= 100000. -> 1000.
        | v when v >= 10000. -> 100.
        | v when v >= 1000. -> 10.
        | v -> 1.

    tr [ ] [
        // SOORT AFVAL
        td [ ] [ str intervention.Name ]

        // AFVALPRODUCTIE AANPASSEN
        td [ ] [
            Field.div [ ] [
                Text.p
                    [ Props [ Style [ FontSize ".75em"; PaddingBottom "0.25em"; TextAlign TextAlignOptions.Center ] ] ]
                    [ str (printWeight scenarioSliderValue) ]
                Slider.slider
                    [ Slider.IsCircle
                      Slider.OnChange (fun ev ->
                        dispatch (UpdateIntervention (intervention.WasteStreamId, ev.Value |> float)))
                      Slider.Value scenarioSliderValue
                      Slider.Step stepSize
                      Slider.Max sliderMax
                      Slider.IsFullWidth
                      Slider.Props [ Style [ Width "100%" ] ]
                      Slider.Disabled rowDisabled
                    ]
            ]
        ]

        // HUIDIG
        td [ Class "numerical-value" ] [
            str (printWeight currentSliderValue)
        ]

        // GESCHEIDEN
        td [ Class "has-text-centered" ] [
            if isResidualWasteStream || isConstructionWasteStream then nothing else
                Switch.switchInline
                    [ Switch.Id switchId
                      Switch.Checked intervention.IsSeparated.Scenario
                      Switch.OnChange
                        ( ( fun _ -> ToggleWasteStreamSeparated intervention.WasteStreamId ) >> dispatch )
                      Switch.IsRounded
                      Switch.Size IsSmall
                      Switch.Disabled rowDisabled ]
                    [ ]
        ]

        // CO2-UITSTOOT
        td [ Class "numerical-value" ] [
            p [ Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
                str (printWeight scenarioCO2)
                // span [ Style [ Width "40px"; Display DisplayOptions.InlineBlock ] ] [
                //     if scenarioCO2 = currentCO2 then
                //         nothing
                //     elif scenarioCO2 > currentCO2 then
                //         Icon.icon [ Icon.Props [ Style [ Color "red" ] ] ]
                //             [ Fa.i [ Fa.Solid.ArrowUp ] [ ] ]
                //     else
                //         Icon.icon [ Icon.Props [ Style [ Color "green" ] ] ]
                //             [ Fa.i [ Fa.Solid.ArrowDown ] [ ] ]
                // ]
              ]
        ]

        // IMPACT IN EURO'S
        td [ Class "numerical-value" ] [
            p [ Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
                str (scenarioImpact |> Rounding.formatEurNL)
                span [ Style [ Width "40px"; Display DisplayOptions.InlineBlock ] ] [
                    if scenarioImpact = currentImpact then
                        nothing
                    elif scenarioImpact > currentImpact then
                        Icon.icon [ Icon.Props [ Style [ Color "red" ] ] ]
                            [ Fa.i [ Fa.Solid.ArrowUp ] [ ] ]
                    else
                        Icon.icon [ Icon.Props [ Style [ Color "green" ] ] ]
                            [ Fa.i [ Fa.Solid.ArrowDown ] [ ] ]
                ]
            ]
        ]
    ]

module Texts =
    let explanationHowItWorks =
        div [ ] [
            p [ ] [
                str "Op deze pagina kun je inventariseren hoe je minder milieu-impact kan maken. "
                str "Dit kun je doen door meer afval te gaan scheiden, of minder afval te produceren."
            ]
            p [ ] [
                br [ ]
            ]
            p [ ] [
                str "Door in onderstaande tabel de button scheiden om te zetten en de slider te verschuiven naar jouw gewenste gewicht, "
                str "kun je zien wat je nieuwe milieu-impact is."
            ]
            br []
            div [ Style [ Position PositionOptions.Relative; PaddingBottom "56.25%" ] ] [
                iframe [
                    Style [ Position PositionOptions.Absolute; Width "100%"; Height "100%"; Left "0px"; Top "0px"]
                    Title "Instructievideo Impact Checker"
                    Src "https://www.youtube.com/embed/McLenEnDT50"
                    Controls true // show controls on hover
                ] []
            ]

        ]

    let playWithInterventions =
        Text.p [ ] [
            str """
            Niet iedere afvalstroom is even vervuilend.
            Daarnaast kan de toegevoegde waarde van het recyclen sterk verschillen per materiaalsoort.
            Je kunt hier je afval herverdelen over de afvalstromen om zo een beeld te krijgen
            van de milieuwinst die te behalen valt door meer of anders te gaan scheiden."""
        ]

    let verkleinJeImpactTipsIntro =
        str """
            Het begint met inzicht, van daaruit kan je als bedrijf doelen stellen.
            Hoe zorg je ervoor dat de impact van jullie bedrijfsafval op het milieu minder wordt?
            Hoe zorg je ervoor dat je stappen zet naar Zero Waste?
            We hebben vijf tips op een rij gezet.
            """

    let moreTips =
        Text.p [] [
            str """
                We dagen je graag uit:
                hoe ver durf je te gaan met het verkleinen van jullie impact?
                Bij veel bedrijven, groot en klein, hebben we succesvolle optimalisaties doorgevoerd.
                We helpen de hoeveelheid restafval drastisch verlagen.
                En we zorgen voor zichtbaarheid en bewustwording in jullie bedrijf.
                """
        ]


    let contactPart =
        Text.p [ ] [
            str """
            We snappen het goed als je hier nog vragen over hebt.
            Neem gerust verblijvend contact met ons op.
            """
        ]

module ImprovementTips =

    open Fulma
    open Fable.React
    open Fable.React.Props

    type ImprovementTips =
        {
            Id       : int
            Title    : string
            Tips     : string
        }
        with
        static member Card (tips: ImprovementTips) (model: Model) (dispatch: Msg -> unit) =
            let isHidden =
                model.IsHiddenImproventTips
                |> Map.find tips.Id
            let icon =
                isHidden |> function | true -> Fa.Solid.Plus | false -> Fa.Solid.Minus

            Message.message [ Message.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ] ] [
                Message.header [ Props [ OnClick (fun ev -> dispatch (ToggleShowImprovementTipId tips.Id)) ] ] [
                    str tips.Title
                    Icon.icon [  ] [ Fa.i [ icon ] [ ] ]
                    ]
                Message.body [ Props [ Hidden isHidden ] ]
                    [ Content.content [ ] [ str tips.Tips ] ]
            ]

    let improvementTips =
        [
            {
                Id       = 0
                Title    = "Ga meer afval scheiden"
                Tips     = """
                Met de Impact Checker zie je precies hoeveel impact een afvalstroom heeft.
                Verdeel het afval maar eens anders.
                Merk op dat het uitmaakt of papier, koffiedik of swill in het restafval zit.
                Door slim afval te scheiden, kan je dus je impact verlagen.
                """
            }
            {
                Id       = 1
                Title    = "Investeer in goede afvalscheidingsmiddelen"
                Tips     = """
                Goed afval scheiden vraagt aandacht.
                Het is belangrijk dat mensen afval makkelijk kúnnen scheiden.
                Of dat nou in een kantooromgeving, productievloer of keuken is.
                Houd rekening met de hoeveelheid ruimte die beschikbaar is en maak het mensen gemakkelijk.
                """
            }
            {
                Id       = 2
                Title    = "Koop anders in"
                Tips     = """
                Soms is het eenvoudig om afval te voorkomen. Bijvoorbeeld door anders in te kopen.
                Duurzamer of minder. Want alles wat er aan de achterkant als afval uitkomt,
                is aan de voorkant naar binnen gebracht.
                """
            }
            {
                Id       = 3
                Title    = "Ga aan de slag met gedragsverandering"
                Tips     = """
                Veranderingen kosten tijd. Als je ander gedrag wenst, kan je mensen daarbij helpen.
                Met goede communicatiemiddelen bijvoorbeeld.
                Door resultaten zichtbaar te maken en de impact te laten zien.
                """
            }
            {
                Id       = 4
                Title    = "Maak de waarde van afval zichtbaar"
                Tips     = """
                Afval is waardevol.
                Veel afval kunnen we weer verwerken tot nieuwe grondstoffen.
                Keer op keer.
                Maar nog lang niet iedereen weet dat.
                Het helpt om dat zichtbaar te maken.
                Bijvoorbeeld door gerecyclede producten te tonen die gemaakt zijn van afval.
                Of zet ze in als relatiegeschenk.
                """
            }
        ]