module Calculation

open Shared
open PreZero

let showWasteStreamName (staticContent : StaticContent) (wasteStreamId : WasteStreamId) =
    staticContent.WasteStreams
    |> Map.tryFind wasteStreamId
    |> Option.map (fun wasteStream ->
        if wasteStream.Name = CalcSetting.dangerousWasteStreamName then
            wasteStream.Name + "*"
        else wasteStream.Name)
    |> Option.defaultValue (sprintf "No waste stream found for id: %i" wasteStreamId.Value)

let showImpactName (staticContent : StaticContent) (impactId : ImpactId) =
    staticContent.Impacts
    |> Map.tryFind impactId
    |> Option.map (fun impact -> impact.Name)
    |> Option.defaultValue (sprintf "No impact found for id: %i" impactId.Value)

let tryShowImpactIcon (staticContent : StaticContent) (impactId : ImpactId) =
    staticContent.Impacts
    |> Map.tryFind impactId
    |> Option.map (fun impact -> impact.Interpretation)

let showInterpretation (staticContent : StaticContent) (impactId : ImpactId) (resultValue : float) =
    let interpretations =
        staticContent.Impacts
        |> Map.tryFind impactId
        |> Option.map (fun impact -> impact.Interpretation)

    let numberOfItems, description =
        match resultValue * 1.<eur>, interpretations with
        | v, Some i when abs(v) * i.Large.ConversionFactorFromEur > 1.0<item> ->
            v * i.Large.ConversionFactorFromEur, i.Large.Name
        | v, Some i when abs(v) * i.Medium.ConversionFactorFromEur > 1.0<item> ->
            v * i.Medium.ConversionFactorFromEur, i.Medium.Name
        | v, Some i ->
            v * i.Small.ConversionFactorFromEur, i.Small.Name
        | v, None ->
            eprintfn "FAILED TO GET INTERPRETATION FROM GIVEN IMPACT ID"
            0.<item>, "FAILED TO GET INTERPRETATION FROM GIVEN IMPACT ID"

    {|
        NumberOfItemsRawValue = numberOfItems * 1.</item>
        FaIconCode  = interpretations |> Option.map (fun i -> i.IconCode)
        Description = description
    |}

let showSectorName (staticContent : StaticContent) (sectorId : SectorId) (defaultString : string) =
    staticContent.Sectors
    |> Map.tryFind sectorId
    |> Option.map (fun sector -> sector.Name)
    |> Option.defaultValue defaultString


let sumWastePerStream (waste : WastePerStream) : WasteAmount =
    waste
    |> Map.filter (fun key _ -> key <> CalcSetting.constructionWasteStreamId)
    |> Map.map (fun key waste -> waste.Separated)
    |> Map.fold (fun x key value -> x + value) (Kg 0.<kg>)


let calculateImpact
    (staticContent : StaticContent)
    (amountPerWasteStream : WastePerStream)  : CalculatedImpact =

    let impacts =
        staticContent.WasteStreamMultipliers
        |> Map.toList
        |> List.collect (fun (wasteStreamId, impactListPerKg) ->
            impactListPerKg
            |> List.map (fun (separatedImpactPerKg, residualImpactPerKg) ->
                let impactId = separatedImpactPerKg.ImpactId

                let calculate wasteAmount impactPerKg =
                    (WasteAmount.ConvertToKg wasteAmount) * impactPerKg |> MonetizedImpact
                try
                    let waste = amountPerWasteStream.Item wasteStreamId
                    let impact = {
                        Separated       = calculate waste.Separated separatedImpactPerKg.ImpactPerKg
                        Residual        = calculate waste.Residual residualImpactPerKg.ImpactPerKg
                        Construction    = calculate waste.Construction separatedImpactPerKg.ImpactPerKg
                    }
                    (wasteStreamId, impactId), impact
                with
                | _ ->
                    let impact = {
                        Separated       = MonetizedImpact 0.<eur>
                        Residual        = MonetizedImpact 0.<eur>
                        Construction    = MonetizedImpact 0.<eur> }

                    (wasteStreamId, impactId), impact
            )
        )


    let impactWithConstructionGrouped =
        impacts
        |> List.map (fun ((wasteStreamId, impactId), impact) ->
            let totalConstructionWaste =
                impacts
                |> List.filter (fun ((_, impactId'), _) -> impactId' = impactId)
                |> List.sumBy (snd >> (fun impact -> impact.Construction))
            if wasteStreamId = CalcSetting.constructionWasteStreamId then
                (wasteStreamId, impactId), { impact with Separated = totalConstructionWaste }
            else
                (wasteStreamId, impactId), impact
        )
        |> List.filter (fun ((wasteStreamId, _), _) ->
            staticContent.WasteStreams.TryFind wasteStreamId
            |> Option.map (fun ws -> not ws.IsConstructionOnly)
            |> Option.defaultValue false )

    { SeparatedWasteImpact =
        impactWithConstructionGrouped
        |> List.map (fun (key, impact) -> key, impact.Separated )
        |> Map.ofList

      ResidualWasteImpact =
        impactWithConstructionGrouped
        |> List.map (fun (key, impact) -> key, impact.Residual )
        |> Map.ofList }


let rescaleWastePerStream (total : WasteAmount) (waste : WastePerStream) : WastePerStream =
    let totalWaste = sumWastePerStream waste
    let scalingFactor = total / totalWaste
    waste
    |> Map.map (fun key waste ->
        { Separated     = waste.Separated.multiply scalingFactor
          Residual      = waste.Residual.multiply scalingFactor
          Construction  = waste.Construction.multiply scalingFactor }
    )

// totalWasteAmount is used to rescale
let calculateSectorImpact
    (staticContent : StaticContent)
    (sectorId : SectorId)
    (sectors : Map<SectorId, SectorData>)
    (totalWasteAmount : WasteAmount) =

    let sector = sectors|> Map.find sectorId
    let sectorWasteData = sector.WastePerStream

    sectorWasteData
    |> rescaleWastePerStream totalWasteAmount
    |> calculateImpact staticContent

// totalWasteAmount is used to rescale
let tryCalculateSectorImpact
    (staticContent : StaticContent)
    (sectorId : SectorId)
    (sectors : Map<SectorId, SectorData>)
    (totalWasteAmount : WasteAmount) =

    let sector = sectors|> Map.tryFind sectorId
    let sectorWasteData = sector |> Option.map (fun sector -> sector.WastePerStream)

    sectorWasteData
    |> Option.map (
        (rescaleWastePerStream totalWasteAmount) >> (calculateImpact staticContent)
    )

let combineCalculatedImpact (calculatedImpact : CalculatedImpact) : Map<ImpactId,MonetizedImpact> =
    calculatedImpact.ByImpactId |> Map.ofArray


let totalImpact (impact : CalculatedImpact) : float  =
    impact.ByImpactId
    |> Array.sumBy (fun (impactId, result) -> result.Float)

let co2Values (impact : CalculatedImpact) : float<kg> =
    (Map.ofArray impact.ByImpactId).TryFind CalcSetting.climateWasteId
    |> Option.defaultValue (MonetizedImpact 0.<eur>)
    |> function | MonetizedImpact x -> x / CalcSetting.climateMonetisationFactor