module Components.Image

open Fulma
open Fable.React
open Fable.React.Props

type Picture =
    | Earth
    | CoffeeCup
    | CoffeeGround
    | Paper
    | Plastic
    | Swill with
    member this.Filepath =
        match this with
        | Earth -> "Algemeen"
        | CoffeeCup -> "Koffiebekers"
        | CoffeeGround -> "Koffiedik"
        | Paper -> "Papier"
        | Plastic -> "Plastic"
        | Swill -> "Swill"
        |> sprintf "images/Splitbeelden/PreZero_%s.png"

let overlayImage (picture: Picture) (text: string option) =
    Field.div [ Field.CustomClass "gallery" ] [
        Image.image [ Image.Is16by9; Image.CustomClass PreZero.IsRoundedCorner.IsTwoCorners.ClassName ]
            [ img [ Src "images/background/Background - Blue.jpg" ]
              img [ Class "picture"; Src picture.Filepath ] ]
        text
        |> function
           | None -> nothing
           | Some text ->
                Heading.h5 [ Heading.CustomClass "overlay-text" ]
                    [ str text ]
    ]