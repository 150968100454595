module Home.View

open Fulma
open Fable.React
open Fable.React.Props

open Components.Layouts
open Components.Helpers
open PreZero
open Home
open Home.Texts
open Fable.FontAwesome

let factCard (fact: {| Number: string; Description: string |}) =
    Column.column [ ] [
        Box.box' [ Props [ ClassName "box"; Style [ MinHeight "100%" ] ] ] [
            Field.div [ Field.IsGroupedCentered ] [
                Field.label [ ] [
                    Heading.h3 [ Heading.Props [ Style [ MinWidth "max-content" ] ] ]
                        [ str fact.Number ]
                ]
                Field.body [ ] [
                    Text.p [ Modifiers [ Modifier.TextSize (Screen.WideScreen, TextSize.Is7);
                                         Modifier.TextSize (Screen.FullHD, TextSize.Is6) ] ]
                        [ str fact.Description ]
                ]
            ]
        ]
    ]

let sectionLanding =
    let props : Fable.React.Props.CSSProp list =
        [ BackgroundImage "url('./images/background/Background - Green.jpg')" ]
    let content =
        let propsFlexVAligned =
            [ Display DisplayOptions.Flex; FlexDirection "column"; JustifyContent "center" ]
        Columns.columns [ Columns.Props [ Style [ Margin "auto"; Height "100vh" ] ] ] [
            Column.column [ ] [
                Columns.columns [ Columns.Props [ Style [ Height "50%" ] ] ] [
                    Column.column [ Column.Width (Screen.All, Column.Is6)
                                    Column.Props [ Style propsFlexVAligned ] ] [
                            Field.div [ ]
                                [ Heading.h2 [ Heading.Modifiers [ Modifier.TextSize (Screen.FullHD, TextSize.Is1) ] ]
                                    [ str "Wat doet je afval?" ] ]
                            Field.div [ ]
                                [ Text.p [ Modifiers [ Modifier.TextSize (Screen.FullHD, TextSize.Is5) ] ] [ str Texts.introduction ] ]
                            Field.div [ ]
                                [ Button.a
                                    [ Button.Size IsMedium; Button.Color IsPrimary
                                      Button.Props [ Navigation.ImpactChecker.ToHref ] ]
                                    [ str "Bereken je huidige impact" ] ]
                    ]
                ]

                // hidden in mobile view
                Columns.columns [ Columns.Modifiers [ Modifier.IsHidden (Screen.Mobile, true) ]
                                  Columns.Props [ Style [ Margin "auto"; Height "50%" ] ] ] [
                    Column.column [ Column.Props [ Style [ Padding "0px" ] ] ] [
                        Columns.columns [ ] [
                            Column.column [ Column.Width (Screen.All, Column.Is6)
                                            Column.Props [ Style propsFlexVAligned ] ] [
                                Field.div [ ]
                                    [ Heading.h5 [ ] [ str "Hoe belangrijk is milieu-impact?" ] ]
                                Field.div [ ]
                                    [ Text.p [ ] [ str Texts.factsIntro ] ]
                            ]
                        ]
                        Columns.columns [ ] [
                            Column.column [ Column.Width (Screen.All, Column.Is12)
                                            Column.Props [ Style propsFlexVAligned ] ] [
                                Columns.columns [  ]
                                    (Texts.facts |> List.map factCard)
                            ]
                        ]
                    ]
                ]
            ]
        ]
    let content' =
        let propsOverlay =
            [
                Width "100%"; Height "100%"
                Position PositionOptions.Absolute
                Top 0; Left 0
            ]
        div [ ] [
            div [ ClassName "is-hidden-mobile"; Style (propsOverlay @ [TextAlign TextAlignOptions.Right]) ] [
                img [ Src "./images/Splitbeelden/PreZero_Algemeen.png"; Style [ Height "100%" ] ]
            ]
            div [ Style propsOverlay ] [
                Container.container
                    [ Container.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ]
                      Container.Props [ Style [ Height "100%"; BoxSizing BoxSizingOptions.Inherit ] ] ]
                    [ content ]
            ]
        ]

    {| Content = content'; Props = props |}

let sectionFactCardsOnMobile =
    let propsFlexVAligned =
        [ Display DisplayOptions.Flex; FlexDirection "column"; JustifyContent "center" ]
    Columns.columns [ Columns.Modifiers [ Modifier.IsHidden (Screen.Tablet, true) ] ] [
        Column.column [ ] [
            Columns.columns [ ] [
                Column.column [ Column.Width (Screen.All, Column.Is6)
                                Column.Props [ Style propsFlexVAligned ] ] [
                    Field.div [ ]
                        [ Heading.h5 [ ] [ str "Hoe belangrijk is milieu-impact?" ] ]
                    Field.div [ ]
                        [ Text.p [ ] [ str Texts.factsIntro ] ]
                ]
            ]
            Columns.columns [ ] [
                Column.column [ Column.Width (Screen.All, Column.Is12)
                                Column.Props [ Style propsFlexVAligned ] ] [
                    Columns.columns [  ]
                        (Texts.facts |> List.map factCard)
                ]
            ]
        ]
    ]

let sectionHoeKunJeVerduurzamen =
    Container.container
        [ Container.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [

            // facts card showing on mobile view
            sectionFactCardsOnMobile

            Tile.parent [ Tile.IsVertical ] [
                Tile.child [ ]
                    [ Heading.h2 [ Heading.Modifiers [ Modifier.TextSize (Screen.Mobile, TextSize.Is3)] ]
                        [ str "Hoe kun je verduurzamen?" ] ]
                Tile.child [ ] [ p [ ] [ str Texts.verkleinImpact ] ]
                Tile.child [ ] [
                    Button.a
                        [ Button.Color IsPrimary
                          Button.Size IsMedium
                          Button.Props [ Navigation.VerkleinImpact.ToHref ] ]
                        [ Text.span [ ] [ str "Verklein je impact" ]
                          Icon.icon [ ] [ Fa.i [ Fa.Solid.ArrowRight ] [ ] ] ]
                    ]
            ]
        ]

let sectionFinal =
    let tileContent title paragraph =
        Tile.parent [ Tile.IsVertical ] [
            Tile.child [ ]
                [ Heading.h2 [ Heading.Modifiers [ Modifier.TextSize (Screen.Mobile, TextSize.Is3)] ]
                    [ str title ] ]
            Tile.child [ ] paragraph
        ]
    Container.container [ ] [
        Columns.columns [ ] [
            Column.column [ Column.Modifiers [ Modifier.IsHidden (Screen.Mobile, true) ] ] [
                Components.Image.overlayImage Components.Image.CoffeeCup
                    <| Some "Wat doen jouw koffiebekers met onze bodem?"
            ]
            Column.column [ ] [
                tileContent "Wat is milieu-impact eigenlijk?" Texts.whatIsImpact
            ]
        ]
        Columns.columns [ ] [
            Column.column [ Column.Modifiers [ Modifier.IsHidden (Screen.Mobile, true) ] ] [
                Components.Image.overlayImage Components.Image.Paper
                    <| Some "Wat doet jouw papier met onze lucht?"
            ]
            Column.column [ ] [
                tileContent "Hoe berekenen we impact?" Texts.howToCalculate
            ]
        ]
    ]

let sectionWhitepaper =
    Container.container
        [ Container.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
            Heading.h2 [ Heading.Modifiers [ Modifier.TextSize (Screen.Mobile, TextSize.Is3)] ]
                [ str "Meer weten?" ]
            Field.div [ ] [ Text.p [ ] [ str Texts.whitePaperReference ] ]
            Field.div [ ] [
                a [ Href links.whitepaper; Target "_blank" ] [
                Field.div [ Field.IsGroupedCentered ] [
                    Text.span [ ] [ str "Whitepaper lezen" ]
                    Icon.icon [ ] [
                        Fa.i [ Fa.Solid.Book ] [ ]
                    ]
                ] ]
            ]
            Field.div [ Field.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                a [ Href links.whitepaper; Target "_blank" ] [
                    img [ Src "./images/prezero-whitepaper.png"; Style [ Width "150px" ] ]
                ]
            ]
        ]


let view (model: Model) (dispatch : Msg -> unit) staticContent page =
    let preZeroSections =
        [
            // PreZeroSection (BackgroundWhite, sectionWatDoeJeAfval)
            // PreZeroSection (CompanyIntro, sectionHoeBelangrijkIsImpact)
            PreZeroSection {| Class = CompanyIntro; Content = sectionHoeKunJeVerduurzamen; SectionId = None |}
            PreZeroSection {| Class = CompanyIntro; Content = sectionFinal; SectionId = None |}
            PreZeroSection {| Class = BackgroundWhite; Content = sectionWhitepaper; SectionId = None |}
        ]

    { Landing = Some (PreZeroLanding sectionLanding); Sections = preZeroSections }
