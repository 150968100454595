module ImpactChecker

open Elmish
open External
open React.Datasheet
open React.Datasheet.ImpactCheckerGrid
open PreZero
open Shared

[<RequireQualifiedAccess>]
type Modal =
    | SectorInformation of string
    | WasteStreamInformation of string
    | ImpactInformation of string
    | ResidualWasteInput
    | ConstructionWasteInput
    | TooManyProjects
    | ProjectSavedSuccess of string

type Model = {
    ModelStatus                 : ModelStatus
    SectorId                    : SectorId
    Modal                       : Modal Option
    WasteAmountGridData         : GridRowData list
    UserProfile                 : UserProfile option
    ProjectSelected             : Project option
    ProjectName                 : string
    ManualResidualWaste         : bool
    ManualConstructionWaste     : bool
    IsHiddenHowItWorks          : bool
    IsNonResidualWastes         : bool
    IsExpandedFullWasteEntries  : bool

    /// Show graph if true; otherwise show table
    ToggleGraphOrTable : {| PerImpact : bool; PerWasteStream : bool |}

    /// Waste stream data for comparison to current project for barcharts; when None, shows benchmark data
    ComparisonWasteStreamDataBarchart : (ProjectId option * string * WastePerStream) option

    /// Whether the sections with visualisation is hidden. True by default. Overlay to be removed when user input is given.
    IsHiddenSectionVisuals : bool
}

type Msg =
    | ChooseSector of SectorId
    | ToggleResidualWasteInput
    | ToggleConstructionWasteInput
    | SetModal of Modal option
    | UpdateGridResidual of React.Datasheet.CellsChangedArgs []
    | UpdateGridConstruction of React.Datasheet.CellsChangedArgs []
    | UpdateForm of WasteStreamId * string
    | UpdateResidualAmount of string
    | UpdateResidualUnit of Unit
    | SetName of string
    | CreateProject
    | SaveProject of Project
    | ProjectSaved of Result<Project,exn>
    | GetProjectList
    | GotProjectList of Result<Map<ProjectId,ProjectInfo>,exn>
    | SelectProject of ProjectId  * ProjectInfo
    | GetProjectData of ProjectId
    | GotProjectData of Result<Project,exn>
    | ToggleDisplayHowItWorks

    /// Dispatch when checkbox "Ons afval wordt gescheiden" is checked/unchecked.
    /// Decide whether the user data entry section for Papier en karton, Glas, and Swill is shown.
    | ToggleSeparatedWastes

    /// Dispatch when the user wishes to see full data entries by clicking the text "Meer velden tonen".
    /// Only accessible when user is logged-in.
    | ToggleExpandedFullWasteEntries

    | ToggleGraphOrTable of {| ToggleSourceIsGraph: bool; DataCategory: DataCategory |}
    | ToggleHiddenOverlay

    | SelectProjectComparisonData of ProjectId
    | GotProjectComparisonData of Result<Project,exn>

    /// Adjust the UI data entry section based on existing client data in the model
    | AdjustUserDataEntryUI


and DataCategory =
    | PerImpact
    | PerWasteStream

module Model =

    let ownWasteAmount model : WastePerStream =
        model.WasteAmountGridData
        |> List.map (fun gridData ->
            gridData.WasteStreamId, gridData.ToWasteAmount)
        |> Map.ofList


    let modelToProject (model : Model) : Project option =

        match model.SectorId, model.ProjectName with
        | _, "" -> None
        | sectorId, name ->
            {   Id                  = model.ProjectSelected |> Option.bind (fun project -> project.Id)
                Name                = name
                Sector              = sectorId
                WasteData           = ownWasteAmount model
                CustomResidualWaste = model.ManualResidualWaste
                }
            |> Some

    let initialModel
        (staticContent : StaticContent)
        (clientData : GridRowData list)
        (userProfile : UserProfile option)
        (project : Project option)
        (isManualResidualWaste: bool)
        (isManualConstructionWaste: bool) : Model =

        let clientData' =
            if clientData.IsEmpty then GridRowData.InitialiseWasteStreamInput staticContent.WasteStreams else clientData
        {
            ModelStatus                 = NewCommand
            SectorId                    = project
                                          |> Option.map (fun proj -> proj.Sector)
                                          |> Option.defaultValue CalcSetting.defaultSectorId
            WasteAmountGridData         = clientData'
            Modal                       = None
            UserProfile                 = userProfile
                                          |> Option.map (fun user ->
                                            { user with
                                                Projects =
                                                    match userProfile with
                                                    | Some user   -> user.Projects
                                                    | None        -> Map.empty
                                              } )
            ProjectSelected             = project
            ProjectName                 = project
                                          |> Option.map (fun proj -> proj.Name)
                                          |> Option.defaultValue ""
            ManualResidualWaste         = isManualResidualWaste
            ManualConstructionWaste     = isManualConstructionWaste
            IsHiddenHowItWorks          = true
            IsNonResidualWastes         = false
            IsExpandedFullWasteEntries  = false
            ToggleGraphOrTable          = {| PerImpact = true; PerWasteStream = true |}
            ComparisonWasteStreamDataBarchart = None
            IsHiddenSectionVisuals      = true
        }

    let init (staticContent: StaticContent) (clientData: GridRowData list) (userProfile: UserProfile option) (project : Project option) (isManualResidualWaste: bool) (isManualConstructionWaste: bool) =

        let initialCommand =
            match userProfile with
            | Some user ->
                Cmd.ofMsg GetProjectList
            | None ->
                Cmd.ofMsg AdjustUserDataEntryUI

        initialModel staticContent clientData userProfile project isManualResidualWaste isManualConstructionWaste,
        initialCommand


    let update (staticContent : StaticContent) (msg : Msg)  (model : Model) =
        match msg with

        | SelectProjectComparisonData selectedProjectId ->
            match selectedProjectId with

            /// Sectorgemiddelde ProjectId = 0, clear wasteSteam data for the dataCategory
            | ProjectId 0 ->
                { model with ComparisonWasteStreamDataBarchart = None }, Cmd.none

            /// Other project Ids
            | _ ->
                let cmd =
                    Cmd.OfAsync.perform
                        APIs.iProjectApi.getProjectData selectedProjectId
                        GotProjectComparisonData
                model, cmd

        | GotProjectComparisonData resultProject ->
            let wasteStreamData =
                match resultProject with
                | Ok project ->
                    Some (project.Id, project.Name, project.WasteData)
                | Error exn ->
                    printfn "Failed to load data for the project: %A" exn
                    None

            { model with ComparisonWasteStreamDataBarchart = wasteStreamData }, Cmd.none

        | AdjustUserDataEntryUI ->
            let isShowingNonResidualWastes =
                if model.WasteAmountGridData.IsEmpty then false
                else model.WasteAmountGridData |> List.exists (fun d -> d.Amount.IsSome)

            let isShowingFullWasteEntries =
                if model.WasteAmountGridData.IsEmpty then false
                else model.WasteAmountGridData
                     |> List.filter (fun d -> not d.Public)
                     |> List.exists (fun d -> d.Amount.IsSome)

            let isShowingSectionVisuals = not model.WasteAmountGridData.IsEmpty

            let model' =
                { model with
                    IsNonResidualWastes        = isShowingNonResidualWastes
                    IsHiddenSectionVisuals     = not isShowingSectionVisuals
                    IsExpandedFullWasteEntries = isShowingFullWasteEntries
                    ManualResidualWaste        = model.ManualResidualWaste
                    ComparisonWasteStreamDataBarchart = None }

            model', Cmd.none

        | ChooseSector id ->
            { model with SectorId = id }, Cmd.none

        | ToggleHiddenOverlay ->
            let existsUserInputValue =
                model.WasteAmountGridData
                |> List.map (fun gridRowData -> gridRowData.Amount)
                |> List.exists Option.isSome

            // if user input value exists, show the visuals by setting overlay hidden=true
            { model with IsHiddenSectionVisuals = not existsUserInputValue }, Cmd.none

        | ToggleGraphOrTable toggleDetail ->
            let togglePerCategory' =
                let togglePerCategory = model.ToggleGraphOrTable
                toggleDetail.DataCategory
                |> function
                   | PerImpact      ->
                        if toggleDetail.ToggleSourceIsGraph = togglePerCategory.PerImpact then
                            togglePerCategory
                        else
                            {| togglePerCategory with PerImpact = not togglePerCategory.PerImpact |}
                   | PerWasteStream ->
                        if toggleDetail.ToggleSourceIsGraph = togglePerCategory.PerWasteStream then
                            togglePerCategory
                        else
                            {| togglePerCategory with PerWasteStream = not togglePerCategory.PerWasteStream |}

            { model with ToggleGraphOrTable = togglePerCategory' }, Cmd.none

        | ToggleExpandedFullWasteEntries ->
            let updatedWasteAmountGridData =
                match model.IsExpandedFullWasteEntries with
                | true ->
                    // If to be togged to IsExpandedFullWasteEntries=false, clear the data entry for non-residual, non-public wastes
                    let nonPublicWasteStreamIds =
                        model.WasteAmountGridData
                        |> List.filter (fun wd -> not wd.Public)
                        |> List.map (fun wd -> wd.WasteStreamId)

                    // reset values for selected wastes
                    model.WasteAmountGridData
                    |> List.map (fun data ->
                        nonPublicWasteStreamIds
                        |> List.exists ((=) data.WasteStreamId)
                        |> function
                           | true  -> { data with Amount = None }
                           | false -> data
                        )
                | false ->
                    model.WasteAmountGridData

            printfn "IsExpandedFullWasteEntries = %b" model.IsExpandedFullWasteEntries

            { model with IsExpandedFullWasteEntries = not model.IsExpandedFullWasteEntries
                         WasteAmountGridData = updatedWasteAmountGridData },
            Cmd.ofMsg ToggleHiddenOverlay

        | ToggleSeparatedWastes ->
            let updatedWasteAmountGridData =
                match model.IsNonResidualWastes with
                | true ->
                    // If to be toggled to IsNonResidualWastes=false, clear the data entry for non-residual wastes
                    let nonResidualDataWasteStreamIds =
                        model.WasteAmountGridData
                        |> List.filter (fun wasteData ->
                            wasteData.WasteStreamId <> CalcSetting.residualWasteStreamId && wasteData.Public
                            )
                        |> List.map (fun gridRowData -> gridRowData.WasteStreamId)

                    // reset values for non-residual wastes
                    model.WasteAmountGridData
                    |> List.map (fun data ->
                        nonResidualDataWasteStreamIds
                        |> List.exists ((=) data.WasteStreamId)
                        |> function
                           | true  -> { data with Amount = None }
                           | false -> data
                    )

                | false ->
                    model.WasteAmountGridData

            { model with IsNonResidualWastes = not model.IsNonResidualWastes
                         WasteAmountGridData = updatedWasteAmountGridData },
            Cmd.none

        /// Toggle to distribute residual wastes automatically based on sector data or manually based on user input.
        | ToggleResidualWasteInput ->

            let newManualResidualWasteSetting = not model.ManualResidualWaste

            let newGridData =
                if not newManualResidualWasteSetting then
                    fillGridDataWithSector model.SectorId staticContent model.WasteAmountGridData
                elif model.UserProfile.IsSome then
                    roundResidualData model.WasteAmountGridData
                else
                    redistributeResidualDataToPublic model.WasteAmountGridData

            let newCommand =
                if newManualResidualWasteSetting then
                    Some Modal.ResidualWasteInput
                    |> SetModal
                    |> Cmd.ofMsg
                else
                    Cmd.none

            { model with ManualResidualWaste = newManualResidualWasteSetting; WasteAmountGridData = newGridData}, newCommand

        | ToggleConstructionWasteInput ->

            let newManualConstructionWasteSetting = not model.ManualConstructionWaste

            let newGridData =
                if not newManualConstructionWasteSetting then
                    distributeConstructionGridData model.WasteAmountGridData
                elif model.UserProfile.IsSome then
                    model.WasteAmountGridData
                    // roundConstructionData model.WasteAmountGridData
                else
                    model.WasteAmountGridData
                    // redistributeConstructionDataToPublic model.WasteAmountGridData

            let newCommand =
                if newManualConstructionWasteSetting then
                    Some Modal.ConstructionWasteInput
                    |> SetModal
                    |> Cmd.ofMsg
                else
                    Cmd.none

            { model with
                ManualConstructionWaste = newManualConstructionWasteSetting
                WasteAmountGridData = newGridData
            }, newCommand

        | SetModal modal -> {model with Modal = modal}, Cmd.none

        | UpdateGridResidual cellsChangedArgs ->
            let newGridData = Array.fold updateWasteAmountGridDataResidual model.WasteAmountGridData cellsChangedArgs
            let totalResidualWaste : WasteAmount =
                newGridData
                |> List.sumBy (fun gridData -> gridData.ToWasteAmount.Residual)
            let newerGridData =
                newGridData
                |> List.map (
                    fun gridData ->
                        if gridData.WasteStreamId = CalcSetting.residualWasteStreamId then
                            {
                                gridData with
                                    Unit = totalResidualWaste.Unit
                                    Amount = totalResidualWaste.Value |> Rounding.roundIfAnnoying |> Some
                            }
                        else gridData
                )

            { model with WasteAmountGridData = newerGridData }, Cmd.ofMsg ToggleHiddenOverlay

        | UpdateGridConstruction cellsChangedArgs ->
            let newGridRows =
                cellsChangedArgs
                |> Array.fold updateConstructionGridData model.WasteAmountGridData

            let totalConstructionWaste : WasteAmount =
                newGridRows
                |> List.sumBy (fun gridData -> gridData.ToWasteAmount.Construction)

            let newGrid =
                newGridRows
                |> List.map (
                    fun gridData ->
                        if gridData.WasteStreamId = CalcSetting.constructionWasteStreamId then
                            {
                                gridData with
                                    Unit = totalConstructionWaste.Unit
                                    Amount = totalConstructionWaste.Value |> Rounding.roundIfAnnoying |> Some
                            }
                        else gridData
                )

            { model with WasteAmountGridData = newGrid }, Cmd.ofMsg ToggleHiddenOverlay

        | UpdateForm (wsId, newValue) ->
            let updatedGridData =
                model.WasteAmountGridData
                |> List.map (fun gridData ->
                    if gridData.WasteStreamId = wsId then
                        { gridData with Amount = Some (if newValue = "" then 0. else float newValue)}
                    else gridData )

            let gridDataIncludingConstructionCorrection =
                if wsId = CalcSetting.constructionWasteStreamId then
                    distributeConstructionGridData updatedGridData
                else
                    updatedGridData

            { model with WasteAmountGridData = gridDataIncludingConstructionCorrection },
              Cmd.ofMsg ToggleHiddenOverlay

        | UpdateResidualAmount value ->
            let newGridData =
                model.WasteAmountGridData
                |> List.map (fun gridData ->
                    if gridData.WasteStreamId = CalcSetting.residualWasteStreamId then
                        { gridData with
                            Amount = Some (if value = "" then 0. else value |> float |> Rounding.roundIfAnnoying ) }
                    else gridData )
                |> fillGridDataWithSector model.SectorId staticContent
            let newModel =
                {model with WasteAmountGridData = newGridData}
            newModel, Cmd.ofMsg ToggleHiddenOverlay

        | UpdateResidualUnit unit ->
            let newGridData =
                model.WasteAmountGridData
                |> List.map (fun gridData ->
                    if gridData.WasteStreamId = CalcSetting.residualWasteStreamId then
                        { gridData with Unit = unit }
                    else gridData)
                |> fillGridDataWithSector model.SectorId staticContent
            let newModel =
                { model with WasteAmountGridData = newGridData }
            newModel,Cmd.none

        | SetName name ->
            let newModel =
                {model with ProjectName = name}
            newModel, Cmd.none

        | CreateProject ->
            let newModel = initialModel staticContent [ ] model.UserProfile None false false
            newModel, Cmd.none

        | SaveProject project ->

            let newModel =
                {model with ProjectSelected = Some project; ModelStatus = NewCommand}

            let newCommand =
                Cmd.OfAsync.either
                    APIs.iProjectApi.saveProject project
                    ProjectSaved
                    (Error>>ProjectSaved)
            newModel, newCommand

        | ProjectSaved (Ok project) ->
            let newModel =
                {model with
                    ProjectSelected = Some project
                    ModelStatus = FinishedCommand}

            let cmd =
                Cmd.batch [
                    Cmd.ofMsg GetProjectList
                    Cmd.ofMsg (SetModal (Some (Modal.ProjectSavedSuccess project.Name)))
                ]

            newModel, cmd

        | ProjectSaved (Error exn) ->
            printf "%A" exn
            let newModel =
                {model with
                    ModelStatus = ModelError [exn.Message]}
            let newCommand = Cmd.ofMsg (SetModal (Some Modal.TooManyProjects))
            newModel, newCommand

        | SelectProject (projectId, projectInfo) ->
            let newModel =
                {model with ModelStatus = NewCommand}
            let newCommand =
                Cmd.OfAsync.perform
                    APIs.iProjectApi.getProjectData projectId
                    GotProjectData
            newModel, newCommand

        | GetProjectList ->
            let newCommand =
                Cmd.OfAsync.perform APIs.iProjectApi.getProjectList ()
                    GotProjectList
            let newModel = {model with ModelStatus = NewCommand}
            newModel, newCommand

        | GotProjectList result ->

            match model.UserProfile with

            | Some user ->

                let newProjectList =
                    match result with
                    | Ok mapping -> mapping
                    | Error _   -> Map.empty

                let newModel =
                    { model with UserProfile = Some {user with Projects = newProjectList} }

                let newCommand =
                    if model.ProjectSelected.IsNone then
                        user.Projects |> Map.toList |> List.maxBy fst |> SelectProject |> Cmd.ofMsg
                    else
                        Cmd.ofMsg AdjustUserDataEntryUI

                newModel, newCommand

            | None ->
                model, Cmd.none

        | GetProjectData  projectId ->
            let newCommand =
                Cmd.OfAsync.perform APIs.iProjectApi.getProjectData projectId
                    GotProjectData
            let newModel = {model with ModelStatus = NewCommand}
            newModel, newCommand

        | GotProjectData projectResult ->
            let newModel =
                match projectResult with
                | Ok project ->

                    {model with
                        ModelStatus         = FinishedCommand
                        ProjectName         = project.Name
                        SectorId            = project.Sector
                        ProjectSelected     = Some project
                        ManualResidualWaste = project.CustomResidualWaste
                        WasteAmountGridData = updateGridWithProject model.WasteAmountGridData project}
                | Error _ ->
                    { model with
                        ModelStatus         = FinishedCommand
                        ProjectSelected     = None
                        WasteAmountGridData = model.WasteAmountGridData }

            newModel, Cmd.ofMsg AdjustUserDataEntryUI

        | ToggleDisplayHowItWorks ->
            { model with IsHiddenHowItWorks = not model.IsHiddenHowItWorks }, Cmd.none


/// Parse the waste steam data to "data" of type text and with UTF-8 type encoding prepared for csv download.
let encodeWasteStreamData (ws: GridRowData list) (results : CalculatedImpact) (staticContent: StaticContent) =
    let prefix = "data:text/csv;charset=utf-8,"
    let impacts =
        results.ByImpactId
        |> Array.toList
        |> List.sortBy (fun (impactId, _) -> impactId.Value)
        |> List.map (fun (impactId, _) ->
            impactId, Calculation.showImpactName staticContent impactId
            )

    let header =
        [
            "Id"
            "Afvalstroom"
            "Hoeveelheid"
            "Eenheid"
            "Hoeveelheid bedrijfsafval"
            "Eenheid bedrijfsafval"
        ]
        @ (impacts |> List.map (fun (_, name) -> name + " (EUR)"))
        @ [ "Totale impact (EUR)" ]
        |> List.reduce (fun a b -> a + "," + b)

    let combinedRowIOData =
        results.SeparatedPlusResidualImpact
        |> Map.toList
        |> List.groupBy (fst >> fst)
        |> List.map (fun (wsId, result) ->
            let impactResultData =
                result
                |> List.map (fun ((wsId, impactId), result) -> impactId, result)
                |> List.sortBy (fun (impactId, result) -> impactId.Value)

            let clientWsData = ws |> List.find (fun rr -> rr.WasteStreamId = wsId)

            // data per row
            [
                string clientWsData.RowId
                string clientWsData.Name
                string (clientWsData.Amount |> Option.defaultValue 0.)
                string clientWsData.Unit
                string (clientWsData.AmountResidual |> Option.defaultValue 0.)
                string clientWsData.UnitResidual
            ]
            @ (impactResultData |> List.map (fun (impactId, impact) -> string impact.Float))
            @ [ impactResultData |> List.sumBy (fun (impactId, impact) -> impact.Float) |> string ]
        )
        |> List.sortBy (fun row -> int(row.[0])) // sort by RowId
        |> List.map (List.reduce (fun a b -> a + "," + b))

    let csvContent =
        [header] @ combinedRowIOData
        |> List.map (fun rr -> rr + "\r\n")
        |> List.reduce (+)

    prefix + Fable.Core.JS.encodeURIComponent(csvContent)