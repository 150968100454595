module Faq.Texts

open Fulma
open Fable.React
open Fable.React.Props

type QA = {
  Question : string
  Answer   : ReactElement list
}

let questionAnswerPairs =
    [
        {
            Question = "Hoe meten we de impact op het milieu?"
            Answer =
            [
                Text.p [ ]
                    [ str """
                          Om een eerlijk verschil te kunnen maken tussen verschillende manieren van verwerken van afvalstromen,
                          vergelijken we in de Impact Checker de volgende twee scenario’s:
                          """ ]
                Content.Ol.ol [ ]
                    [ li [ ] [ str "Het verbranden van afval. Door dit proces wordt energie wordt opgewekt en virgin materialen geproduceerd." ]
                      li [ ] [ str "Het recyclen van afval. Materialen worden hergebruikt en energie wordt opgewekt." ] ]
                Text.p [ ]
                    [ str """
                          Het vergelijken van deze twee scenario’s geeft een volledig beeld van de milieu-impact,
                          omdat beide dezelfde input gebruiken (afval) en in hetzelfde resulteren (energie en materialen).
                          """ ]
            ]
        }
        {
            Question = "Waarop zijn de maatschappelijke kosten van elke milieu impact berekend?"
            Answer =
            [
                """
                Het beste overzicht hiervan staat in sectie 4.1 van de True Price Monetisation Factors.
                Dit document geeft een volledig overzicht van alle impactindicatoren en monetariseringsfactoren,
                inclusief uitleg en bronvermelding.
                """
                |> Components.Helpers.parseParagraphWithLinks
                    [
                        {| Texts = "True Price Monetisation Factors"
                           Href = "https://trueprice.org/monetisation-factors-for-true-pricing/" |}
                    ]
            ]
        }
        {
            Question = "Wat is monetariseren?"
            Answer =
            [
                Text.p [ ] [ str """
                                 Alle milieu-impacts op de Impact Checker zijn gemonetariseerd
                                 (oftewel uitgedrukt in euro’s) met behulp van herstel- of compensatiekosten.
                                 Dit zijn de kosten die gemaakt moeten worden om een negatieve impact te herstellen,
                                 of anders de schade te compenseren.
                                 """ ]
            ]
        }
        {
            Question = "Waarom monitariseren we?"
            Answer =
            [
                Text.p [ ] [ str "Door te monetariseren maken we de verschillende milieu-impacts:" ]
                ul [ ]
                   [ li [ ] [ str "Beter te interpreteren: wat betekent het dat een hoeveelheid CO2 wordt uitgestoten?" ]
                     li [ ] [ str "Vergelijkbaar: welke impact is het grootst?" ]
                     li [ ] [ str "Klaar om meegenomen te worden in beleid- en besluitvorming,
                                   omdat ze op gelijke voet mee kunnen met financiële getallen." ] ]
            ]
        }
        {
            Question = "Wat is er wél meegenomen in de impactberekening?"
            Answer =
            [
                Text.p [ ] [ str """
                                 De Impact Checker beslaat waardeketenstappen waarin een transformerende activiteit plaatsvindt (waar iets verandert).
                                 Denk hierbij aan het verbranden van afval in een verbrandingsoven,
                                 het produceren van warmte en elektriciteit als gevolg van het verbranden van afval,
                                 hergebruik van afval en de productie van virgin materialen.
                                 """ ]
            ]
        }
        {
            Question = "Wat is er niet meegenomen in de impactberekening?"
            Answer =
            [
                Text.p [ ] [ str """
                                 Waardeketenstappen waarin géén transformerende activiteit plaatsvindt (zoals transport),
                                 zijn niet meegenomen in de afbakening van de Impact Checker.
                                 """ ]
            ]
        }
        {
            Question = "Hoe berekenen we de gemiddelde landelijke verdeling van het restafval?"
            Answer =
            [
                Text.p [ ] [ str """
                                 We hebben ons voor de gemiddelde verdeling van restafval gebaseerd op het rapport van Rijkswaterstaat 2019:
                                 Samenstelling van het huishoudelijk restafval, sorteeranalyses 2019 (Rijkswaterstaat).
                                 Binnenkort is hiervoor een sectorspecifieke verdeling mogelijk.
                                 """ ]
            ]
        }
        {
            Question = "Waarom is de CO2 impact van papier hoger als je gaat recyclen?"
            Answer =
            [
                Text.p [ ] [ str """
                                 De milieu-impact van papier recyclen is lager dan wanneer dit bij het restafval belandt.
                                 Het heeft echter wel een hogere CO2 uitstoot. Dit is vanwege twee redenen.
                                 Ten eerste is papierrecycling een energie-intensief proces.
                                 Daarnaast wordt de CO2 uitstoot gedreven door het missen van opgewekte elektriciteit uit verbranding
                                 (dat wel gewonnen wordt wanneer papier bij het restafval belandt).
                                 """ ]
            ]
        }
        {
            Question = "Hoe bepalen we de samenstelling van het PBD?"
            Answer =
            [
                Text.p [ ] [ str "Deze is bepaald op basis van een interne analyse door PreZero." ]
            ]
        }
        {
            Question = "Kijken jullie bij het PBD op basis van bronscheiding of nascheiding?"
            Answer =
            [
                Text.p [ ] [ str "Dit zijn resultaten obv bronscheiding." ]
            ]
        }
        {
            Question = "Waarom is de CO2 impact van PBD lager dan die van van PD?"
            Answer =
            [
                Text.p [ ] [ str "Het produceren, verwerken en recyclen van blik veroorzaakt veel lucht- en watervervuiling.
                                De negatieve impact van blik, en daarmee die van PBD, is daardoor groter dan de negatieve impact van PD. Op CO2-emissie scoort blik echter iets beter dan plastic en drinkpakken. Hierdoor zie je dat de CO2 voetafdruk van PBD lager uitkomt dan die van PD." ]
            ]
        }
        {
            Question = "Zijn er kosten verbonden aan de Impact Checker?"
            Answer =
            [
                Text.p [ ] [ str """
                                 Aan het gebruik van de Impact Checker zijn geen kosten verbonden.
                                 Wij vinden inzicht in milieu impact noodzakelijk om elke dag een stap dichter bij Zero Waste te komen.
                                 """ ]
            ]
        }
        {
            Question = "Wat is de gemonitariseerde impact per kilo afval?"
            Answer =

            let totalImpactPerKg =
                [
                    "Papier en karton", 0.492, 0.521
                    "Glas", 0.281, 0.379
                    "Swill (gft)", 0.055, 0.059
                    "Koffiebekers", 0.494, 0.521
                    "Koffiedik", 0.239, 1.187
                    "EPS", 0.610, 1.852
                    "Harde kunststoffen", 1.095, 1.436
                    "Gemengde kunststoffen", 0.702, 0.922
                    "PBD", 0.725, 1.236
                    "PD", 0.638, 0.798
                    "Blik", 1.194, 3.611
                    "Hout", 0.134, 0.887
                    "Metalen (ferro)", 0.717, 1.221
                    "Metalen (non-ferro)", 1.618, 8.400
                    "Gevaarlijk afval", 0.989, 0.989
                    "Puin", 0.007, 0.021
                    "Gips", 0.017, 0.185
                    "PVC", 1.881, 2.464
                    "Folie", 0.658, 1.337
                    "Vlakglas", 0.151, 0.256
                    "Overig BSA afval", 0.140, 0.301
                ]
            [
                Text.p [ ] [ str "In onderstaande tabel zie je de totale milieu-impact van iedere afvalstroom,
                                  zowel voor gescheiden inzameling als restafval." ]

                Table.table [ Table.IsBordered ]
                    [ thead [ ]
                        [ tr [ ]
                            [ th [ Style [ Width "33%"] ] [ str "Afvalstroom" ]
                              th [ ] [ str "Totale negatieve milieu-impact in €/kg gescheiden" ]
                              th [ ] [ str "Totale negatieve milieu-impact in €/kg restafval" ] ] ]
                      tbody [ ]
                        [
                            for (name, separatedImpact, residualImpact) in totalImpactPerKg do
                                yield tr [ ]
                                        [ td [ ] [ str name ]
                                          td [ HTMLAttr.Custom("align", "."); Class "numerical-value" ]
                                            [ str (System.Math.Round(separatedImpact, 3) |> Rounding.formatNL) ]
                                          td [ HTMLAttr.Custom("align", "."); Class "numerical-value" ]
                                            [ str (System.Math.Round(residualImpact, 3) |> Rounding.formatNL) ] ]
                        ] ]

            ]
        }
        {
            Question = "Welke aannames worden gedaan bij de recycling van bijvoorbeeld papier en PBD?"
            Answer =
            [
                Text.p [ ] [
                    str """
                        De berekeningen in de impact checker zijn gemaakt door onze verwerkingswijze van een bepaalde afvalstroom te matchen met de bijbehorende data uit de Ecoinvent database, en die vervolgens monetariseren met de methode van True Price.
                        EcoInvent is een veel gebruikte en gevalideerde levenscyclusinventarisatie (LCI) database, met impact informatie over duizenden processen.
                        De resultaten zijn daarom een benadering van de milieu-impact van de specifieke afvalmanagementprocessen van PreZero.
                        Per stroom zijn de meest geschikte processen gebruikt, waarbij soms een combinatie is gebruikt.
                        Zo is de impact van PBD recycling een combinatie van papier, aluminium en plastics.
                         """ ]
            ]
        }
        {
            Question = "Hoe zijn de verschillende impacts gedefinieerd?"
            Answer =
            [
                ul [ ] [
                    li [ ] [
                      str "Bijdrage aan klimaatverandering: uitstoot van broeikasgassen zoals koolstofdioxide (CO2) en methaan (CH4)."
                    ]
                    li [ ] [
                      str "Watervervuiling: uitstoot van giftige stoffen in water en eutrofiëring."
                    ]
                    li [ ] [
                      str "Luchtvervuiling: uitstoot van giftige stoffen in de lucht, wat bijvoorbeeld leidt tot de vorming van smog en fijnstof. "
                    ]
                    li [ ] [
                      str "Landgebruik: gebruik van land voor andere toepassingen (bijvoorbeeld agricultuur of bebouwing) dan origineel."
                    ]
                    li [ ] [
                      str "Bodemvervuiling: uitstoot van giftige stoffen in de bodem."
                    ]
                    li [ ] [
                      str "Gebruik van schaars water: gebruik van schaars water."
                    ]
                    li [ ] [
                      str "Uitputting van andere niet-hernieuwbare hulpbronnen: het gebruik van niet-hernieuwbare materialen zoals metalen."
                    ]
                    li [ ] [
                      str "Uitputting van fossiele brandstoffen: het gebruik van niet-hernieuwbare brandstoffen zoals gas of kolen."
                    ]
                ]
            ]
        }
        {
            Question = "Welke bronnen zijn geraadpleegd voor de Impact Checker?"
            Answer =
            [
                Text.p [ ] [ str "We gebruikten de data van PreZero over onze verwerkingswijze per afvalstroom,
                                  de LCA data vanuit de Eco Invent database versie 3.6 en de Monetisation factors van True Price." ]
            ]
        }
        // {
        //     Question = "Hoe verhoudt de Impact Checker zich tot de CE Delft 190400 Klimaatimpact van afvalverwerkroutes in Nederland?"
        //     Answer =
        //     [
        //         Text.p [ ] [ str "Dit wordt op dit moment onderzocht door het Impact Institute." ]
        //     ]
        // }
        {
            Question = "Hoe definiëren we environmental impact?"
            Answer =
            [
                Text.p [ ] [ str "Environmental impact, oftewel milieu-impact, in de Impact Checker omvat alle negatieve effecten op het milieu als gevolg van afvalverwerking." ]
            ]
        }
        {
            Question = "Hoe zit het precies met Gevaarlijk Afval?"
            Answer =
            [
                Text.p [ ] [ str "Gevaarlijk afval is opgenomen als 1 verzamelnaam, waarbij er vanuit wordt gegaan dat alles wordt verbrand (al dan niet met terugwinning energie). Dit is mogelijk niet de manier waarop jouw gevaarlijk afval wordt verwerkt. Wil je toch graag inzicht in een specifieke stroom gevaarlijk afval, neem dan contact met ons op." ]
            ]
        }
        {
            Question = "Waarom koos PreZero voor het Impact Institute?"
            Answer =
            [
                """
                Om meerdere redenen. Maar de belangrijkste is dat we geloven in een bredere kijk op
                milieu-impact en in het monetariseren waar het Impact Institute als autoriteit wordt gezien.
                Het feit dat andere grote organisaties ook al samen werken met het Impact Institute bevestigde ons vertrouwen.
                Zie hier de overige samenwerkingen.
                """
                |> Components.Helpers.parseParagraphWithLinks
                    [ {| Texts = "hier"
                         Href  = "https://www.impactinstitute.com/portfolio/" |} ]
            ]
        }
    ]