module Index

open Elmish
open External
open External.GoogleAnalytics
open React.Datasheet
open Fable.FontAwesome

open Components.Button
open Components.Helpers
open Shared
open PreZero

[<RequireQualifiedAccess>]
type Page =
| Home of Home.Model // Intro, Facts & figures, Calculate impact and Contact
| ImpactChecker of ImpactChecker.Model
| VerkleinImpact of VerkleinImpact.Model
| Faq of Faq.Model
| Contact of Contact.Model

type Modal =
| DeleteAccountModal of UserProfile
| AccountDeletedModal of Result<unit,exn>

// The model holds global data that we want to keep track of while the application is running
type GlobalModel = {
    Page                    : Page
    AppStatus               : AppStatus
    StaticContent           : StaticContent
    UserProfile             : UserProfile option
    ClientData              : GridRowData list
    ScenarioData            : GridRowData list
    SectorId                : SectorId
    Project                 : Project option
    ManualResidualWaste     : bool
    ManualConstructionWaste : bool
    Modal                   : Modal option
    }


// The Msg type defines what events/actions can occur while the application is running
// the state of the application changes *only* in reaction to these events
type GlobalMsg =
    | GotInitialContent of Result<StaticContent * UserProfile option,exn>
    | GotStaticContent of Result<StaticContent,exn>
    | GotUserProfile of Result<UserProfile option, exn>
    | SendTestCommand
    | FinishTestCommand
    | HomeMsg of Home.Msg
    | ImpactCheckerMsg of ImpactChecker.Msg
    | VerkleinImpactMsg of VerkleinImpact.Msg
    | FaqMsg of Faq.Msg
    | ContactMsg of Contact.Msg
    | SetModal of Modal option
    | DeleteAccount of UserProfile
    | AccountDeleted of Result<unit,exn>


// defines the initial state and initial command (= side-effect) of the application
let init (initialRoute : Navigation.Route option) : GlobalModel * Cmd<GlobalMsg> =

    let homeModel, homeMsg = Home.Model.init
    let initialStaticContent = { Sectors = Map.empty ; WasteStreams = Map.empty ; Impacts = Map.empty ; Drivers = [] ; WasteStreamMultipliers = [] |> Map.ofList}

    let initialPage =
        match initialRoute with

        | Some route ->
            match route with
            | Navigation.Home          -> homeModel |> Page.Home
            | Navigation.ImpactChecker ->
                ImpactChecker.Model.initialModel initialStaticContent [] None None false false
                |> Page.ImpactChecker
            | Navigation.VerkleinImpact ->
                VerkleinImpact.Model.initialModel initialStaticContent [] [] None None false
                |> Page.VerkleinImpact
            | Navigation.Faq            ->  Faq.Model.initialModel |> Page.Faq
            | Navigation.Contact        -> Contact.Model.initialModel |> Page.Contact

        | None -> Page.Home homeModel

    let initialModel =
        { Page                      = initialPage
          StaticContent             = initialStaticContent
          AppStatus                 = CommandsInProcess 2
          UserProfile               = None
          ClientData                = []
          ScenarioData              = []
          SectorId                  = CalcSetting.defaultSectorId
          Project                   = None
          ManualResidualWaste       = false
          ManualConstructionWaste   = false
          Modal                     = None }

    let loadStaticContentCmd =

        let initialCommands () = async {
            let! staticContent = APIs.iStaticContentApi.getStaticContent ()
            let! userProfile   = APIs.UserProfileApi.tryGetUserProfile ()
            return staticContent, userProfile
        }

        Cmd.OfAsync.either initialCommands ()
            (Ok >> GotInitialContent )
            (Error >> GotInitialContent )


    initialModel, loadStaticContentCmd

// The update function computes the next state of the application based on the current state and the incoming events/messages
// It can also run side-effects (encoded as commands) like calling the server via Http.
// these commands in turn, can dispatch messages to which the update function will react.
let update (msg : GlobalMsg) (currentModel : GlobalModel) : GlobalModel * Cmd<GlobalMsg> =

    match currentModel.Page, msg with

    | _, GotInitialContent (Ok (staticContent, userProfile)) ->

        let nextCmd =

            match currentModel.Page, userProfile with
            | Page.ImpactChecker _ , Some _ -> Cmd.ofMsg (ImpactChecker.Msg.GetProjectList |> ImpactCheckerMsg)
            | Page.VerkleinImpact _ , Some _ -> Cmd.ofMsg (VerkleinImpact.Msg.GetProjectList |> VerkleinImpactMsg)
            | _, _ -> Cmd.none

        let commandBatch =
            [ Cmd.ofMsg (GotStaticContent (Ok staticContent))
              Cmd.ofMsg (GotUserProfile (Ok userProfile))
              nextCmd
            ]
            |> Cmd.batch

        currentModel, commandBatch

    | _, GotStaticContent (Ok staticContent) ->

        let newPage =
            match currentModel.Page with
            | Page.ImpactChecker _ ->
                Page.ImpactChecker (
                    ImpactChecker.Model.initialModel
                        staticContent
                        currentModel.ClientData
                        currentModel.UserProfile
                        currentModel.Project
                        currentModel.ManualResidualWaste
                        currentModel.ManualConstructionWaste
                    )
            | Page.VerkleinImpact _ ->
                Page.VerkleinImpact (
                    VerkleinImpact.Model.initialModel
                        staticContent
                        currentModel.ClientData
                        [ ]
                        currentModel.UserProfile
                        currentModel.Project
                        currentModel.ManualResidualWaste
                    )
            | _ -> currentModel.Page

        let nextModel =
            { currentModel with
                Page            = newPage
                StaticContent   = staticContent
                AppStatus       = currentModel.AppStatus + FinishedCommand }

        nextModel, Cmd.none

    | _, GotUserProfile (Ok userProfile) ->

        GaTracked.UserProfileLoaded userProfile |> GoogleAnalytics.trackAction

        let newPage =
            match currentModel.Page with
            | Page.ImpactChecker localModel -> Page.ImpactChecker { localModel with UserProfile = userProfile }
            | Page.VerkleinImpact localModel -> Page.VerkleinImpact { localModel with UserProfile = userProfile }
            | _ -> currentModel.Page

        let nextModel =
            { currentModel with
                Page            = newPage
                UserProfile     = userProfile
                AppStatus       = currentModel.AppStatus + FinishedCommand }

        nextModel, Cmd.none

    | Page.ImpactChecker impactCheckerModel, ImpactCheckerMsg msg ->

        let nextImpactCheckerModel, nextImpactCheckerMsg =
            ImpactChecker.Model.update currentModel.StaticContent msg impactCheckerModel

        let projectSelected =
            match msg with
            | ImpactChecker.Msg.GotProjectData (Ok project) | ImpactChecker.Msg.ProjectSaved (Ok project) ->
                Some project
            | _ -> None

        let nextModel =
            { currentModel with
                Page                = Page.ImpactChecker nextImpactCheckerModel
                ClientData          = nextImpactCheckerModel.WasteAmountGridData
                Project             = projectSelected
                ManualResidualWaste = nextImpactCheckerModel.ManualResidualWaste
                SectorId            = nextImpactCheckerModel.SectorId }

        nextModel, Cmd.map ImpactCheckerMsg nextImpactCheckerMsg

    | Page.VerkleinImpact verkleinImpactModel, VerkleinImpactMsg msg ->

        let nextVerkleinImpactModel, nextVerkleinImpactMsg = VerkleinImpact.Model.update msg verkleinImpactModel

        let nextModel =
            { currentModel with
                Page                = Page.VerkleinImpact nextVerkleinImpactModel
                ScenarioData        = nextVerkleinImpactModel.ScenarioData
                ManualResidualWaste = nextVerkleinImpactModel.ManualResidualWaste }

        nextModel, Cmd.map VerkleinImpactMsg nextVerkleinImpactMsg


    | Page.Faq faqModel, FaqMsg msg ->

        let nextFaqModel, nextFaqMsg = Faq.Model.update msg faqModel

        let nextModel =
            { currentModel with
                Page        = Page.Faq nextFaqModel }
        nextModel, Cmd.map FaqMsg nextFaqMsg


    | Page.Contact contactModel, ContactMsg msg ->

        let nextContactModel, nextContactMsg = Contact.Model.update msg contactModel

        let nextModel =
            { currentModel with
                Page        = Page.Contact nextContactModel }
        nextModel, Cmd.map ContactMsg nextContactMsg

    | _, SetModal modal ->
        {currentModel with Modal = modal}, Cmd.none

    | _, DeleteAccount user ->
        let newCommand =
            Cmd.OfAsync.perform
                APIs.UserProfileApi.deleteProfile user
                AccountDeleted
        currentModel, newCommand

    | _, AccountDeleted r ->

        let newPage =
            match currentModel.Page with
            | Page.ImpactChecker model -> Page.ImpactChecker { model with UserProfile = None }
            | anyOtherPageModel -> anyOtherPageModel

        let newModel =
            match r with
            | Ok _ -> {currentModel with UserProfile = None; Page = newPage}
            | Error e -> currentModel
        newModel, Cmd.ofMsg (SetModal (Some (AccountDeletedModal r)))

    | Page.Home _, ImpactCheckerMsg _
    | Page.Home _, VerkleinImpactMsg _
    | Page.Home _, FaqMsg _
    | Page.Home _, ContactMsg _
    | Page.ImpactChecker _, HomeMsg _
    | Page.ImpactChecker _, VerkleinImpactMsg _
    | Page.ImpactChecker _, FaqMsg _
    | Page.ImpactChecker _, ContactMsg _
    | Page.VerkleinImpact _, HomeMsg _
    | Page.VerkleinImpact _, ImpactCheckerMsg _
    | Page.VerkleinImpact _, FaqMsg _
    | Page.VerkleinImpact _, ContactMsg _
    | Page.Faq _, HomeMsg _
    | Page.Faq _, ImpactCheckerMsg _
    | Page.Faq _, VerkleinImpactMsg _
    | Page.Faq _, ContactMsg _
    | Page.Contact _, HomeMsg _
    | Page.Contact _, ImpactCheckerMsg _
    | Page.Contact _, VerkleinImpactMsg _
    | Page.Contact _, FaqMsg _ ->

        // let errorMsg = sprintf "Conflicterende commando's ontvangen. Ververs de pagina en neem contact op met Impact Institute als dit vaker voorkomt. Message %A on page %A." msg currentModel.Page

        // don't register as errors, just ignore because there are currently no cases where this goes wrong
        currentModel, Cmd.none

    | _, anything ->

        let errorMessage  =

            match anything with
            | GotInitialContent (Error e)
            | GotStaticContent (Error e)
            | GotUserProfile (Error e)
            | ImpactCheckerMsg (ImpactChecker.Msg.GotProjectList (Error e))
            | ImpactCheckerMsg (ImpactChecker.Msg.GotProjectData (Error e)) ->
                "API error: " + e.Message + " " + e.StackTrace
            | _ -> "An unknown error occurred. Check whether the Page, Message pattern is matched: " + currentModel.Page.ToString() + msg.ToString()

        { currentModel with AppStatus = AppError [errorMessage] } , Cmd.none


open Fulma
open Fable.React
open Fable.React.Props

let preZeroLogo =
    Navbar.Item.div [ ]
        [ img [ Style [ Height "39px" ]
                Src  "./images/logo/svg/PreZero_logo_RGB_petrol_green.svg" ] ]

let navigationHeader (model : GlobalModel) =
    let navItem (page: Navigation.Route) =
        let isCurrentPage =
            match model.Page with
            | Page.Home _           -> page = Navigation.Route.Home
            | Page.ImpactChecker _  -> page = Navigation.Route.ImpactChecker
            | Page.VerkleinImpact _ -> page = Navigation.Route.VerkleinImpact
            | Page.Faq _            -> page = Navigation.Route.Faq
            | Page.Contact _        -> page = Navigation.Route.Contact

        Navbar.Item.a [ Navbar.Item.IsActive isCurrentPage; Navbar.Item.Props [ page.ToHref ] ]
            [ str (page.Name.ToUpper()) ]


    Navbar.navbar [ Navbar.IsSpaced ] [
        Container.container [ ] [
            Navbar.Brand.div [ ] [
                preZeroLogo

                // Navbar burger: https://bulma.io/documentation/components/navbar/#navbar-burger
                Navbar.burger [ ] [
                    Button.a [ Button.Props [ AriaLabel "menu"; AriaExpanded false; Class "navbar-burger"
                                              HTMLAttr.Custom ("data-target", "navbarMenu"); Role "button" ] ] [
                        span [ AriaHidden true ] [ ]
                        span [ AriaHidden true ] [ ]
                        span [ AriaHidden true ] [ ]
                    ]
                ]
            ]

            Navbar.menu [ Navbar.Menu.Props [ Id "navbarMenu" ] ] [
                Navbar.Start.div [ ] [
                    navItem Navigation.Home
                    navItem Navigation.ImpactChecker
                    navItem Navigation.VerkleinImpact
                    navItem Navigation.Faq
                    navItem Navigation.Contact
                ]
                Navbar.End.div [ ] [
                    match model.UserProfile with
                    | Some user ->
                        Navbar.Item.div [ ] [
                            form [ HTMLAttr.Action Shared.Route.logout; HTMLAttr.Method "POST" ] [
                                Button.button
                                    [ Button.Color IsPrimary ]
                                    [ str "Afmelden" ]
                            ]
                        ]
                        Navbar.Item.div [] [str (sprintf "Ingelogd als %A" (model.UserProfile |> Option.map (fun x -> x.Name)))]
                    | None ->
                        Navbar.Item.div [] [
                            form [ HTMLAttr.Action Shared.Route.login; HTMLAttr.Method "get" ] [
                                Button.button
                                    [ Button.Color IsPrimary ]
                                    [ str "Inloggen" ]
                            ]
                        ]
                        Navbar.Item.div [] [
                            form [ HTMLAttr.Action Shared.Route.register; HTMLAttr.Method "get" ] [
                                Button.button
                                    [ Button.Color IsPrimary; Button.IsOutlined ]
                                    [ str "Account aanmaken" ]
                            ]
                        ]
                    ]
                ]
            ]
        ]


let errorBody (model : GlobalModel) (dispatch : GlobalMsg -> unit) (errors : string list) : ReactElement =
    Box.box' [ Props [ Style [BackgroundColor "WhiteSmoke" ] ] ] [
            Heading.h1 [
                Heading.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered)]
            ] [
                str "Er is een fout opgetreden"
            ]
            Level.level [] []
            div [ Style [ MinHeight "500px"] ] [
                Text.p [
                    Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]
                ] [
                    str "Probeer de pagina te verversen. Als dit probleem vaker voorkomt, neem dan contact op met Impact Institute."
                ]
                // Level.level [] []
                // str (sprintf "Errors: %A" errors)
            ]
        ]


let loadingBody (model : GlobalModel) (dispatch : GlobalMsg -> unit) : ReactElement =
    Box.box' [ Props [ Style [BackgroundColor "WhiteSmoke" ; Height "100vh"; PaddingTop "100px"] ] ] [
            Button.button [
                Button.Props [ Style [ BackgroundColor "WhiteSmoke" ; Border "0px solid black" ] ]
                Button.IsFullWidth
                Button.IsLoading true
                Button.Size IsLarge ] []
    ]


let modalDeleteAccount (model : GlobalModel) (dispatch : GlobalMsg -> unit) =
    let onClickDeleteAccountButton user = dispatch (DeleteAccount user)

    let name, title, body =
        match model.Modal with
        | Some (DeleteAccountModal user) ->
            "Account verwijderen", "Account verwijderen", [
                p [] [str (sprintf "Weet je dit zeker? Je data gaat verloren en kan niet worden hersteld.")]
                deleteAccountButton user onClickDeleteAccountButton
            ]
        | Some (AccountDeletedModal r)->
            match r with
            | Ok _ ->
                "Account verwijderd","Account verwijderd", [p [] [str "Account succesvol verwijderd."]]
            | Error e ->
                "Foutmelding", "Foutmelding", [p [] [str (sprintf "Er ging is mis: %A" e)]]
        | None -> "nothing", "nothing", [nothing]

    Components.Modal.modalTemplate
        model.Modal.IsSome
        title
        body
        dispatch
        (SetModal None)

let contactInfo (model : GlobalModel) (dispatch : GlobalMsg -> unit) =
    let CompanyIntro =
        Field.div [ ] [
            a [ Href "https://www.prezero.nl"; Target "_blank" ] [
                Image.image [ Image.CustomClass "logo" ]
                    [ img [ Src "./images/logo/svg/PreZero_logo_RGB_petrol_green.svg" ] ]
            ]
        ]
    let socialMediaIcons = [
        {| Key = Fa.Brand.LinkedinIn; Href = "https://www.linkedin.com/company/prezero-nederland" |}
        {| Key = Fa.Brand.FacebookF; Href = "https://www.facebook.com/PreZeroNL" |}
        {| Key = Fa.Brand.Youtube; Href = "https://www.youtube.com/c/PreZeroNederland" |}
        {| Key = Fa.Brand.Twitter; Href = "https://twitter.com/PreZero_NL" |}
    ]
    let socialMediaLinks =
        Field.div [ Field.IsGrouped ] [
            for sm in socialMediaIcons do
                Button.a [
                    Button.Color IsPrimary;
                    Button.Props [ Href sm.Href; Target "_blank"; Style [
                        // styling copied from prezero.nl
                        Width "50px"
                        Height "50px"
                        Padding "0 16px"
                        MarginRight "15px"
                        BorderRadius "4px"
                        AlignItems AlignItemsOptions.Center
                        AlignSelf AlignSelfOptions.Center
                        JustifyContent "center"
                    ] ]
                ] [
                    Icon.icon [ ] [ Fa.i [ sm.Key ] [ ] ]
                ]
        ]

    Container.container [ ] [
        Columns.columns [ Columns.CustomClass "contact-info" ] [
            Column.column [ Column.Width (Screen.All, Column.Is3) ]
                [ CompanyIntro ]
            Column.column [ Column.Width (Screen.All, Column.Is3); Column.Offset (Screen.All, Column.Is6) ]
                [ socialMediaLinks ]
        ]
    ]

let footerDeleteAccount (userProfile : UserProfile) (dispatch : GlobalMsg -> unit) =
    a [ Style [ Color "#4a4a4a" ]
        OnClick (fun _ -> dispatch (SetModal (Some (DeleteAccountModal userProfile)))) ]
        [ str "Verwijder account" ]

let footer (model : GlobalModel) (dispatch : GlobalMsg -> unit) =
    let notes =
        Content.content [ Content.Size IsSmall; Content.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Justified) ] ] [
            str <| """
            De PreZero Impact Checker versie 1.7 is ontwikkeld door Impact Institute.
            Impact Institute heeft zich ingespannen om ervoor te zorgen dat de informatie en gegevens in,
            en resultaten die voortkomen uit, de Impact Checker zo volledig en nauwkeurig mogelijk zijn.
            Impact Institute garandeert echter niet dat die informatie,
            gegevens en resultaten juist en volledig zijn en daar kunnen derhalve geen rechten aan worden ontleend.
            Impact Institute en PreZero zijn niet aansprakelijk voor schade die op enige wijze wordt veroorzaakt door het gebruik,
            de onvolledigheid of onnauwkeurigheid van de Impact Checker of door beslissingen of investeringen op basis van de Impact Checker.
            PreZero denkt uiteraard graag met u mee omtrent uw resultaten en de mogelijke stappen die u kunt zetten in uw reis naar Zero Waste.
            Impact Institute is een handelsnaam van 21 Markets B.V. """
        ]

    let navTexts = [
        {| Text = "Privacy"
           PropsHref = Href "https://www.prezero.nl/privacy-statement"
           TargetIsBlank = true |}
        {| Text = "Contact"
           PropsHref = Navigation.Contact.ToHref
           TargetIsBlank = false |}
    ]

    let navTextBreadcrumbs =
        navTexts |> List.map (fun text ->
            Breadcrumb.item [ ] [
                a [ text.PropsHref
                    if text.TargetIsBlank then Target "_blank"
                    Style [ Color "#4a4a4a" ]
                ] [
                    str text.Text
                ]
            ]
        )

    Container.container [ ] [
        Columns.columns [ ] [
            Column.column [ Column.Width (Screen.All, Column.Is3) ] [
                Breadcrumb.breadcrumb [ Breadcrumb.HasDotSeparator ]
                    ( match model.UserProfile with
                      | Some user ->
                            navTextBreadcrumbs
                            @ [ Breadcrumb.item [ ] [ footerDeleteAccount user dispatch ] ]
                      | None ->
                            navTextBreadcrumbs )
            ]

            Column.column [ ]
                [ notes ]
        ]
    ]


let defaultView (model : GlobalModel) (dispatch : GlobalMsg -> unit) (preZeroPage: PreZeroPage) =
    Hero.hero [ ] [
        Hero.head [ ] [
            navigationHeader model
        ]

        Hero.body [ ] [
            match preZeroPage.Landing with
            | Some (PreZeroLanding heroElement) ->
                Section.section
                    [ Section.CustomClass "hero"; Section.Props [ Style heroElement.Props ] ]
                    [ heroElement.Content ]
            | None ->
                ()

            for prezeroSection in preZeroPage.Sections do
                Section.section
                    [
                        Section.CustomClass prezeroSection.ClassName
                        match prezeroSection.Id with
                        | Some id -> Section.Props [ Id id ]
                        | None -> ()
                    ]
                    [ Container.container [ ]
                        [ prezeroSection.ContentRoot ] ]

        ]
        Footer.footer [ ] [
            contactInfo model dispatch
            footer model dispatch
        ]
        modalDeleteAccount model dispatch
    ]


let view (globalModel : GlobalModel) (dispatch : GlobalMsg -> unit) =

    let page = globalModel.Page.ToString()

    let preZeroPage =
        match globalModel.AppStatus with
        | Idle ->

            match globalModel.Page with

            | Page.Home model ->
                Home.View.view model (HomeMsg >> dispatch)
                    globalModel.StaticContent page

            | Page.ImpactChecker model ->
                ImpactChecker.View.view model (ImpactCheckerMsg >> dispatch)
                    globalModel.StaticContent page

            | Page.VerkleinImpact model ->
                VerkleinImpact.View.view model (VerkleinImpactMsg >> dispatch)
                    globalModel.UserProfile globalModel.StaticContent page

            | Page.Contact model ->
                Contact.View.view model (ContactMsg >> dispatch)
                    globalModel.StaticContent page

            | Page.Faq model ->
                Faq.View.view model (FaqMsg >> dispatch)
                    globalModel.StaticContent page

        | CommandsInProcess n ->
            { Landing = None; Sections = [
                PreZeroSection {| Class = NoClass; Content = loadingBody globalModel dispatch; SectionId = None |}
            ]}

        | AppError errors ->
            { Landing = None; Sections = [
                PreZeroSection {| Class = NoClass; Content = errorBody globalModel dispatch errors; SectionId = None |}
            ]}

    preZeroPage |> defaultView globalModel dispatch
