module VerkleinImpact

open Shared
open PreZero

open Elmish
open External
open React.Datasheet
open React.Datasheet.VerkleinImpactGrid

[<RequireQualifiedAccess>]
type Modal =
| ResidualWasteInput
| TooManyProjects
| ProjectSavedSuccess of string

type Model = {
    ModelStatus             : ModelStatus
    Modal                   : Modal option
    SectorId                : SectorId
    ClientData              : GridRowData list
    ScenarioData            : GridRowData list
    Interventions           : Intervention list
    GlobalUnit              : Unit
    WasteStreams            : Map<WasteStreamId, WasteStream>
    UserProfile             : UserProfile option
    ProjectSelected         : Project option
    ManualResidualWaste     : bool
    ManualConstructionWaste : bool
    IsHiddenHowItWorks      : bool
    IsHiddenImproventTips   : Map<int, bool>
}

type Msg =
| SetModal of Modal option
| UpdateGridResidual of CellsChangedArgs []
| UpdateIntervention of WasteStreamId * float
| UpdateGlobalUnit of Unit
| ResetScenario
| ToggleWasteStreamSeparated of WasteStreamId
| ToggleDisplayHowItWorks
| ToggleShowImprovementTipId of int
| GetProjectList
| GotProjectList of Result<Map<ProjectId,ProjectInfo>,exn>
| SelectProject of ProjectId
| GotProjectData of Result<Project,exn>
| SaveProject of Project
| ProjectSaved of Result<Project,exn>

module Model =

    let initialModel
        (staticContent : StaticContent)
        (clientData : GridRowData list)
        (scenarioData : GridRowData list)
        (userProfile : UserProfile option)
        (project : Project option)
        (isManualResidualWaste: bool) : Model =

        let clientData' =
            if clientData.IsEmpty then GridRowData.InitialiseWasteStreamInput staticContent.WasteStreams else clientData
        let scenarioData' =
            if scenarioData.IsEmpty then clientData' else scenarioData
        let initialInterventions =
            // use clientData as scenarioData for initial interventions
            Intervention.CreateFromGridRowDataList clientData' scenarioData'
        let userProfile' =
            // not really sure what this mapping was about, but for now copying it from the old code.
            userProfile
            |> Option.map (fun user ->
                { user with
                    Projects =
                        match userProfile with
                        | Some user   -> user.Projects
                        | None        -> Map.empty
                  } )

        let initialGlobalUnit =
            match clientData' with
            | [] -> Unit.Kg
            | _ -> clientData'.Head.Unit

        { ModelStatus               = ModelStatus.Idle
          Modal                     = None
          SectorId                  = project
                                      |> Option.map (fun proj -> proj.Sector)
                                      |> Option.defaultValue CalcSetting.defaultSectorId
          UserProfile               = userProfile'
          ClientData                = clientData'
          ScenarioData              = scenarioData'
          Interventions             = initialInterventions
          GlobalUnit                = initialGlobalUnit
          WasteStreams              = staticContent.WasteStreams
          ProjectSelected           = project
          ManualResidualWaste       = isManualResidualWaste
          ManualConstructionWaste   = isManualResidualWaste
          IsHiddenHowItWorks        = true
          IsHiddenImproventTips     = // WARNING: THIS IS A STUPID SOLUTION BUT IT WORKS FOR NR TIPS < 10
                                      [ 0..10 ] |> List.map (fun i -> i, true) |> Map.ofList }

    let init (staticContent: StaticContent) (clientData : GridRowData list) (scenarioData : GridRowData list)
             (userProfile : UserProfile option) (project : Project option) (isManualResidualWaste: bool) =

        let initialCommand =
            match userProfile with
            | Some user ->
                Cmd.ofMsg GetProjectList
            | None ->
                Cmd.none

        initialModel staticContent clientData scenarioData userProfile project isManualResidualWaste, initialCommand

    let update (msg : Msg) (model : Model) =
        match msg with
        | SetModal newModal ->
            { model with Modal = newModal}, Cmd.none
        | ToggleShowImprovementTipId tipsId ->
            let model' =
                model.IsHiddenImproventTips
                |> Map.map (fun id isHidden ->
                    if id = tipsId then not isHidden else isHidden
                    )
            { model with IsHiddenImproventTips = model' }, Cmd.none
        | ToggleDisplayHowItWorks ->
            { model with IsHiddenHowItWorks = not model.IsHiddenHowItWorks }, Cmd.none
        | ToggleWasteStreamSeparated wasteStreamId ->
            let newInterventions =
                model.Interventions
                |> List.map (fun intervention ->
                    if intervention.WasteStreamId = wasteStreamId then
                        intervention.ToggleWasteStreamSeparated
                    else
                        intervention
                )
            { model with Interventions = newInterventions }, Cmd.none

        | UpdateIntervention (wasteStreamId, sliderValue) ->
            let newInterventions =
                model.Interventions
                |> List.map (fun intervention ->
                    if intervention.WasteStreamId = wasteStreamId then
                        intervention.UpdateScenario sliderValue
                    else
                        intervention
                )
                |> distributeConstructionIntervention

            { model with Interventions = newInterventions }, Cmd.none

        | UpdateGlobalUnit newUnit ->
            let newModel =
                { model with
                    GlobalUnit = newUnit
                    Interventions = model.Interventions |> List.map (Intervention.ConvertUnit newUnit) }
            newModel, Cmd.none

        | ResetScenario ->
            let newInterventions =
                model.Interventions
                |> List.map (fun intervention ->
                    { intervention with
                        Amount =
                            { intervention.Amount with
                                Scenario = intervention.Amount.Current }
                        AmountResidual =
                            { intervention.AmountResidual with
                                Scenario = intervention.AmountResidual.Current }
                        AmountConstruction =
                            { intervention.AmountConstruction with
                                Scenario = intervention.AmountConstruction.Current }
                        IsSeparated =
                            { intervention.IsSeparated with
                                Scenario = intervention.IsSeparated.Current }
                    }
                )
            { model with Interventions = newInterventions }, Cmd.none

        | UpdateGridResidual cellsChangedArgs ->
            let interventionsUpdated =
                Array.fold updateWasteAmountGridDataResidual model.Interventions cellsChangedArgs
            let clientDataUpdated =
                // The `ToCurrentGridRowData` method does NOT take care of RowId,
                // so we need to map the `GridRowData` record from the real current ClientData in model.
                let clientDataForMapping wasteStreamId =
                    interventionsUpdated
                    |> List.map (fun d -> d.ToCurrentGridRowData)
                    |> List.tryFind (fun gridData -> gridData.WasteStreamId = wasteStreamId)

                model.ClientData
                |> List.map (fun gridData ->
                    let mappedData = clientDataForMapping gridData.WasteStreamId
                    {
                        gridData with
                            Amount = mappedData |> Option.bind (fun d -> d.Amount)
                            AmountResidual = mappedData |> Option.bind (fun d -> d.AmountResidual)
                    }
                )

            let totalResidualWaste : WasteAmount =
                interventionsUpdated
                |> List.sumBy (fun intervention -> intervention.ToCurrentWasteAmount.Residual)
            let interventionsUpdatedInclResidual =
                interventionsUpdated
                |> List.map (
                    fun intervention ->
                        if intervention.WasteStreamId = CalcSetting.residualWasteStreamId then
                            { intervention with
                                Unit = totalResidualWaste.Unit
                                Amount = { intervention.Amount with
                                            Scenario = totalResidualWaste.Value
                                                       |> Rounding.roundIfAnnoying
                                                       |> Some }
                            }
                        else
                            intervention
                )
            let newModel =
                { model with
                    ManualResidualWaste = true
                    ClientData = clientDataUpdated
                    Interventions = interventionsUpdatedInclResidual }
            newModel, Cmd.none

        | GetProjectList ->
            let cmd =
                Cmd.OfAsync.perform APIs.iProjectApi.getProjectList () GotProjectList

            { model with ModelStatus = NewCommand }, cmd

        | GotProjectList result ->
            let projects =
                match result with
                | Ok projectMap ->
                    projectMap
                | Error err ->
                    printfn "FAILED TO LOAD PROJECT LIST: %s" err.Message
                    Map.empty

            let userProfile =
                model.UserProfile
                |> Option.map (fun user ->
                    { user with Projects = projects }
                )

            let cmd =
                match userProfile with
                | Some user ->
                    if model.ProjectSelected.IsNone then
                        let projectId, _ = user.Projects |> Map.toList |> List.maxBy fst
                        Cmd.ofMsg (SelectProject projectId)
                    else
                        Cmd.ofMsg ResetScenario
                | None ->
                    Cmd.none

            { model with UserProfile = userProfile }, cmd

        | SelectProject projectId ->
            let cmd =
                Cmd.OfAsync.perform
                    APIs.iProjectApi.getProjectData projectId
                    GotProjectData
            { model with ModelStatus = NewCommand }, cmd

        | GotProjectData projectResult ->
            let model =
                match projectResult with
                | Ok project ->
                    let clientData = React.Datasheet.ImpactCheckerGrid.updateGridWithProject model.ClientData project
                    { model with
                        ModelStatus         = FinishedCommand
                        SectorId            = project.Sector
                        ProjectSelected     = Some project
                        ClientData          = clientData
                        ScenarioData        = clientData
                        Interventions       = Intervention.CreateFromGridRowDataList clientData clientData
                        }
                | Error _ ->
                    { model with
                        ModelStatus      = FinishedCommand
                        ProjectSelected  = None }

            model, Cmd.ofMsg ResetScenario

        | SaveProject project ->

            let wasteData : Map<WasteStreamId, WasteGroupTriple<WasteAmount>> =
                model.ClientData
                |> List.map (fun gridData ->
                    gridData.WasteStreamId,
                    {
                        Separated    = WasteAmount.CreateFromOption gridData.Amount Unit.Kg
                        Residual     = WasteAmount.CreateFromOption gridData.AmountResidual Unit.Kg
                        Construction = WasteAmount.CreateFromOption gridData.AmountConstruction Unit.Kg
                    }
                    )
                |> Map.ofList

            let newProject =
                { project with
                    WasteData = wasteData
                    CustomResidualWaste = model.ManualResidualWaste }

            let newModel =
                {
                    model with
                        ProjectSelected = Some newProject
                        ModelStatus = NewCommand
                }

            let newCommand =
                Cmd.OfAsync.either
                    APIs.iProjectApi.saveProject newProject
                    ProjectSaved
                    (Error>>ProjectSaved)

            newModel, newCommand

        | ProjectSaved projectSavedResult ->
            match projectSavedResult with
            | Ok project ->
                { model with
                        ProjectSelected = Some project
                        ModelStatus = FinishedCommand },
                Cmd.ofMsg (SetModal (Some (Modal.ProjectSavedSuccess project.Name)))

            | Error exn ->
                { model with
                    ModelStatus = ModelError [exn.Message] },

                Cmd.ofMsg (SetModal (Some Modal.TooManyProjects))
