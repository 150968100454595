module Components.Dropdown

open Fulma
open Fable.React
open Fable.React.Props
open Fable.Core.JsInterop

open Shared

let unitDropdown (isDisabled: bool) (chosenUnit: Unit option) (onClick: Unit -> unit) =
    let selectedUnit = (chosenUnit |> Option.defaultValue Unit.Kg).ToString
    let unitOptions = [ Unit.Kg; Unit.Ton; Unit.Kton ]
    Select.select [ Select.Disabled isDisabled ] [
        select [ Disabled isDisabled
                 OnChange (fun ev ->
                    let selectedUnit : string = !!ev.target?value
                    unitOptions
                    |> List.find (fun u -> u.ToString = selectedUnit)
                    |> onClick
                 ) ] [
            for unit in unitOptions do
                match unit with
                | u when u.ToString = selectedUnit ->
                    option [ Selected true ] [ str unit.ToString ]
                | u ->
                    option [ ] [ str unit.ToString ]
            ]
        ]

let sectorDropdown
    (staticContent : StaticContent)
    (chosenSectorId : SectorId)
    (onClick : SectorId -> unit) : ReactElement =

    let sectorOptions = staticContent.Sectors |> Map.toSeq |> List.ofSeq

    Select.select [ Select.Size IsSmall ] [
        select [ OnChange (fun ev ->
            let selectedSectorName : string = !!ev.target?value
            sectorOptions
            |> List.find (fun (id, data) -> data.Name = selectedSectorName)
            |> fst |> onClick
            ) ] [
            for (sectorId, sectorData) in sectorOptions do
                match sectorId with
                | si when si = chosenSectorId ->
                    option [ Selected true ] [ str sectorData.Name ]
                | si ->
                    option [ ] [ str sectorData.Name ]
        ]
    ]

let projectDropdown
    (projectList : Map<ProjectId, ProjectInfo>)
    (chosenProjectId : ProjectId option)
    (onClick : ProjectId * ProjectInfo -> unit) : ReactElement =
    if projectList.IsEmpty then nothing
    else
        Field.div [ ] [
            Label.label [ ] [ str "Selecteer een bestaand project: " ]
            Field.div [ ] [
                Select.select [ ] [
                    select [ OnChange (fun ev ->
                        let selectedProjectName : string = !!ev.target?value
                        let selectedProjectId =
                            projectList |> Map.findKey (fun _ p -> p.Name = selectedProjectName)
                        onClick (selectedProjectId, projectList.Item selectedProjectId)
                        ) ] [
                        if chosenProjectId.IsNone then
                            option [ Selected true ] [ str "Selecteer project" ]

                        for KeyValue(projectId, project) in projectList do
                            match projectId, chosenProjectId with
                            | pi, Some(chosenId) when pi = chosenId ->
                                option [ Selected true ] [ str project.Name ]
                            | pi, _ ->
                                option [ ] [ str project.Name ]
                    ]
                ]
            ]
        ]
