[<RequireQualifiedAccess>]
module Navigation

type Route =
| Home // Intro, Facts & figures, Calculate impact and Contact
| ImpactChecker
| VerkleinImpact
| Faq
| Contact
with
    member this.Name =
        match this with
        | Home              -> "Home"
        | ImpactChecker     -> "Impact Checker"
        | VerkleinImpact    -> "Verklein je impact"
        | Faq               -> "FAQ"
        | Contact           -> "Contact"

    member this.ToHash =
        match this with
        | Home              -> "home"
        | ImpactChecker     -> "impact-checker"
        | VerkleinImpact    -> "verklein-impact"
        | Faq               -> "faq"
        | Contact           -> "contact"

    member this.ToHref =
        "#" + this.ToHash |> Fable.React.Props.Href

