module Components.Helpers

open Fulma
open Fulma.Extensions.Wikiki
open Fable.React
open Fable.React.Props
open Fable.FontAwesome
open Fable.Core.JsInterop

open PreZero

let onChange (action: string -> unit) =
    OnChange (fun e -> action !! e.target?value)

let infoTooltip (input : string) =
    span [ Style [ Color IsPrimary; TextAlign TextAlignOptions.Center ] ] [
        Icon.icon [
            Icon.CustomClass Tooltip.IsTooltipTop
            Icon.Props [ Tooltip.dataTooltip input ]
            Icon.Size IsSmall
        ] [
            Fa.i [ Fa.Solid.InfoCircle ] []
        ]
    ]

let parseParagraphWithLinks (links: {| Texts: string; Href: string |} list) (paragraph: string)  =
    let mutable pp = paragraph
    Text.p [ ] [
        Text.span [ ] [
            for link in links do
                let pairs = pp.Split( link.Texts )
                str pairs.[0]
                a [ Href link.Href; Target "_blank" ] [ str link.Texts ]
                pp <- pairs.[1]
            str pp
        ]
    ]

module CustomHeaders =

    let cardHeaderGeneral (props : HTMLAttr option) title =
        Heading.h2 [
            Heading.Props [ if props.IsSome then props.Value ]
            Heading.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ]
        ] [
            str title
        ]

    let cardHeader = cardHeaderGeneral None
    let cardHeaderPlusMargin = Class "extra-bottom-margin" |> Some |> cardHeaderGeneral

    let cardHeaderCentered title =
        Heading.h1 [ ] [ str title ]