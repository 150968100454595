module Rounding

open Shared

type M = System.Math

type LargeNumberNames =
    static member Billion = 1000000000.
    static member Million = 1000000.
    static member Thousand = 1000.

let rounding (amount: float) =
    match amount with
    | v when v < 1.  -> M.Round (v, 3)
    | v when v < 10. -> M.Round (v, 1)
    | v              -> M.Round v

let roundTwoSignificantDigits (value : float) =
    let rounding value =
        let log10 = M.Log10(abs(value))
        let nearest10fold = M.Floor(log10)
        let twoSignificantDigits = M.Round(value / 10.**nearest10fold, 1)
        M.Round(twoSignificantDigits * 10.**nearest10fold, 10)

    match value with
    | 0. -> 0.
    | v -> rounding v

let roundIfAnnoying (value : float) =
  if value = 0. then 0.
  else
    if abs (value - M.Round(value)) < 0.0000001 then
        M.Round(value)
    elif abs (value - M.Round(value, 1)) < 0.0000001 then
        M.Round(value, 1)
    elif abs (value - M.Round(value, 2)) < 0.0000001 then
        M.Round(value, 2)
    elif abs (value - M.Round(value, 3)) < 0.0000001 then
        M.Round(value, 3)
    elif abs (value - M.Round(value, 4)) < 0.0000001 then
        M.Round(value, 4)
    elif abs (value - M.Round(value, 5)) < 0.0000001 then
        M.Round(value, 5)
    else
        M.Round(value, 6)


let roundFiveSignificantDigits (value : float) =
  if value = 0. then
    0.
  else
    let log10 = M.Log10(abs(value))
    let nearest10fold = M.Floor(log10)
    let fiveSignificantDigits = M.Round(value / 10.**nearest10fold, 4)
    M.Round(fiveSignificantDigits * 10.**nearest10fold * abs(value)/value, 10)


open Fable.Core.JsInterop

let formatNL (x : float) : string = x?toLocaleString("nl-NL", string)

let formatEurNL (x : float) : string =
    sprintf "€%s" (M.Round(x, 2) |> formatNL)

let formatIntegerEurNL (x : float) : string =
    sprintf "€%s" (M.Round(x) |> formatNL)

let formatWasteAmountNL (wasteAmount : WasteAmount) =
    sprintf "%s %s" ( wasteAmount.Value |> formatNL ) wasteAmount.Unit.ToString


let printMonetisedValue (value : float) =
    match value with
    | v when v >= LargeNumberNames.Billion ->
        " mld", v / LargeNumberNames.Billion
    | v when v >= LargeNumberNames.Million ->
        " mln", v / LargeNumberNames.Million
    | v ->
        "", v
    |> (fun (unit, value) -> unit, value |> rounding |> formatNL)

type AmountWeight =
    static member scalePerAmount (amountInKg : float) =
        match amountInKg with
        | v when v >= LargeNumberNames.Million ->
            Unit.Kton
        | v when v >= LargeNumberNames.Thousand ->
            Unit.Ton
        | v ->
            Unit.Kg

    static member parseWeight (scaleUnit: Unit) (amountInKg : float) =
        scaleUnit
        |> function
           | Unit.Kton as unit ->
                unit,
                amountInKg / LargeNumberNames.Million
           | Unit.Ton as unit ->
                unit,
                amountInKg / LargeNumberNames.Thousand
           | Unit.Kg as unit ->
                unit,
                amountInKg

    static member printWeight (scaleUnit: Unit, amountInKg : float) =
        (scaleUnit, amountInKg)
        ||> AmountWeight.parseWeight
        |> (fun (unit, value) ->
                unit.Abbr,
                value |> rounding |> formatNL)

    static member printWeight (amountInKg : float) =
        let scaleUnit = AmountWeight.scalePerAmount amountInKg
        (scaleUnit, amountInKg)
        ||> AmountWeight.parseWeight
        |> (fun (unit, value) ->
                unit.Abbr,
                value |> rounding |> formatNL)
