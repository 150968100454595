module Components.Interpretation

open Fulma
open Fable.React
open Fable.React.Props

open Shared

// abbreviations
module P = Fable.React.Props
let Style = P.Style
let Color = P.CSSProp.Color

/// Card ReactElement showing result value and its interpretation.
let interpretationCard (interpretations : InterpretationOptions) (resultValue : float) =
    let resultInterpretated =
        interpretations.ResultInterpretated resultValue

    let numberOfItemsRounded = resultInterpretated.NrOfItem |> Rounding.roundTwoSignificantDigits |> Rounding.formatNL

    Field.div [ Field.IsGrouped; Field.Props [ Style [ P.Margin "1em 0 0"; P.Padding "0" ] ] ] [
        Field.div [ ]
            [ Icon.icon
                [ Icon.Size IsMedium ]
                [ i [ P.ClassName ("fas fa-lg " + interpretations.IconCode) ] [ ] ] ]
        Field.p [ ]
            [ str (" " + numberOfItemsRounded.ToString() + resultInterpretated.Description) ]
        ]