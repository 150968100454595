module Home

open Elmish

open PreZero

type Model = {
    ModelStatus : ModelStatus
}

type Msg =
| Nothing

module Model =

    let init : Model * Cmd<Msg> =
        let initialModel = { ModelStatus = ModelStatus.Idle }
        let initialCmd = Cmd.none
        initialModel, initialCmd

    let update (msg : Msg) (model : Model) =
        model, Cmd.none