module VerkleinImpact.View

open Elmish
open Fulma
open Fable.React
open Fable.React.Props

open Components.Button
open Components.Helpers
open Components.Layouts
open External
open React.Datasheet
open React.Datasheet.VerkleinImpactGrid
open Shared

open PreZero
open VerkleinImpact
open Fable.FontAwesome
open Fulma.Extensions.Wikiki
module CT = VerkleinImpact.Components.Texts

module ResultsCalculated =

    let private currentWaste model :(WasteStreamId * WasteGroupTriple<WasteAmount>) list =
        model.Interventions
        |> List.map (fun row -> row.WasteStreamId, row.ToCurrentWasteAmount)

    let private scenarioWaste model : (WasteStreamId * WasteGroupTriple<WasteAmount>) list =
        model.Interventions
        |> List.map (fun row -> row.WasteStreamId, row.ToScenarioWasteAmount)

    let calculateTotalSeparated (wastePerStream : (WasteStreamId * WasteGroupTriple<WasteAmount>) list) =
        wastePerStream
        |> List.sumBy (fun (id, wasteAmount) ->
            if id = CalcSetting.residualWasteStreamId then Kg 0.<kg> else wasteAmount.Separated
        )

    let calculateTotalResidual (wastePerStream : (WasteStreamId * WasteGroupTriple<WasteAmount>) list) =
        wastePerStream
        |> List.sumBy (fun (id, wasteAmount) -> wasteAmount.Residual)

    let calcTotalWasteAmount (wastePerStream : (WasteStreamId * WasteGroupTriple<WasteAmount>) list) =
        calculateTotalSeparated wastePerStream + calculateTotalResidual wastePerStream

    let currentWasteTotal model : WasteAmount =
        currentWaste model |> calcTotalWasteAmount

    let currentWasteResidual model : WasteAmount =
        currentWaste model |> calculateTotalResidual

    let scenarioWasteTotal model : WasteAmount =
        scenarioWaste model |> calcTotalWasteAmount

    let scenarioWasteSeparated model : WasteAmount =
        scenarioWaste model |> calculateTotalSeparated

    let currentImpact model staticContent : CalculatedImpact =
        currentWaste model
        |> Map.ofList
        |> Calculation.calculateImpact staticContent

    let scenarioImpact model staticContent : CalculatedImpact =
        scenarioWaste model
        |> Map.ofList
        |> Calculation.calculateImpact staticContent


/// Section "De impact van jouw interventie" showing the results of user's intervention.
module SectionResultsIntervention =
    let private boxResultOverview (results: {| OwnImpact: CalculatedImpact; ScenarioImpact: CalculatedImpact|}) =
        let boxResultBeforeAndAfter (result: {| Current: float; After: float; IsUnitsMonetised: bool |}) =
            let prefixCurrencySymbol, resultUnits, resultFaIcon =
                match result.IsUnitsMonetised with
                | true -> "€", "impact in euro's", Fa.Solid.MoneyBill
                | false -> "", "CO2-uitstoot", Fa.Solid.Cloud


            let (nameCurrent, resultCurrent), (nameAfter, resultAfter) =
                if result.IsUnitsMonetised then
                    Rounding.printMonetisedValue result.Current,
                    Rounding.printMonetisedValue result.After
                else
                    Rounding.AmountWeight.printWeight result.Current,
                    Rounding.AmountWeight.printWeight result.After

            Field.div [ ] [
                Columns.columns [ Columns.IsMultiline ] [
                    Column.column [ ]
                        [ Level.level [ Level.Level.Props [ Style [ MarginBottom 0 ] ] ] [
                            Level.item [ Level.Item.HasTextCentered ] [
                                Level.heading [ ] [ str "HUIDIG" ]
                            ]
                          ]
                          div [ Style [ Display DisplayOptions.Flex
                                        AlignItems AlignItemsOptions.Center
                                        Height "100%" ] ] [
                            Text.p [ Props [ Style [ TextAlign TextAlignOptions.Center
                                                     Margin "auto" ] ] ] [
                                str (prefixCurrencySymbol + resultCurrent)
                                span [ Style [ FontSize "0.75rem" ] ]
                                    [ str nameCurrent ]
                            ]
                          ] ]

                    Column.column [ ]
                        [ Level.level [ Level.Level.Props [ Style [ MarginBottom 0 ] ] ] [
                            Level.item [ Level.Item.HasTextCentered ] [
                                Level.heading [ ] [ str "NA INTERVENTIE" ]
                            ]
                          ]
                          div [ Style [ Display DisplayOptions.Flex
                                        AlignItems AlignItemsOptions.Center
                                        Height "100%" ] ] [
                            Level.title [ Props [ Style [
                                FontSize "1.5rem"
                                WhiteSpace WhiteSpaceOptions.Nowrap
                                TextAlign TextAlignOptions.Center
                                Margin "auto"
                                ] ] ] [
                                str (prefixCurrencySymbol + resultAfter)
                                span [ Style [ FontSize "0.75rem" ] ]
                                    [ str nameAfter ]
                            ]
                          ] ]
                ]
                Field.div [ Field.IsGrouped ]
                    [ Icon.icon [ ] [ Fa.i [ resultFaIcon ] [ ] ]
                      Text.p [ Props [ Style [ MarginLeft ".25em" ] ] ] [ str resultUnits ] ]
            ]


        Columns.columns [ Columns.Props [ Style [ MarginBottom "20px" ] ] ] [
            Column.column [ Column.Width (Screen.All, Column.IsNarrow) ]
                [ Box.box' [ Props [ ClassName "box info-card" ] ] [
                    boxResultBeforeAndAfter
                        {| Current = Calculation.co2Values results.OwnImpact * 1.</kg>
                           After   = Calculation.co2Values results.ScenarioImpact * 1.</kg>
                           IsUnitsMonetised = false |} ]
                ]
            Column.column [ Column.Width (Screen.All, Column.IsNarrow) ]
                [ Box.box' [ Props [ ClassName "box info-card" ] ] [
                    boxResultBeforeAndAfter
                        {| Current = Calculation.totalImpact results.OwnImpact
                           After   = Calculation.totalImpact results.ScenarioImpact
                           IsUnitsMonetised = true |} ]
                ]
        ]

    let private boxResultInterventionTranslation (results: {| OwnImpact: CalculatedImpact; ScenarioImpact: CalculatedImpact|}) staticContent =
        let differencesInterventions =
            results.OwnImpact.ByImpactId
            |> Array.map (fun (impactId, ownImpact) ->
                let _, scenarioImpact =
                    results.ScenarioImpact.ByImpactId |> Array.find (fun (ii, _) -> impactId = ii)
                impactId,
                {| Scenario = scenarioImpact
                   Marginal = ownImpact - scenarioImpact |}
                )
        let interpretations =
            differencesInterventions
            |> Array.map (fun (impactId, diffImpactMonetized) ->
                {|
                    ImpactId = impactId
                    ScenarioImpact = diffImpactMonetized.Scenario
                    ScenarioInterpretation = Calculation.showInterpretation staticContent impactId diffImpactMonetized.Scenario.Float
                    MarginalInterpretation = Calculation.showInterpretation staticContent impactId diffImpactMonetized.Marginal.Float
                |}
            )

        let remainingInterpretations, targetInterpretation =
            let targetImpactId = ImpactId 3 // Eq nr of flights
            let targetInterpretation =
                interpretations |> Array.find (fun ii -> ii.ImpactId = targetImpactId)
            let remainingInterpretations =
                interpretations |> Array.filter (fun ii -> ii.ImpactId <> targetImpactId)

            remainingInterpretations, targetInterpretation

        Field.div [ ] [
            Box.box' [ Props [ ClassName "box info-card" ] ] [
                let faIcon =
                    targetInterpretation.MarginalInterpretation.FaIconCode
                    |> Option.map (fun iconCode -> "fas fa-lg " + iconCode)
                    |> Option.map (fun className ->
                        Icon.icon [ Icon.Size IsLarge; Icon.Props [ Style [ MarginRight ".5em" ] ] ]
                            [ Fa.i [ Fa.Size Fa.ISize.Fa2x; Fa.CustomClass className ] [ ] ]
                        )
                    |> Option.defaultValue nothing
                let nrItem = targetInterpretation.MarginalInterpretation.NumberOfItemsRawValue |> Rounding.roundTwoSignificantDigits
                let interpretationDesc =
                    Rounding.formatNL nrItem + targetInterpretation.MarginalInterpretation.Description
                let impactName =
                    Calculation.showImpactName staticContent targetInterpretation.ImpactId

                Field.div [ Field.IsGrouped ]
                    [ Field.div [ ] [ faIcon ]
                      Field.div [ ]
                        [ Heading.h5 [ Heading.Props [ Style [ MarginBottom "10px" ] ] ]
                            [ str impactName ]
                          Text.p [ ] [ str interpretationDesc ] ] ]
            ]

            for ii in remainingInterpretations do
                let faIcon =
                    ii.MarginalInterpretation.FaIconCode
                    |> Option.map (fun iconCode -> "fas fa-lg " + iconCode)
                    |> Option.map (fun className ->
                        Icon.icon [ Icon.Props [ Style [ MarginRight ".5em" ] ] ]
                            [ Fa.i [ Fa.Size Fa.ISize.FaLarge; Fa.CustomClass className ] [ ] ]
                        )
                    |> Option.defaultValue nothing

                let nrItemMarginal = ii.MarginalInterpretation.NumberOfItemsRawValue |> Rounding.roundTwoSignificantDigits
                let interpretationMarginalDesc =
                    Rounding.formatNL nrItemMarginal + ii.MarginalInterpretation.Description
                let interpretationScenarioDesc =
                    let nrItem = ii.ScenarioInterpretation.NumberOfItemsRawValue |> Rounding.roundTwoSignificantDigits
                    Rounding.formatNL nrItem + ii.ScenarioInterpretation.Description
                let impactName =
                    Calculation.showImpactName staticContent ii.ImpactId

                Dropdown.dropdown [ Dropdown.IsHoverable; Dropdown.IsRight; Dropdown.CustomClass "onhover-bg-grey" ] [
                    Dropdown.trigger [ ] [
                        Field.div [ Field.IsGroupedMultiline
                                    Field.Props [ Style [ MarginBottom "10px" ] ] ]
                            [ faIcon
                              Text.p [ Modifiers [ Modifier.TextWeight TextWeight.Bold ]
                                       Props [ Style [ MarginRight ".5em" ] ] ] [ str impactName ]
                              Text.p [ ] [ str interpretationMarginalDesc ] ]
                    ]
                    Dropdown.menu [ ] [
                        Box.box' [ ] [
                            let msg =
                                match nrItemMarginal with
                                | nr when nr < 0. ->
                                    sprintf "Je interventie kost %s extra!" <|
                                        // remove the minus sign prefix
                                        interpretationMarginalDesc.Substring 1
                                | nr ->
                                    sprintf "Je hebt %s bespaard!" interpretationMarginalDesc

                            Label.label [ ] [ str impactName ]
                            Field.div [ Field.IsGrouped ] [
                                Label.label [ Label.Props [ Style [ PaddingRight ".5em" ] ] ] [ str "IMPACT IN EURO'S:" ]
                                span [ ] [ str (ii.ScenarioImpact.Float |> Rounding.formatEurNL) ]
                                ]
                            Field.div [ ] [
                                faIcon
                                span [ ] [ str interpretationScenarioDesc ]
                            ]
                            Text.p [ ] [ str msg ]
                        ]
                    ]
                ]

        ]
    let sectionResultsIntervention (dataResults: {| OwnImpact: CalculatedImpact; ScenarioImpact: CalculatedImpact |}) staticContent =
        Container.container [ ] [
            Field.div [ ] [
                Heading.h3 [ ] [ str "De impact van jouw interventie" ]
                boxResultOverview dataResults
            ]
            Field.div [ ] [
                Heading.h3 [ ] [ str "Je bespaart" ]
                boxResultInterventionTranslation dataResults staticContent
            ]
        ]

let view (model: Model) (dispatch : Msg -> unit) (userProfile : UserProfile option) (staticContent : StaticContent) page =

    let sectionLanding =
        let switchHowItWorks =
            Field.div [ Field.IsGrouped; Field.Props [
                    Style [ PaddingTop "1em"; Cursor "pointer"; PreZeroColor.RoyalBlue.CSSProp ]
                    OnClick (fun _ -> dispatch ToggleDisplayHowItWorks)
                    ] ] [
                Text.p [ ] [ str "Zo werkt het" ]
                model.IsHiddenHowItWorks
                |> function
                    | true  -> Icon.icon [ ] [ Fa.i [ Fa.Regular.PlusSquare ] [ ] ]
                    | false -> Icon.icon [ ] [ Fa.i [ Fa.Regular.MinusSquare ] [ ] ]
            ]
        let boxHowItWorks =
            Field.div [ ]
                [ Components.Texts.explanationHowItWorks ]

        let boxCTA =
            let boxContent =
                if model.ClientData |> List.forall (fun r -> r.Empty) then
                    // if no project is entered, refer to Impact Checker page
                    {|
                        Title = "Benieuwd hoe jij jouw impact kan verkleinen? Voer je afvalgegevens in op de Impact Checker pagina."
                        Benefits = [ ]
                        Button =
                            {|
                                Text = "Bereken je impact"
                                Href = Navigation.ImpactChecker.ToHref
                            |}
                    |}

                else
                    // if some project is entered, advice user to create account resp. get in touch
                    if userProfile.IsSome then

                        // USER LOGGED-IN
                        {|
                            Title = "Exact weten wat het potentieel is van jouw restafval? Wij doen graag een grondstoffenanalyse."
                            Benefits = [
                                "Krijg inzicht in je afvalstromen"
                                "Vind jouw waardevolle stromen"
                                "Ontdek het recycling potentieel"
                                ]
                            Button =
                                {|
                                    Text = "Neem contact met ons op"
                                    Href = Navigation.Contact.ToHref
                                |}
                        |}

                    else
                        // NOT LOGGED-IN
                    {|
                        Title = "Deze gegevens opslaan? Maak een gratis account voor je organisatie."
                        Benefits = [
                            "Krijg inzicht in je afvalstromen"
                            "Sla je gegevens op"
                            "Verminder makkelijker je impact"
                            "Gratis advies van onze impactexperts"
                            ]
                        Button =
                            {|
                                Text = "Account aanmaken"
                                Href = Href Shared.Route.register
                            |}
                    |}


            Box.box' [ Props [ ClassName "box info-card cta" ] ] [
                Heading.h5 [ ] [ str boxContent.Title ]
                Field.div [ ] [
                    ul [ ] [
                        for benefit in boxContent.Benefits do
                            li [ ] [ str benefit ]
                    ]
                ]
                Button.a [ Button.Color IsPrimary
                           Button.Props [ boxContent.Button.Href ] ]
                    [ Text.p [ ] [ str boxContent.Button.Text ]
                      Icon.icon [ ] [ Fa.i [ Fa.Solid.ArrowRight ] [ ] ] ]
            ]
        Columns.columns [ ] [
            Column.column [ Column.Width (Screen.All, Column.Is7) ] [
                Heading.h2 [ ] [ str "Verklein je impact" ]
                Text.p [ ] [ str """
                Om de negatieve impact van je afval te verkleinen, kun je simpelweg minder afval produceren.
                Maar vaak is dit nog niet zo gemakkelijk.
                Een goed alternatief is afval scheiden bij de bron.
                Hiermee kunnen wij jouw afvalstromen recyclen en wordt de milieu-impact verkleind.
                """ ]
                switchHowItWorks
                if not model.IsHiddenHowItWorks then boxHowItWorks
            ]
            Column.column [ Column.Width (Screen.All, Column.Is5) ]
                [ boxCTA ]
        ]

    let boxPlayWithInterventions =
        Field.div [ ] [
            Heading.h3 [ ] [ str "Jouw afvalstromen" ]
            Heading.h6 [ Heading.Props [ Style [ MarginBottom "10px"; MarginTop "20px" ] ]
            ] [ str "Stap 2 van 2" ]
            Section.section
                [ Section.CustomClass "container-with-border" ] [
                Heading.h4 [ ] [ str "Minder produceren of meer scheiden?" ]
                Field.div [ ] [
                    Text.p [ ] [ str """
                    Niet iedere afvalstroom is even vervuilend. Daarnaast kan de toegevoegde waarde van het
                    recyclen sterk verschillen per materiaalsoort. Je kunt hier je afval herverdelen over de
                    afvalstromen om zo een beeld te krijgen van de milieuwinst die te behalen valt door meer
                    of anders te gaan scheiden.
                    """ ]
                ]

                Components.modalManualResidualWastes model userProfile dispatch

                if userProfile.IsSome then nothing else
                    Box.box' [ Props [ ClassName "box info-card" ] ]
                        [ Text.p [ ] [ str "Wil je kunnen schuiven met al je stromen, in plaats van alleen papier, glas, swill en bedrijfsafval?" ]
                          Text.p [ Props [ Style [ MarginTop "15px" ] ] ] [
                            //   Button.a [ Button.Color IsPrimary
                            //              Button.Props [ Href Shared.Route.register ] ]
                            // [ str "Maak dan hier een account aan" ]
                              a [ Href Shared.Route.register ] [
                                  Text.p [ Props [ Style [ TextDecoration "underline" ] ] ]
                                    [ str "Maak dan hier een account aan." ]
                              ]
                          ]
                        ]

                Level.level [ ] [
                    Level.left [ ] [
                        Level.level [ ] [
                            Level.item [ ] [ Label.label [ ] [ str "Reset naar beginwaardes" ] ]
                            Level.item [ ] [
                                Button.button
                                    [ Button.OnClick (fun _ -> dispatch ResetScenario) ]
                                    [ Icon.icon [ Icon.Props [ ] ] [ Fa.i [ Fa.Solid.Sync ] [ ] ] ]
                            ]
                        ]
                    ]
                    Level.right [ ] [
                        match model.UserProfile with
                        | Some user ->
                            let projects = user.Projects |> Map.toList
                            if projects |> List.isEmpty then nothing
                            else
                                Field.div [ ] [
                                    Components.projectSelection projects model.ProjectSelected dispatch
                                ]
                        | None ->
                            nothing
                     ]
                ]

                // PLAY WITH INTERVENTIONS
                Table.table [ Table.IsStriped; Table.IsFullWidth ] [
                    thead [ ] [
                        tr [ ] [
                            th [ ] [ str "SOORT AFVAL" ]
                            th [ Class "has-text-centered" ] [ str "AFVALPRODUCTIE AANPASSEN" ]
                            th [ Class "has-text-centered" ] [ str "HUIDIG" ]
                            th [ Class "has-text-centered" ] [ str "GESCHEIDEN" ]
                            th [ Class "has-text-centered" ] [ str "CO2-UITSTOOT" ]
                            th [ Class "has-text-centered" ] [ str "IMPACT IN EURO'S" ]
                        ]
                    ]
                    tbody [ ] [

                        let interventionsExclResidualAndConstructionOnly =
                            model.Interventions
                            |> List.filter (fun intervention ->
                                not intervention.IsConstructionOnly
                                &&
                                intervention.IsSeparatable
                                &&
                                intervention.WasteStreamId <> CalcSetting.residualWasteStreamId)

                        Components.rowInterventionResidual
                            <| model.Interventions
                            <| ResultsCalculated.currentImpact model staticContent
                            <| ResultsCalculated.scenarioImpact model staticContent
                            <| dispatch

                        // rows waste stream data
                        for intervention in interventionsExclResidualAndConstructionOnly do
                            Components.rowIntervention model.UserProfile.IsSome
                            <| intervention
                            <| ResultsCalculated.currentImpact model staticContent
                            <| ResultsCalculated.scenarioImpact model staticContent
                            <| dispatch

                    ]
                    tfoot [ ] [
                        tr [ ] [
                            th [ ] [ str "Totaal" ]
                            th [ Class "has-text-centered" ] [
                                let scenarioTotal = ResultsCalculated.scenarioWasteTotal model
                                str (Rounding.formatWasteAmountNL scenarioTotal)
                            ]
                            th [ Class "numerical-value" ] [
                                let currentTotal = ResultsCalculated.currentWasteTotal model
                                str (Rounding.formatWasteAmountNL currentTotal)
                            ]
                            th [ Class "has-text-centered" ] [
                                let scenarioSeparated = ResultsCalculated.scenarioWasteSeparated model
                                str (Rounding.formatWasteAmountNL scenarioSeparated)
                            ]

                            th [ Class "numerical-value" ] [

                                // let totalCurrentCO2 =
                                //     ResultsCalculated.currentImpact model staticContent
                                //     |> Calculation.co2Values
                                //     |> fun v -> v * 1.<1/kg>

                                let totalScenarioCO2 =
                                    ResultsCalculated.scenarioImpact model staticContent
                                    |> Calculation.co2Values
                                    |> fun v -> v * 1.<1/kg>

                                let totalScenarioCO2Scaled =
                                    totalScenarioCO2
                                    |> Rounding.AmountWeight.printWeight
                                    |> fun (unit, value) -> sprintf "%s %s" value unit

                                p [ Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
                                    str ( totalScenarioCO2Scaled )
                                    // span [ Style [ Width "40px"; Display DisplayOptions.InlineBlock ] ] [
                                    //     if totalScenarioCO2 = totalCurrentCO2 then
                                    //         nothing
                                    //     elif totalScenarioCO2 > totalCurrentCO2 then
                                    //         Icon.icon [ Icon.Props [ Style [ Color "red" ] ] ] [ Fa.i [ Fa.Solid.ArrowUp ] [ ] ]
                                    //     else
                                    //         Icon.icon [ Icon.Props [ Style [ Color "green" ] ] ] [ Fa.i [ Fa.Solid.ArrowDown ] [ ] ]
                                    // ]
                                    ]
                            ]

                            th [ Class "numerical-value" ] [

                                let totalCurrentImpact =
                                    ResultsCalculated.currentImpact model staticContent
                                    |> Calculation.totalImpact

                                let totalScenarioImpact =
                                    ResultsCalculated.scenarioImpact model staticContent
                                    |> Calculation.totalImpact

                                p [ Style [ WhiteSpace WhiteSpaceOptions.Nowrap ] ] [
                                    str ( totalScenarioImpact |> Rounding.formatEurNL )
                                    span [ Style [ Width "40px"; Display DisplayOptions.InlineBlock ] ] [
                                        if totalScenarioImpact = totalCurrentImpact then
                                            nothing
                                        elif totalScenarioImpact > totalCurrentImpact then
                                            Icon.icon [ Icon.Props [ Style [ Color "red" ] ] ] [ Fa.i [ Fa.Solid.ArrowUp ] [ ] ]
                                        else
                                            Icon.icon [ Icon.Props [ Style [ Color "green" ] ] ] [ Fa.i [ Fa.Solid.ArrowDown ] [ ] ]
                                    ]
                                    ]
                            ]
                        ]
                    ]
                ]
            ]
            Content.content [ Content.Size IsSmall ] [
                p [ ] [
                    str """
                    * De rij Bedrijfsafval geeft informatie over de totale hoeveelheid ongescheiden afval, inclusief de stromen die voorkomen in de rest van de tabel. Hierdoor telt de weergegeven impact van alle stromen plus bedrijfsafval niet altijd op tot het werkelijke totaal zoals weergegeven in de rij Totaal.
                    """
                ]
                p [ ] [
                    str """
                    Disclaimer: de uitkomsten die hier te zien zijn, zijn gebaseerd op onderzoek van PreZero.
                    Desalniettemin zijn de resultaten indicatief en dienen ter illustratie om de impact op het klimaat weer te geven.
                    """
                ]
            ]
        ]

    let boxResults =
        let dataResults =
            {| OwnImpact      = ResultsCalculated.currentImpact model staticContent
               ScenarioImpact = ResultsCalculated.scenarioImpact model staticContent |}

        Field.div [ ] [
            SectionResultsIntervention.sectionResultsIntervention dataResults staticContent
        ]

    let sectionInterventionsResults =
        Field.div [ ] [
            Columns.columns [ ] [
                Column.column [ Column.Width (Screen.All, Column.Is8) ]
                    [ boxPlayWithInterventions ]
                Column.column [ ]
                    [ boxResults ]
            ]
        ]

    let improveTips =
        let tips =
            Components.ImprovementTips.improvementTips
            |> List.map (fun tip ->
                Field.div [ ]
                    [ Components.ImprovementTips.ImprovementTips.Card tip model dispatch ]
                )
        Section.section [ ]
            [ Columns.columns [ ]
                [ Column.column
                    [ Column.Width (Screen.All, Column.Is6) ]
                    [ Container.container [ Container.CustomClass "verklein-impact tips-list" ]
                        ( [ Heading.h2 [ ] [ str "Praktische tips" ] ]
                          @ [ Text.p [ ] [ CT.verkleinJeImpactTipsIntro ] ]
                          @ [ Level.level [ ] [ ] ]
                          @ tips ) ]
                  Column.column [ ] [
                        Field.div [ Field.Props [ Style [ Margin "auto 2em"; Height "100%" ] ] ]
                            [ Image.image
                                [ Image.CustomClass IsRoundedCorner.IsBottomRightCorner.ClassName
                                  Image.IsSquare ]
                                [ img [ Src "./images/ecoo flesje.png"
                                        Style [ ObjectFit "cover" ] ] ] ]
                    ] ] ]

    let sectionMoreTipsAndContact =
        Section.section [ ] [
            Heading.h2 [ ] [ str "Onze afvalexperts staan voor je klaar!" ]
            Field.div [ ] [ CT.moreTips ]
            Field.div [ ] [ CT.contactPart ]
            Field.div [ ] [ hrefButton (Navigation.Contact.ToHref, "Neem contact op") ]
        ]

    let isAnyWasteAmountEntered =
        [
            model.ClientData |> List.forall (fun data -> data.Amount |> Option.defaultValue 0. = 0.) |> not
            model.UserProfile |> Option.map (fun user -> user.Projects.IsEmpty |> not) |> Option.defaultValue false
        ]
        |> List.exists id // if any boolean value from the list is true

    let preZeroSections =
        [
            PreZeroSection {| Class = CompanyIntro; Content = sectionLanding; SectionId = None |}
            if isAnyWasteAmountEntered then
                PreZeroSection {| Class = BackgroundWhite
                                  Content = sectionInterventionsResults
                                  SectionId = None |}
            PreZeroSection {| Class = CompanyIntro; Content = improveTips;SectionId = None |}
            PreZeroSection {| Class = BackgroundWhite; Content = sectionMoreTipsAndContact; SectionId = None |}
        ]

    { Landing = None; Sections = preZeroSections }

