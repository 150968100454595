namespace External.GoogleAnalytics

open Shared

open Fable.Core

type GaTracked =
| VisitPage of Navigation.Route
| UserProfileLoaded of UserProfile option

module GoogleAnalytics =

    [<Emit("ga('set', 'title', $0);")>]
    let private gaSetPageTitle (pageTitle: string) = jsNative

    [<Emit("ga('send', 'event', $0, $1, $2);")>]
    let private gaSendEvent3 (category: string) (action: string) (label: string option)  = jsNative

    [<Emit("dataLayer.push({'event': $0, 'virtualPageURL': $1});")>]
    let private gtmPushVirtualPageEvent (event: string) (virtualPageUrl: string)= jsNative

    [<Emit("dataLayer.push({'event': $0, 'user-id': $1});")>]
    let private gtmPushLoginEvent (event: string) (userId: int option)= jsNative

    let trackAction (gaTracked: GaTracked) =
        match gaTracked with
        | VisitPage route ->
            let virtualPageUrl = "/"+ route.ToHash
            gtmPushVirtualPageEvent "change-virtual-page" virtualPageUrl
        | UserProfileLoaded userProfile ->
            let loginAction = if userProfile.IsSome then "login-successful" else "not-logged-in"
            let userId = userProfile |> Option.map (fun userProfile -> userProfile.UserId)
            gtmPushLoginEvent loginAction userId

