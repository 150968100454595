module ImpactChecker.Components

open Fable.React
open Fable.React.Props
open Fulma

open Shared

let cardsResultSummary ownImpact ownWasteAmount =
    let resultCard (result: {| Value: string; Units: string; TooltipMsg: string |}) =
        Card.card [ CustomClass "box info-card"
                    Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]
                    Props [ Style [ MaxWidth "250px"; Margin "auto"; Padding "2em" ] ] ]
            [ div [ Style [ Right ".5rem"; Top ".5rem"; Position PositionOptions.Absolute ] ]
                [ Components.Helpers.infoTooltip result.TooltipMsg ]
              Heading.h2 [ Heading.Props [ Style [ MarginBottom "unset" ] ] ]
                [ str result.Value ]
              Content.content [ Content.Size IsSmall ]
                [ str result.Units ] ]

    let fractionRecycled (data : WastePerStream) : Percentage =
        let totalWeight =
            data
            |> Map.map (fun _ waste -> waste.Separated)
            |> Map.fold (fun state key value -> state + value) (Kg 0.<kg>)
        let recycledWeight =
            data
            |> Map.filter (fun key _ -> key <> PreZero.CalcSetting.residualWasteStreamId)
            |> Map.fold (fun state key waste -> state + waste.Separated) (Kg 0.<kg>)
        let fraction= (recycledWeight / totalWeight)
        if System.Double.IsNaN fraction then Percentage 0. else Percentage fraction

    let cardResultEmissions =
        let result =
            Calculation.co2Values ownImpact
            |> fun v -> v * 1.</kg>
            |> System.Math.Round |> Rounding.formatNL

        resultCard
            {|
                Value = result
                Units = "kg CO2-uitstoot"
                TooltipMsg = "Dit is de uitstoot voor de verwerking van jouw afval o.b.v. een Nederlands gemiddelde."
            |}

    let cardResultFractionRecycled =
        let result =
            fractionRecycled ownWasteAmount
            |> fun (Percentage x) -> Rounding.roundTwoSignificantDigits (x * 100.)
            |> Rounding.formatNL
            |> sprintf "%s%%"
        resultCard
            {|
                Value = result
                Units = "van je afval gerecycled"
                TooltipMsg = "Dit is het percentage wat wordt gerecycled, indien aan alle specificaties is voldaan."
            |}

    let cardResultImpactMonetised =
        let result =
            ownImpact
            |> Calculation.totalImpact
            |> System.Math.Round
            |> Rounding.formatNL
            |> sprintf "€%s"
        resultCard
            {|
                Value = result
                Units = "Totale impact in euro’s"
                TooltipMsg = "Dit is de totale gemonetariseerde impact van jouw afval. \nDe verdeling per afvalstroom is onderaan de pagina te vinden."
            |}
    Columns.columns [ Columns.IsGap (Screen.All, Columns.Is8) ]
        [
            Column.column [ ] [ cardResultEmissions ]
            Column.column [ ] [ cardResultFractionRecycled ]
            Column.column [ ] [ cardResultImpactMonetised ]
        ]

let tableResultPerImpact (ownImpact: PreZero.CalculatedImpact) staticContent =
    let sortedOwnImpact =
        ownImpact.ByImpactId
        |> Array.sortByDescending (fun (id, value) -> value.Float)

    Table.table [ Table.IsStriped; Table.IsFullWidth ] [
        thead [ ] [
            tr [ ] [
                th [ Class "has-text-left" ] [ str "IMPACT" ]
                th [ Class "has-text-right" ] [ str "IMPACT IN EURO'S" ]
                th [ Class "has-text-left" ] [ str "WAT BETEKENT DAT?" ]
            ]
        ]
        tbody [ ] [
            for (impactId, monetisedImpact) in sortedOwnImpact do
                let interpretation =
                    Calculation.showInterpretation staticContent impactId monetisedImpact.Float
                let interpretationDesc =
                    let nrItem = interpretation.NumberOfItemsRawValue |> Rounding.roundTwoSignificantDigits |> Rounding.formatNL
                    nrItem + interpretation.Description
                tr [ ] [
                    td [ ] [ str (Calculation.showImpactName staticContent impactId) ]
                    td [ ClassName "numerical-value" ]
                       [ str (monetisedImpact.Float |> Rounding.formatIntegerEurNL) ]
                    td [ ] [ str interpretationDesc ]
                ]
        ]
    ]

let tableResultPerWastestream
    (ownImpact: string * PreZero.CalculatedImpact)
    (comparisonImpact: string * PreZero.CalculatedImpact)
    staticContent =
    let titleOwnImpact, dataOwnImpact = ownImpact
    let titleComparisonImpact, dataComparisonImpact = comparisonImpact

    let sortedOwnImpact =
        dataOwnImpact.ByWasteStreamId
        |> Array.sortByDescending (fun (id, value) -> value.Float)

    let getEmissionsPerWasteSteam (calculatedImpact: PreZero.CalculatedImpact) (wasteStreamId: WasteStreamId) =
        calculatedImpact.SeparatedPlusResidualImpact
        |> Map.filter (fun (wsId, impactId) impact ->
            impactId = PreZero.CalcSetting.climateWasteId &&
            wsId = wasteStreamId)
        |> Map.toList
        |> List.sumBy (fun (ids, impact) -> impact.Float)
        |> fun v -> v / PreZero.CalcSetting.climateMonetisationFactor / (1.<kg/eur>)
        |> Rounding.roundTwoSignificantDigits |> Rounding.formatNL

    let headerOwnImpact' =
        titleOwnImpact
        |> function
           | "" -> "EIGEN IMPACT"
           | projectName -> projectName.ToUpper()

    let headerComparisonImpact' =
        titleComparisonImpact
        |> function
           | "" -> "SECTORGEMIDDELDE"
           | projectName -> projectName.ToUpper()

    Table.table [ Table.IsStriped; Table.IsFullWidth ] [
        thead [ ] [
            tr [ ] [
                th [ Class "has-text-left" ] [ str "JOUW NEGATIEVE MILIEU-IMPACT" ]
                th [ ColSpan 2 ; Class "has-text-centered" ] [ str headerOwnImpact' ]
                th [ ColSpan 2 ; Class "has-text-centered" ] [ str headerComparisonImpact' ]
            ]
            tr [ ] [
                th [ Class "has-text-left"; Style [ Width "30%" ]] [ str "AFVALSTROOM" ]
                th [ Class "has-text-right" ; Style [ Width "15%"  ]] [ str "CO2-UITSTOOT" ]
                th [ Class "has-text-right" ; Style [ Width "20%"  ]] [ str "IMPACT IN EURO'S" ]
                th [ Class "has-text-right" ; Style [ Width "15%"  ] ][ str "CO2-UITSTOOT" ]
                th [ Class "has-text-right" ; Style [ Width "20%"  ]] [ str "IMPACT IN EURO'S" ]
            ]
        ]
        tbody [ ] [
            for (wsId, monetisedImpact) in sortedOwnImpact do
                let emissions =
                    getEmissionsPerWasteSteam dataOwnImpact wsId

                let value =
                    monetisedImpact.Float |> Rounding.formatIntegerEurNL

                let sectorImpact =
                    dataComparisonImpact.ByWasteStreamId
                    |> Array.find (fun (benchmarkWsId, v) -> benchmarkWsId = wsId)
                    |> snd

                let sectorEmissions =
                    getEmissionsPerWasteSteam dataComparisonImpact wsId

                let sectorImpactValue = sectorImpact.Float |> Rounding.formatIntegerEurNL

                tr [ ] [
                    td [ ] [ str (Calculation.showWasteStreamName staticContent wsId) ]
                    td [ ClassName "numerical-value" ] [ str (sprintf "%s kg" emissions) ]
                    td [ ClassName "numerical-value" ] [ str value ]
                    td [ ClassName "numerical-value" ] [ str (sprintf "%s kg" sectorEmissions) ]
                    td [ ClassName "numerical-value" ] [ str sectorImpactValue ]
                ]
        ]
    ]