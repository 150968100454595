module APIs

open Fable.Remoting.Client

open Shared

/// A proxy you can use to talk to server directly
let iStaticContentApi : IStaticContentApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IStaticContentApi>

let iSendEmailApi : ISendEmailApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<ISendEmailApi>

let UserProfileApi : IUserProfileApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IUserProfileApi>

let iProjectApi : IProjectApi =
    Remoting.createApi()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IProjectApi>