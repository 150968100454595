module PreZero.Contact.View

open Fulma
open Fable.React
open Fable.React.Props

open Shared
open Components
open Components.Layouts
open Components.Helpers
open PreZero
open PreZero.Contact

let view (model: Model) (dispatch : Msg -> unit) staticContent page =

    let modal =
        let title, bodyText =
            match model.Modal with
            | Some (EmailSentError error) -> "Error tijdens het sturen van bericht", sprintf "Er is een error opgetreden tijdens het versturen van je bericht. Neem contact op met PreZero. %s" error
            | Some EmailSentSucces -> "Email was succesvol verstuurd", "Het versturen van je bericht is gelukt. Er wordt een kopie naar je emailadres gestuurd. Wij komen zo spoedig mogelijk bij je terug."
            | Some EmptyFormError -> "Niet alle velden ingevuld", "Gelieve alle velden in te vullen."
            | None -> "-","-"

        Components.Modal.modalTemplate
            model.Modal.IsSome
            title
            [ str bodyText ]
            dispatch
            (SetModal None)

    let nameField =
        FormField.string
            (Some "Naam") "b.v. Ashley..."
            model.Email.Name
            [ onChange (SetName >> dispatch) ]

    let emailField =
        FormField.email
            (Some "E-mailadres") "voorbeeld@email.com"
            model.Email.EmailAddress
            [ onChange (SetEmailAddress >> dispatch) ]

    let messageField =
        FormField.textArea
            (Some "Bericht") "Typ hier je bericht"
            model.Email.Message
            [ onChange (SetMessage >> dispatch) ]

    let sendEmailButton =
        Button.a [
            Button.Color IsPrimary
            Button.Props [ OnClick (fun _ -> dispatch SendEmail) ]
        ] [
            str "Verstuur"
        ]

    let preZeroSections =
        let content =
            Container.container [] [
                modal
                centralCard "Contact" [
                    Text.div [
                        Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered)]
                        CustomClass "intro"
                    ] [
                        str """
                            Wil je meer weten over de impact van jouw organisatie op het milieu,
                            een analyse laten doen op jouw bedrijfsafvalstromen
                            of begeleiding bij het verkleinen van jouw impact?
                            Neem dan contact met ons op.
                            Zo kunnen we samen doorpakken op duurzaamheid.
                            """
                    ]
                ]
                Section.section [] [
                    Heading.h2 [ ] [ str "Stuur ons een bericht!" ]
                    div [] [
                        nameField
                        emailField
                        messageField
                        Level.level [] []
                        sendEmailButton
                    ]
                ]
            ]
        [
            PreZeroSection
                {|
                    Class = PreZeroSectionClass.CompanyIntro
                    Content = content
                    SectionId = None
                |}
        ]

    { Landing = None; Sections = preZeroSections }
