module UrlUpdate

open External.GoogleAnalytics

open Elmish
open Elmish.UrlParser

open PreZero

let pageParser : Parser<Navigation.Route -> Navigation.Route,_> =
        oneOf [
            map Navigation.Home            (s Navigation.Home.ToHash)
            map Navigation.ImpactChecker   (s Navigation.ImpactChecker.ToHash)
            map Navigation.VerkleinImpact  (s Navigation.VerkleinImpact.ToHash)
            map Navigation.Faq             (s Navigation.Faq.ToHash)
            map Navigation.Contact         (s Navigation.Contact.ToHash)
        ]

open Index

let urlUpdate (nextRoute : Navigation.Route option) (currentModel : GlobalModel) : GlobalModel * Cmd<GlobalMsg> =

    printfn "Navigate to page: %A." nextRoute
    Browser.Dom.window.scrollTo(0.0,0.0)

    nextRoute |> Option.map (GaTracked.VisitPage >> GoogleAnalytics.trackAction) |> ignore

    let staticContent = currentModel.StaticContent

    match nextRoute, currentModel.Page with
    | Some Navigation.Home, Page.ImpactChecker impactCheckerModel ->
        let initialHomeModel, initialHomeCmd = Home.Model.init
        { currentModel with
            Page       = Page.Home initialHomeModel
            ClientData = impactCheckerModel.WasteAmountGridData
            Project    = impactCheckerModel.ProjectSelected
            ManualResidualWaste = impactCheckerModel.ManualResidualWaste } , initialHomeCmd |> Cmd.map HomeMsg

    | Some Navigation.ImpactChecker, Page.ImpactChecker impactCheckerModel ->
        let initialImpactCheckerModel, initialImpactCheckerCmd =
            ImpactChecker.Model.init staticContent currentModel.ClientData currentModel.UserProfile currentModel.Project currentModel.ManualResidualWaste currentModel.ManualConstructionWaste
        { currentModel with
            Page       = Page.ImpactChecker initialImpactCheckerModel
            ClientData = impactCheckerModel.WasteAmountGridData
            Project    = impactCheckerModel.ProjectSelected
            ManualResidualWaste = impactCheckerModel.ManualResidualWaste }, initialImpactCheckerCmd |> Cmd.map ImpactCheckerMsg

    | Some Navigation.ImpactChecker, Page.VerkleinImpact verkleinImpactModel ->
        let initialImpactCheckerModel, initialImpactCheckerCmd =
            ImpactChecker.Model.init staticContent verkleinImpactModel.ClientData verkleinImpactModel.UserProfile verkleinImpactModel.ProjectSelected verkleinImpactModel.ManualResidualWaste verkleinImpactModel.ManualConstructionWaste
        { currentModel with
            Page       = Page.ImpactChecker initialImpactCheckerModel
            ClientData = verkleinImpactModel.ClientData
            Project    = verkleinImpactModel.ProjectSelected
            ManualResidualWaste = verkleinImpactModel.ManualResidualWaste
        },
        initialImpactCheckerCmd |> Cmd.map ImpactCheckerMsg

    | Some Navigation.VerkleinImpact, Page.ImpactChecker impactCheckerModel ->
        let initialVerkleinImpactModel, initialVerkleinImpactCmd =
            VerkleinImpact.Model.init staticContent currentModel.ClientData currentModel.ScenarioData currentModel.UserProfile impactCheckerModel.ProjectSelected impactCheckerModel.ManualResidualWaste

        { currentModel with
            Page       = Page.VerkleinImpact initialVerkleinImpactModel
            ClientData = impactCheckerModel.WasteAmountGridData
            SectorId   = impactCheckerModel.SectorId
            Project    = impactCheckerModel.ProjectSelected
            ManualResidualWaste = impactCheckerModel.ManualResidualWaste },
        initialVerkleinImpactCmd |> Cmd.map VerkleinImpactMsg

    | Some Navigation.Faq, Page.ImpactChecker impactCheckerModel ->
        let initialFaqModel, initialFaqCmd = Faq.Model.init
        { currentModel with
            Page = Page.Faq initialFaqModel
            ClientData = impactCheckerModel.WasteAmountGridData
            Project = impactCheckerModel.ProjectSelected
            ManualResidualWaste = impactCheckerModel.ManualResidualWaste } , initialFaqCmd |> Cmd.map FaqMsg


    | Some Navigation.Contact, Page.ImpactChecker impactCheckerModel ->
        let initialContactModel, initialContactCmd = Contact.Model.init
        { currentModel with
            Page       = Page.Contact initialContactModel
            ClientData = impactCheckerModel.WasteAmountGridData
            Project    = impactCheckerModel.ProjectSelected }, initialContactCmd |> Cmd.map ContactMsg

    | Some Navigation.Home, _ ->
        let initialHomeModel, initialHomeCmd = Home.Model.init
        { currentModel with Page = Page.Home initialHomeModel }, initialHomeCmd |> Cmd.map HomeMsg

    | Some Navigation.ImpactChecker, _ ->
        let initialImpactCheckerModel, initialImpactCheckerCmd =
            ImpactChecker.Model.init staticContent currentModel.ClientData currentModel.UserProfile currentModel.Project currentModel.ManualResidualWaste currentModel.ManualConstructionWaste
        { currentModel with
            Page = Page.ImpactChecker initialImpactCheckerModel}, initialImpactCheckerCmd |> Cmd.map ImpactCheckerMsg

    | Some Navigation.VerkleinImpact, _ ->
        let initialVerkleinImpactModel, initialVerkleinImpactCmd =
            VerkleinImpact.Model.init staticContent currentModel.ClientData currentModel.ScenarioData currentModel.UserProfile currentModel.Project currentModel.ManualResidualWaste
        { currentModel with
            Page = Page.VerkleinImpact initialVerkleinImpactModel}, initialVerkleinImpactCmd |> Cmd.map VerkleinImpactMsg

    | Some Navigation.Faq, _ ->
        let initialFaqModel, initialFaqCmd = Faq.Model.init
        { currentModel with Page = Page.Faq initialFaqModel }, initialFaqCmd |> Cmd.map FaqMsg


    | Some Navigation.Contact, _ ->
        let initialContactModel, initialContactCmd = Contact.Model.init
        { currentModel with
            Page = Page.Contact initialContactModel }, initialContactCmd |> Cmd.map ContactMsg
    | _, _ ->  currentModel, Cmd.none