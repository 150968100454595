namespace PreZero.Contact

open Elmish

open Shared
open PreZero

type Modal =
    | EmailSentSucces
    | EmailSentError of string
    | EmptyFormError

type Model = {
    ModelStatus : ModelStatus
    Email       : Email
    Modal       : Modal option
}

type Msg =
| SetName of string
| SetEmailAddress of string
| SetMessage of string

| SendEmail
| EmailSent of Result<unit,exn>

| SetModal of Modal option

| Nothing

module Model =

    let initialModel =
        let initialEmail = {
            Name = ""
            EmailAddress = ""
            Message = ""
        }
        { ModelStatus = ModelStatus.Idle ; Email = initialEmail ; Modal = None }

    let init : Model * Cmd<Msg> =
        initialModel, Cmd.none

    let allFieldsFilled (email : Email) : bool =
        (email.Name <> "" && email.EmailAddress <> "" && email.Message <> "")

    let update (msg : Msg) (currentModel : Model) =
        match msg with
        | SetName name          -> { currentModel with Email = { currentModel.Email with Name           = name } } , Cmd.none
        | SetEmailAddress adress -> { currentModel with Email = { currentModel.Email with EmailAddress    = adress } } , Cmd.none
        | SetMessage message    -> { currentModel with Email = { currentModel.Email with Message        = message } } , Cmd.none

        | SendEmail             ->
                if allFieldsFilled currentModel.Email then
                    currentModel, Cmd.OfAsync.either APIs.iSendEmailApi.sendEmail currentModel.Email (Ok >> EmailSent) (Error >> EmailSent)
                else {currentModel with Modal = Some EmptyFormError}, Cmd.none
        | EmailSent (Ok _)      -> { initialModel with Modal = Some EmailSentSucces }, Cmd.none
        | EmailSent (Error exn) -> { currentModel with Modal = Some (EmailSentError exn.Message) }, Cmd.none

        | SetModal modal        -> { currentModel with Modal = modal }, Cmd.none

        | _ -> currentModel, Cmd.none
