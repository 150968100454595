module Faq

open Elmish
open Fable.React

open PreZero

type QuestionId = QuestionId of int

type QuestionAnswer =
    { Id        : QuestionId
      Question  : string
      Answer    : ReactElement list
      Visible   : bool
    }

type Model = {
    ModelStatus : ModelStatus
    Faq         : QuestionAnswer list
}

type Msg =
| ToggleActive of QuestionId

module Model =

    let initialModel =
        { ModelStatus = ModelStatus.Idle
          Faq =
            Faq.Texts.questionAnswerPairs
            |> List.mapi (fun i qa ->
                { Id       = QuestionId i
                  Question = qa.Question
                  Answer   = qa.Answer
                  Visible  = false } ) }

    let init : Model * Cmd<Msg> =
        initialModel, Cmd.none

    let update (msg : Msg) (model : Model) =
        match msg with
        | ToggleActive i ->
            let newFaq =
                model.Faq
                |> List.map (fun qa ->
                    if i = qa.Id then
                        { qa with Visible = not qa.Visible }
                    else qa
                )
            { model with Faq = newFaq} , Cmd.none
