module External.React.Datasheet

open Shared
open PreZero

open Fable.Core
open Fable.Core.JsInterop
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma


// Attribute names and casing are fixed to run smoothly with JS Interop and React!
type CellsChangedArgs = {
    cell        : obj
    col         : int
    row         : int
    value       : string
}


// Attribute names and casing are fixed to run smoothly with JS Interop and React!
type CellView = {
    cell        : obj
    col         : int
    row         : int
    value       : string
}


let cellChangedArgs (cell : CellView) : CellsChangedArgs = {
    cell      = cell.cell
    col       = cell.col
    row       = cell.row
    value     = ""
}

type DataType =
| String
| Float
| Bool


type Cell = {
    value       : CellsChangedArgs
    dataType    : DataType
    width       : float
    readOnly    : bool
}


type Props =
    | Data of Cell [] []
    | ValueRenderer of (Cell -> ReactElement)
    | OnCellsChanged of (CellsChangedArgs [] -> unit)
    | ValueViewer of (CellView -> ReactElement)


// module ViewElements =

//     let unitDropdown currentUnit onChange (cellChanged : CellsChangedArgs) =
//         let cellChanged (value : string) = [| { cellChanged with value = value } |]

//         Select.select [ Select.CustomClass "react-datasheet" ] [
//             select [ OnChange (fun ev ->
//                 let selectedUnit = !!ev.target?value
//                 onChange (cellChanged selectedUnit)
//                 ) ] [
//                 for u in [ "kg"; "ton"; "kiloton" ] do
//                     match u with
//                     | u' when u' = currentUnit ->
//                         option [ Selected true ] [ str u ]
//                     | _ ->
//                         option [ ] [ str u ]
//             ]
//         ]

type NonPublicWasteStreamSetting =
| ViewPublicOnly
| DisableNonPublic
| ViewAll




let filterGridRowData (viewSetting : NonPublicWasteStreamSetting) (wasteData : GridRowData list) =
    match viewSetting with
    | DisableNonPublic | ViewAll ->
        wasteData
    | ViewPublicOnly ->
        wasteData |> List.filter (fun data -> data.Public)


let redistributeResidualDataToPublic (wasteData : GridRowData list) =
    let totalResidualWasteEntry =
        wasteData
        |> List.filter (fun data -> data.WasteStreamId = CalcSetting.residualWasteStreamId)
        |> List.sumBy (fun data -> data.Amount |> Option.defaultValue 0.)

    let sumPublicOnly =
        filterGridRowData ViewPublicOnly wasteData
        |> List.sumBy (fun data -> data.AmountResidual |> Option.defaultValue 0.)

    let rescalePublicOnly (value : float option) =
        let factor =
            if sumPublicOnly = 0. then 0.
            else totalResidualWasteEntry / sumPublicOnly
        match value with
        | Some value' ->
            let unrounded = value' * factor
            if totalResidualWasteEntry < 10. then
                System.Math.Round (unrounded, 1) |> Some
            else
                unrounded |> System.Math.Round |> Some

        | None -> None

    wasteData |> List.map (fun data ->
        { data with
            AmountResidual =
                if data.Public then rescalePublicOnly data.AmountResidual else Some 0.
        }
    )


let roundResidualData (wasteData : GridRowData list) =
    let totalResidualWasteEntry =
        wasteData
        |> List.filter (fun data -> data.WasteStreamId = CalcSetting.residualWasteStreamId)
        |> List.sumBy (fun data -> data.Amount |> Option.defaultValue 0.)

    let round (unrounded : float option) =
        match unrounded with
        | Some unrounded' ->
            if totalResidualWasteEntry < 10. then
                System.Math.Round (unrounded', 1) |> Some
            else
                unrounded' |> System.Math.Round |> Some
        | None -> None

    wasteData |> List.map (fun data ->
        { data with AmountResidual = round data.AmountResidual } )



let fillGridDataWithSector
    (sectorId : SectorId)
    (staticContent : StaticContent)
    (clientData : GridRowData list) : GridRowData list =

    let sectorData =
        staticContent.Sectors
        |> Map.tryFind sectorId
        |> Option.map (fun sectordata -> sectordata.WastePerStream)
        |> Option.defaultValue Map.empty

    let residualAmount, residualUnit =
        clientData
        |> List.filter (fun data -> data.WasteStreamId = CalcSetting.residualWasteStreamId)
        |> List.map (fun data -> data.Amount, data.Unit)
        |> List.tryHead
        |> function
           | Some (Some amount, unit) -> Some amount, unit
           | Some (None, unit) -> None, unit
           | None -> None, Unit.Kg

    let sectorTotal =
        sectorData
        |> Map.toList
        |> List.sumBy (snd >> (fun waste -> waste.Residual))

    let sectorPercentages : Map<WasteStreamId,float> =
        sectorData
        |> Map.map (fun key waste ->
            (waste.Residual / sectorTotal)
            |> Rounding.roundFiveSignificantDigits)

    clientData
    |> List.map (fun data ->
        let wastePercentage = sectorPercentages.TryFind data.WasteStreamId |> Option.defaultValue 0.
        let residualEstimate = (residualAmount |> Option.defaultValue 0.) * wastePercentage
        let roundedResidualEstimate =
            match residualAmount with
            | Some residualAmount' ->
                if residualAmount' < 10. then
                    System.Math.Round (residualEstimate, 1) |> Some
                else
                    residualEstimate |> System.Math.Round |> Some
            | None -> None

        { data with
            AmountResidual  = roundedResidualEstimate
            UnitResidual    = residualUnit } )


let distributeConstructionGridData (clientData : GridRowData list) : GridRowData list =

    let totalConstructionAmount, totalConstructionUnit =
        clientData
        |> List.tryFind (fun ws ->
            ws.WasteStreamId = CalcSetting.constructionWasteStreamId
        )
        |> Option.map (fun ws -> ws.Amount, ws.Unit)
        |> Option.defaultValue (None , Unit.Kg)

    clientData
    |> List.map (fun ws ->
        if ws.WasteStreamId = CalcSetting.otherConstructionWasteStreamId then
            { ws with
                AmountConstruction = totalConstructionAmount
                UnitConstruction   = totalConstructionUnit }
        else
            ws
    )

let distributeConstructionIntervention (interventions : Intervention list) : Intervention list =

    let totalConstructionAmount, totalConstructionUnit =
        interventions
        |> List.tryFind (fun ws ->
            ws.WasteStreamId = CalcSetting.constructionWasteStreamId
        )
        |> Option.map (fun ws -> ws.Amount, ws.Unit)
        |> Option.defaultValue ( { Current = None; Scenario = None } , Unit.Kg)

    interventions
    |> List.map (fun ws ->
        if ws.WasteStreamId = CalcSetting.otherConstructionWasteStreamId then
            { ws with
                AmountConstruction = totalConstructionAmount
                UnitConstruction   = totalConstructionUnit }
        else
            ws
    )


let filterInterventions (viewSetting : NonPublicWasteStreamSetting) (interventions : Intervention list) =
    match viewSetting with
    | DisableNonPublic | ViewAll ->
        interventions
    | ViewPublicOnly ->
        interventions |> List.filter (fun data -> data.Public)



let createGridRowHeader (headerContent: (float * string) array) =
    headerContent
    |> Array.mapi (fun colNumber (width, text) ->
            {value =
                { cell = text
                  row = 0
                  col = colNumber
                  value = text}
             dataType = String
             width = width
             readOnly = true } )


module ImpactCheckerGrid =

    [<RequireQualifiedAccess>]
    type Col =
    | Name
    | Unit
    | Amount
    with
        member this.ColIndex =
            match this with
            | Name      -> 0
            | Unit      -> 1
            | Amount    -> 2


    // let wasteAmountInputToCells
    //     (viewSetting : NonPublicWasteStreamSetting)
    //     (wasteInputAmount : GridRowData list) : Cell [] [] =

    //     let header =
    //         [| 175., "Afval per stroom"
    //            100., "Eenheid"
    //            100., "Hoeveelheid" |]
    //         |> createGridRowHeader

    //     let dataRows =
    //         filterGridRowData viewSetting wasteInputAmount
    //         |> List.toArray
    //         |> Array.map (fun data ->
    //             let rowId = data.RowId

    //             [|  // Readonly column with name of waste stream
    //                 { value =
    //                     { cell     = data.Name :> obj
    //                       row      = rowId
    //                       col      = Col.Name.ColIndex
    //                       value    = data.Name }
    //                   dataType   = String
    //                   width      = header.[Col.Name.ColIndex].width
    //                   readOnly   = true }

    //                 // Readonly column with unit
    //                 { value =
    //                     { cell     = data.Unit.ToString :> obj
    //                       row      = rowId
    //                       col      = Col.Unit.ColIndex
    //                       value    = data.Unit.ToString }
    //                   dataType   = String
    //                   width      = header.[Col.Unit.ColIndex].width
    //                   readOnly   = true }

    //                  // Editable column with amount of waste
    //                 { value =
    //                     { cell     = data.Amount |> Option.map string |> Option.defaultValue "" :> obj
    //                       row      = rowId
    //                       col      = Col.Amount.ColIndex
    //                       value    = data.Amount |> Option.map string |> Option.defaultValue "" }
    //                   dataType   = Float
    //                   width      = header.[Col.Amount.ColIndex].width
    //                   readOnly   =
    //                     match viewSetting with
    //                     | ViewPublicOnly | ViewAll -> false
    //                     | DisableNonPublic ->  not data.Public }
    //             |] )

    //     Array.append [| header |] dataRows


    let wasteAmountInputToCellsResidual (viewSetting: NonPublicWasteStreamSetting) (wasteInputAmount : GridRowData list) : Cell [] [] =

        let header =
            [| 200., "Bedrijfsafval (restafval)"
               100., "Eenheid"
               150., "Hoeveelheid" |]
            |> createGridRowHeader

        let dataRows =
            filterGridRowData viewSetting wasteInputAmount
            // filter out construction related streams
            |> List.filter (fun x ->
                not x.IsConstructionOnly
                && x.WasteStreamId <> CalcSetting.constructionWasteStreamId )
            |> List.toArray
            |> Array.map (fun data ->
                let rowId = data.RowId

                [|  // Readonly column with name of waste stream
                    { value =
                        { cell  = (if data.WasteStreamId = CalcSetting.residualWasteStreamId then "Overig" else data.Name)
                          row   = rowId
                          col   = Col.Name.ColIndex
                          value = if data.WasteStreamId = CalcSetting.residualWasteStreamId then "Overig" else data. Name }
                      dataType = String
                      width = header.[Col.Name.ColIndex].width
                      readOnly = true }

                    // Readonly column with unit
                    { value =
                        { cell  = data.UnitResidual.ToString :> obj
                          row   = rowId
                          col   = Col.Unit.ColIndex
                          value = data.UnitResidual.ToString }
                      dataType = String
                      width = header.[Col.Unit.ColIndex].width
                      readOnly = true }

                     // Editable column with amount of waste
                    { value =
                        { cell  = data.AmountResidual |> Option.map string |> Option.defaultValue "" :> obj
                          row   = rowId
                          col   = Col.Amount.ColIndex
                          value = data.AmountResidual |> Option.map string |> Option.defaultValue "" }
                      dataType = Float
                      width = header.[Col.Amount.ColIndex].width
                      readOnly =
                        match viewSetting with
                        | ViewPublicOnly | ViewAll -> false
                        | DisableNonPublic ->  not data.Public }
                |] )

        Array.append [| header |] dataRows

    let wasteAmountInputToCellsConstruction (wasteInputAmount : GridRowData list) : Cell [] [] =

        let header =
            [| 200., "Afvalstroom"
               100., "Eenheid"
               150., "Hoeveelheid" |]
            |> createGridRowHeader

        let dataRows =
            wasteInputAmount
            |> List.filter (fun x -> x.IsConstruction)
            |> List.toArray
            |> Array.map (fun data ->
                let rowId = data.RowId

                [|  // Readonly column with name of waste stream
                    { value =
                        { cell  = data.Name
                          row   = rowId
                          col   = Col.Name.ColIndex
                          value = data.Name }
                      dataType = String
                      width = header.[Col.Name.ColIndex].width
                      readOnly = true }

                    // Readonly column with unit
                    { value =
                        { cell  = data.UnitConstruction.ToString :> obj
                          row   = rowId
                          col   = Col.Unit.ColIndex
                          value = data.UnitConstruction.ToString }
                      dataType = String
                      width = header.[Col.Unit.ColIndex].width
                      readOnly = true }

                     // Editable column with amount of waste
                    { value =
                        { cell  = data.AmountConstruction
                                  |> Option.map string
                                  |> Option.defaultValue "" :> obj
                          row   = rowId
                          col   = Col.Amount.ColIndex
                          value = data.AmountConstruction
                                  |> Option.map string
                                  |> Option.defaultValue "" }
                      dataType = Float
                      width = header.[Col.Amount.ColIndex].width
                      readOnly = false }
                |] )

        Array.append [| header |] dataRows


    let updateWasteAmountGridData (gridData : GridRowData list) (cellChanged : CellsChangedArgs) =

        // printfn "Received cellsChangedArgs: cell %A, row %A, col %A, value %A" cellChanged.cell cellChanged.row cellChanged.col cellChanged.value

        let cellChangedWasteStreamId =
            gridData
            |> List.tryFind (fun data -> data.RowId = cellChanged.cell?value?row)
            |> Option.get
            |> fun x -> x.WasteStreamId

        gridData
        |> List.map (fun gridRowData ->

            if gridRowData.WasteStreamId = cellChangedWasteStreamId then

                if cellChanged.col = Col.Unit.ColIndex then
                    { gridRowData with
                        Unit =
                            cellChanged.value
                            |> string
                            |> Shared.Units.unitFromString
                            |> Option.defaultValue Unit.Kg }

                elif cellChanged.col = Col.Amount.ColIndex then
                    {gridRowData with
                        Amount =
                            if cellChanged.value = "" then
                                None
                            else cellChanged.value |> string |> float |> Some }

                else
                    gridRowData
            else
                gridRowData )

    let updateConstructionGridData (gridData : GridRowData list) (cellChanged : CellsChangedArgs) =

        // printfn "Received cellsChangedArgs: cell %A, row %A, col %A, value %A" cellChanged.cell cellChanged.row cellChanged.col cellChanged.value

        let cellChangedWasteStreamId =
            gridData
            |> List.tryFind (fun data -> data.RowId = cellChanged.cell?value?row)
            |> Option.get
            |> fun x -> x.WasteStreamId

        gridData
        |> List.map (fun gridRowData ->

            if gridRowData.WasteStreamId = cellChangedWasteStreamId then

                if cellChanged.col = Col.Unit.ColIndex then
                    { gridRowData with
                        UnitConstruction =
                            cellChanged.value
                            |> string
                            |> Shared.Units.unitFromString
                            |> Option.defaultValue Unit.Kg }

                elif cellChanged.col = Col.Amount.ColIndex then
                    {gridRowData with
                        AmountConstruction =
                            if cellChanged.value = "" then
                                None
                            else cellChanged.value |> string |> float |> Some }

                else
                    gridRowData
            else
                gridRowData )


    let updateWasteAmountGridDataResidual (gridData : GridRowData list) (cellChanged : CellsChangedArgs) =

        // printfn "Received residual cellsChangedArgs: cell %A, row %A, col %A, value %A" cellChanged.cell cellChanged.row cellChanged.col cellChanged.value

        let cellChangedWasteStreamId =
            gridData
            |> List.tryFind (fun data -> data.RowId = cellChanged.cell?value?row)
            |> Option.get
            |> fun x -> x.WasteStreamId

        gridData
        |> List.map (fun gridRowData ->

            if gridRowData.WasteStreamId = cellChangedWasteStreamId then

                if cellChanged.col = Col.Unit.ColIndex then
                    { gridRowData with
                        UnitResidual =
                            cellChanged.value
                            |> string
                            |> Shared.Units.unitFromString
                            |> Option.defaultValue Unit.Kg}

                elif cellChanged.col = Col.Amount.ColIndex then
                    {gridRowData with
                        AmountResidual =
                            if cellChanged.value = "" then
                                None
                            else cellChanged.value |> string |> float |> Some }

                else
                    gridRowData
            else
                gridRowData )

    let valueRenderer (cell : Cell) : ReactElement = str (string cell.value.cell)

    let valueViewer onchange : Props =

        let leftAlignedProps =
            Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ]
        let boldProps =
            Modifiers [ Modifier.TextWeight TextWeight.Bold ]

        ValueViewer (fun cell ->

            // make headers bold
            if cell.row = 0 then
                Text.p [ leftAlignedProps; boldProps ] [ str cell.value ]

            // give dangerous waste an info bullet
            elif cell.value = CalcSetting.dangerousWasteStreamName then
                Text.p [ leftAlignedProps ] [
                    str (string cell.value + "  ")
                    Components.Helpers.infoTooltip "Uitgaande van verbranding met/zonder energie-\nterugwinning bij een algemene, gemiddelde\nsamenstelling van het gevaarlijk afval. Specifieke\nstromen geven een andere uitkomst.\nWil je een detailanalyse op je gevaarlijk afval,\nneem dan contact met ons op."
                ]
            elif cell.col = Col.Name.ColIndex then
                Text.p [ leftAlignedProps ] [ str cell.value ]

            elif cell.col = Col.Unit.ColIndex then
                Text.p [ leftAlignedProps ] [ str cell.value ]

            // elif cell.col = Col.Unit.ColIndex then
            //     cellChangedArgs cell |> ViewElements.unitDropdown (string cell.value) onchange

            // right-align the numerical values
            elif cell.col = Col.Amount.ColIndex then
                Text.p [ Props [ Class "numerical-value" ] ]
                    [ str (string cell.value) ]

            else
                str (string cell.value)
            )


    let inline grid (props : Props list) (elems : ReactElement list) : ReactElement =
        ofImport "default" "react-datasheet" (keyValueList CaseRules.LowerFirst props) elems

    let updateGridWithProject (gridRowData : GridRowData list) (project : Project) : GridRowData list =

        gridRowData
        |> List.map (fun data ->
            let loadedValues = project.WasteData |> Map.tryFind data.WasteStreamId
            match loadedValues with
            | Some waste ->
                { data with
                    Amount             = Some waste.Separated.Value
                    Unit               = waste.Separated.Unit
                    AmountResidual     = Some waste.Residual.Value
                    UnitResidual       = waste.Residual.Unit
                    AmountConstruction = Some waste.Construction.Value
                    UnitConstruction   = waste.Construction.Unit }
            | None -> data
        )

module VerkleinImpactGrid =

    [<RequireQualifiedAccess>]
    type Col =
    | Name
    | Unit
    | AmountCurrent
    | AmountScenario
    with
        static member tryGetColByIndex (colId: int) =
            match colId with
            | 0 -> Some Col.Name
            | 1 -> Some Col.Unit
            | 2 -> Some Col.AmountCurrent
            | 3 -> Some Col.AmountScenario
            | _ -> None
        member this.ColIndex =
            match this with
            | Name           -> 0
            | Unit           -> 1
            | AmountCurrent  -> 2
            | AmountScenario -> 3


    let wasteAmountInputToCellsResidual
        (viewSetting : NonPublicWasteStreamSetting)
        (interventions : Intervention list) : Cell [] [] =

        let header =
            [| 200., "Bedrijfsafval (restafval)"
               100., "Eenheid"
               125., "Hoeveelheid huidig"
               125., "Hoeveelheid aangepast"  |]
            |> createGridRowHeader

        let dataRows =
            filterInterventions viewSetting interventions
            |> List.filter (fun intervention ->
                not intervention.IsConstructionOnly &&
                intervention.WasteStreamId <> CalcSetting.constructionWasteStreamId
            )
            |> List.toArray
            |> Array.map (fun intervention ->
                [|  // Readonly column with name of waste stream
                    { value =
                        { cell  =
                            if intervention.WasteStreamId = CalcSetting.residualWasteStreamId then "Overig" else intervention.Name
                            :> obj
                          row   = intervention.WasteStreamId.Value
                          col   = Col.Name.ColIndex
                          value =
                            if intervention.WasteStreamId = CalcSetting.residualWasteStreamId then "Overig" else intervention.Name }
                      dataType   = String
                      width      = header.[Col.Name.ColIndex].width
                      readOnly   = true }

                    // Read-only column with unit of client data
                    { value =
                        { cell   = intervention.UnitResidual.ToString :> obj
                          row    = intervention.WasteStreamId.Value
                          col    = Col.Unit.ColIndex
                          value  = intervention.UnitResidual.ToString }
                      dataType   = String
                      width      = header.[Col.Unit.ColIndex].width
                      readOnly   = true }

                     // Editable column with amount of waste
                    { value =
                        { cell    =
                            intervention.AmountResidual.Current
                            |> Option.map string
                            |> Option.defaultValue ""
                            :> obj
                          row     = intervention.WasteStreamId.Value
                          col     = Col.AmountCurrent.ColIndex
                          value   =
                            intervention.AmountResidual.Current
                            |> Option.map string
                            |> Option.defaultValue "" }
                      dataType   = Float
                      width      = header.[Col.AmountCurrent.ColIndex].width
                      readOnly   =
                        match viewSetting with
                        | ViewPublicOnly | ViewAll -> false
                        | DisableNonPublic ->  not intervention.Public }

                     // Editable column with amount of waste
                    { value =
                        { cell   =
                            intervention.AmountResidual.Scenario
                            |> Option.map string
                            |> Option.defaultValue ""
                            :> obj
                          row    = intervention.WasteStreamId.Value
                          col    = Col.AmountScenario.ColIndex
                          value  =
                            intervention.AmountResidual.Scenario
                            |> Option.map string
                            |> Option.defaultValue "" }
                      dataType   = Float
                      width      = header.[Col.AmountScenario.ColIndex].width
                      readOnly   =
                        match viewSetting with
                        | ViewPublicOnly | ViewAll -> false
                        | DisableNonPublic ->  not intervention.Public }
                |] )

        let totalRow : Cell [] =
            let interventions =
                filterInterventions viewSetting interventions

            let totalCurrent =
                interventions
                |> List.sumBy (fun intervention ->
                    intervention.AmountResidual.Current |> Option.defaultValue 0.
                )

            let totalScenario =
                interventions
                |> List.sumBy (fun intervention ->
                    intervention.AmountResidual.Scenario |> Option.defaultValue 0.
                )

            [|
                {value =
                    { cell = "Totaal"
                      row = 999
                      col = Col.Name.ColIndex
                      value = "Totaal"}
                 dataType = String
                 width    = header.[Col.Name.ColIndex].width
                 readOnly = true }

                { value =
                    { cell   = Unit.Kg.Abbr :> obj
                      row    = 999
                      col    = Col.Unit.ColIndex
                      value  = Unit.Kg.Abbr }
                  dataType   = String
                  width      = header.[Col.Unit.ColIndex].width
                  readOnly   = true }

                 // Read-only column with total amount of waste, current
                { value =
                    { cell    = totalCurrent |> string :> obj
                      row     = 999
                      col     = Col.AmountCurrent.ColIndex
                      value   = totalCurrent |> string }
                  dataType   = Float
                  width      = header.[Col.AmountCurrent.ColIndex].width
                  readOnly   = true }

                 // Read-only column with total amount of waste, scenario
                { value =
                    { cell   = totalScenario |> string :> obj
                      row    = 999
                      col    = Col.AmountScenario.ColIndex
                      value  = totalScenario |> string }
                  dataType   = Float
                  width      = header.[Col.AmountScenario.ColIndex].width
                  readOnly   = true }
            |]


        Array.concat
            [|
                [| header |]
                dataRows
                [| totalRow |]
            |]


    /// Update the current intervention data with cells changed from react datasheet.
    let updateWasteAmountGridDataResidual (oldData : Intervention list) (cellChanged : CellsChangedArgs) =


        // printfn "Received cellsChangedArgs: cell %A, row %A, col %A, value %A" cellChanged.cell cellChanged.row cellChanged.col cellChanged.value

        // update first the modified wastestream (e.g. Paper)
        // in either its current or scenario residual waste amount
        let newInterventionsIncompleteResidual =
            oldData
            |> List.map (fun intervention ->
                match intervention with
                | ii as interventionWSChanged when ii.WasteStreamId.Value = cellChanged.cell?value?row ->
                    let newValue =
                        if cellChanged.value = "" then None
                        else cellChanged.value |> string |> float |> Some

                    match cellChanged.col |> Col.tryGetColByIndex with
                    | Some Col.Unit ->
                        { interventionWSChanged with
                            UnitResidual =
                                cellChanged.value
                                |> string
                                |> Units.unitFromString
                                |> Option.defaultValue Unit.Kg }
                    | Some Col.AmountCurrent ->
                        { interventionWSChanged with
                            AmountResidual = { interventionWSChanged.AmountResidual with
                                                    Current = newValue; Scenario = newValue } }
                    | Some Col.AmountScenario ->
                        match newValue, interventionWSChanged.IsSeparated.Scenario with
                        | Some newValue', true ->
                            // E.g., if user enters a new value in Paper / Scenario
                            // and the toggle for Paper was turned on (i.e. separated),
                            let previousScenarioSeparated =
                                interventionWSChanged.Amount.Scenario |> Option.defaultValue 0.
                            let previousScenarioResidual =
                                interventionWSChanged.AmountResidual.Scenario |> Option.defaultValue 0.

                            let differenceScenarioResidual = newValue' - previousScenarioResidual

                            { interventionWSChanged with
                                Amount =
                                    { interventionWSChanged.Amount with
                                        Scenario = Some (previousScenarioSeparated + differenceScenarioResidual) }
                                AmountResidual =
                                    { interventionWSChanged.AmountResidual with Scenario = newValue }  }

                        | noNewValueOrWasteStreamNotSeparated ->
                            { interventionWSChanged with
                                AmountResidual = { interventionWSChanged.AmountResidual with Scenario = newValue } }

                    | otherCol ->
                        intervention

                | otherIntervention ->
                    otherIntervention )

        // update the amount of wastestream "residual wastes" too
        newInterventionsIncompleteResidual
        |> List.map (fun intervention ->
            match intervention with
            | ii as interventionResidual when ii.WasteStreamId = CalcSetting.residualWasteStreamId ->
                let newResidualCurrent =
                    newInterventionsIncompleteResidual
                    |> List.sumBy (fun intervention ->
                        intervention.AmountResidual.Current |> Option.defaultValue 0. )
                let newResidualScenario =
                    newInterventionsIncompleteResidual
                    |> List.sumBy (fun intervention ->
                        intervention.AmountResidual.Scenario |> Option.defaultValue 0. )
                { interventionResidual with
                    Amount = { Current = Some newResidualCurrent; Scenario = Some newResidualScenario } }
            | otherIntervention ->
                otherIntervention
        )

    let onCellsChanged (changes: CellsChangedArgs []) =
        OnCellsChanged (fun changes -> ())

    let valueRenderer (cell : Cell) : ReactElement = str (string cell.value.cell)

    let valueViewerResidual onchange : Props =

        ValueViewer (fun cell ->

            // make headers bold
            if cell.row = 0 || cell.row = 999 then
                Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [ str cell.value ]

            // right-align the numerical values
            elif cell.col = Col.AmountScenario.ColIndex || cell.col = Col.AmountCurrent.ColIndex then
                Text.p [ Props [ Class "numerical-value" ] ]
                    [ str (string cell.value) ]

            // give dangerous waste an info bullet
            elif cell.value = CalcSetting.dangerousWasteStreamName then
                p [] [
                    str (string cell.value + "  ")
                    Components.Helpers.infoTooltip "Uitgaande van verbranding met/zonder energie-\nterugwinning bij een algemene, gemiddelde\nsamenstelling van het gevaarlijk afval. Specifieke\nstromen geven een andere uitkomst.\nWil je een detailanalyse op je gevaarlijk afval,\nneem dan contact met ons op."]

            else
                str (string cell.value)
            )

    let inline grid (props : Props list) (elems : ReactElement list) : ReactElement =
        ofImport "default" "react-datasheet" (keyValueList CaseRules.LowerFirst props) elems
