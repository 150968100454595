module ImpactChecker.View

open Elmish
open Fulma
open Fulma.Extensions.Wikiki
open Fable.FontAwesome
open Fable.React
open Fable.React.Props

open External
open React.Datasheet
open React.Datasheet.ImpactCheckerGrid
open Shared

open Components.Button
open Components.Dropdown
open Components.Helpers
open Components.Layouts
open Components.Table
open PreZero
open ImpactChecker


let onCellsChangedResidual (model : Model) (dispatch : Msg -> unit) : React.Datasheet.Props =
    OnCellsChanged (UpdateGridResidual >> dispatch)

let onCellsChangedConstruction (model : Model) (dispatch : Msg -> unit) : React.Datasheet.Props =
    OnCellsChanged (UpdateGridConstruction >> dispatch)

let gridManualResidualWastes (viewSetting : NonPublicWasteStreamSetting) (model: Model) (dispatch : Msg -> unit) =
    ImpactCheckerGrid.grid [
        Data (wasteAmountInputToCellsResidual viewSetting model.WasteAmountGridData)
        ValueRenderer valueRenderer
        valueViewer (UpdateGridResidual >> dispatch)
        onCellsChangedResidual model dispatch
    ] []

let gridConstruction (model: Model) (dispatch : Msg -> unit) =
    grid [ Data (wasteAmountInputToCellsConstruction model.WasteAmountGridData)
           ValueRenderer valueRenderer
           valueViewer (UpdateGridConstruction >> dispatch)
           onCellsChangedConstruction model dispatch ] []

let modal (model : Model) (dispatch : Msg -> unit) (staticContent : StaticContent) =
    let title, body =
        match model.Modal with
        | Some (Modal.ImpactInformation impactName) ->
            sprintf "Drivers van %A" impactName,
                ( staticContent.Drivers
                    |> List.filter (fun driver -> Calculation.showImpactName staticContent driver.ImpactId = impactName)
                    |> List.collect (fun driver -> [
                            Text.p [] [ strong [] [ str driver.Name ] ]
                            Text.p [] [ str driver.Description ]
                            Level.level [] []
                        ] ) )
        | Some (Modal.WasteStreamInformation wasteStreamName) ->
            // @TODO this setup is weird, we never want to match on names, we want to match on ID's
            // Not sure if this is used anywhere, so not fixing it for now
            let wasteStream =
                try
                    staticContent.WasteStreams
                    |> Map.tryPick (fun id wasteStream -> if wasteStream.Name = wasteStreamName then Some wasteStream else None)
                with
                | e -> None

            sprintf "Afvalstroom informatie van %A" wasteStreamName,
            match wasteStream with
               | Some wasteStream -> [
                    Text.p [] [ str wasteStream.Description ]
                    Level.level [] []
                       ]
               | None -> [ nothing ]

        | Some Modal.ResidualWasteInput ->

            "Vul de samenstelling van je bedrijfsafval in",
                match model.UserProfile with
                | Some user ->
                    [
                        gridManualResidualWastes ViewAll model dispatch
                    ]
                | None ->
                    [
                        gridManualResidualWastes ViewPublicOnly model dispatch
                        Level.level [] []
                        p [ ] [
                          str "Wil je meer afvalstromen onderscheiden in je bedrijfsafval? Maak dan "
                          a [ Href Shared.Route.register ] [ str "hier" ]
                          str " een account aan."
                          ]
                    ]

        | Some Modal.ConstructionWasteInput ->
            "Vul de samenstelling van je bouw- en sloopafval in",
            [ gridConstruction model dispatch ]

        | Some Modal.TooManyProjects ->
            "Fout bij opslaan",
                [ str "Er liep iets fout bij het opslaan van je project. Mogelijk heb je de limiet van het aantal projecten bereikt." ]

        | Some (Modal.ProjectSavedSuccess projectName) ->
            "Project opgeslagen!",
                [ str (sprintf "Project \"%s\" is succesvol opgeslagen." projectName) ]

        | Some (Modal.SectorInformation _) -> "name", [ nothing ]
        | None -> "name", [ nothing ]

    Components.Modal.modalTemplate
        model.Modal.IsSome
        title
        body
        dispatch
        (SetModal None)

let buttonToStepTwo hasMarginTop =
    Button.a [
        Button.Color IsPrimary
        Button.Size IsMedium
        Button.Props [
            Navigation.Route.VerkleinImpact.ToHref
            Style [ MarginBottom "1.5rem"; if hasMarginTop then MarginTop "1.5rem" ] ]
    ] [
        Text.p [ ] [ str "Impact verkleinen? Ga door naar stap 2" ]
        Icon.icon [ ] [ Fa.i [ Fa.Solid.ArrowRight ] [ ] ]
    ]

module Sections =
    let sectionLanding (model: Model) (dispatch: Msg -> unit) =
        let switchHowItWorks =
            Field.div [ Field.IsGrouped; Field.Props [
                    Style [ PaddingTop "1em"; Cursor "pointer"; PreZeroColor.RoyalBlue.CSSProp ]
                    OnClick (fun _ -> dispatch ToggleDisplayHowItWorks)
                    ] ] [
                Text.p [ ] [ str "Zo werkt het" ]
                model.IsHiddenHowItWorks
                |> function
                    | true  -> Icon.icon [ ] [ Fa.i [ Fa.Regular.PlusSquare ] [ ] ]
                    | false -> Icon.icon [ ] [ Fa.i [ Fa.Regular.MinusSquare ] [ ] ]
            ]
        let boxHowItWorks =
            Field.div [ ] (Texts.explanationHowItWorks dispatch)

        let boxCTA =
            let boxContent =
                // if some project is entered, advice user to create account resp. get in touch
                if model.UserProfile.IsSome then

                    // USER LOGGED-IN
                    {|
                        Title = "Exact weten wat het potentieel is van jouw restafval? Wij doen graag een grondstoffenanalyse."
                        Benefits = [
                            "Krijg inzicht in je afvalstromen"
                            "Vind jouw waardevolle stromen"
                            "Ontdek het recycling potentieel"
                            ]
                        Button =
                            {|
                                Text = "Neem contact met ons op"
                                Href = Navigation.Contact.ToHref
                            |}
                    |}

                else
                    // NOT LOGGED-IN
                {|
                    Title = "Inzicht in alle afvalstromen? Maak een gratis account aan voor je organisatie."
                    Benefits = [
                        "Krijg inzicht in al jouw afvalstromen"
                        "Sla je gegevens op"
                        "Verminder makkelijker je impact"
                        "Gratis advies van onze impactexperts"
                        ]
                    Button =
                        {|
                            Text = "Account aanmaken"
                            Href = Href Shared.Route.register
                        |}
                |}


            Box.box' [ Props [ ClassName "box info-card cta" ] ] [
                Heading.h5 [ ] [ str boxContent.Title ]
                Field.div [ ] [
                    ul [ ] [
                        for benefit in boxContent.Benefits do
                            li [ ] [ str benefit ]
                    ]
                ]
                Button.a [ Button.Color IsPrimary
                           Button.Props [ boxContent.Button.Href ] ]
                    [ Text.p [ ] [ str boxContent.Button.Text ]
                      Icon.icon [ ] [ Fa.i [ Fa.Solid.ArrowRight ] [ ] ] ]
            ]
        Columns.columns [ ] [
            Column.column [ Column.Width (Screen.All, Column.Is7) ] [
                Heading.h2 [ ] [ str "Wat is jouw impact?" ]
                Text.p [ ] [
                    str """
                        In de Impact Checker vul je de gewichten van je bedrijfsafvalstromen in.
                        Wat scheid je al?
                        En hoeveel kilo heb je van elke stroom?
                        Op basis van deze gegevens vertellen wij je welke impact de verwerking van je afval heeft op het milieu.
                        Weet je de gewichten van je bedrijfsafval (nog) niet?
                        Neem dan
                    """
                    a [ Navigation.Contact.ToHref ] [ str "contact" ]
                    str " met ons of een andere afvalverwerker op." ]
                switchHowItWorks
                if not model.IsHiddenHowItWorks then boxHowItWorks
            ]
            Column.column [ Column.Width (Screen.All, Column.Is5) ]
                [ boxCTA ]
        ]


    let boardProjectManagementPreData (model: Model) (dispatch : Msg -> unit) =

        let selectProject (user: UserProfile) =
            projectDropdown
                user.Projects
                (model.ProjectSelected |> Option.bind (fun proj -> proj.Id))
                (SelectProject >> dispatch)

        let createProject =
            Button.button [ Button.Color IsPrimary; Button.OnClick (fun _ -> dispatch CreateProject) ]
                [ str "Nieuw project aanmaken" ]

        match model.UserProfile with
        | None ->
            nothing
        | Some userLoggedIn ->
            Columns.columns [ ] [
                Column.column [ ] [ createProject ]
                Column.column [ ] [
                    Label.label [ ] [ str "Projectnaam" ]
                    Field.div [ Field.Props [ Style [ Width "350px" ] ] ] [
                        Input.text
                            [ Input.Placeholder "Bijv: De Groene Reus - oktober 2020"
                              Input.Value model.ProjectName
                              Input.Props [ onChange (SetName >> dispatch) ] ] ]
                ]
                Column.column [ ] [
                    selectProject userLoggedIn
                ]
            ]


    let boardProjectManagementPostData (model: Model) (dispatch : Msg -> unit) =

        let marginTop = Field.Props [ Style [ MarginTop "25px" ] ]
        let centerText = Field.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]

        let notLoggedInMsg =
            Field.div [ marginTop; centerText ] [
                str "Wil je je data opslaan? Of meer verschillende afvalstromen invullen? "
                a [ Href Shared.Route.register ] [ str "Maak dan een account aan." ]
            ]

        let saveProject model' =
            saveProjectButton
                (Model.modelToProject model')
                (SaveProject >> dispatch)

        match model.UserProfile with
        | None ->
            notLoggedInMsg
        | Some userLoggedIn ->
            Field.div [ marginTop ; centerText ] [
                saveProject model
                ]


    let private userDataEntryNonResidualWastes (model: Model) (dispatch : Msg -> unit) =

        let nonResidualData =
            model.WasteAmountGridData
            |> List.filter (fun wasteData ->
                if model.UserProfile.IsSome && model.IsExpandedFullWasteEntries then wasteData.IsSeparatable
                else wasteData.IsSeparatable && wasteData.Public
            )

        let userDataEntryField (data: GridRowData) =

            let disableConstructionInput =
                data.WasteStreamId = CalcSetting.constructionWasteStreamId && model.ManualConstructionWaste

            Column.column [ Column.Width (Screen.All, Column.IsNarrow) ] [
                Label.label [ Label.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ] ]
                    [ str data.Name
                      span [ Style [ PaddingLeft ".35em"; FontWeight "initial"; Color "#666" ] ]
                        [ infoTooltip data.Description ] ]
                Field.div [ Field.HasAddons ] [
                    Control.div [ Control.Props [ ] ] [
                        Input.text
                            [ Input.Disabled disableConstructionInput
                              Input.Placeholder "Hoeveelheid"
                              Input.Value
                                    (data.Amount
                                     |> Option.map string
                                     |> Option.defaultValue "")
                              Input.Props [
                                    onChange (fun x -> (UpdateForm >> dispatch) (data.WasteStreamId, x))
                                    Style [ Width "150px" ]
                              ]
                            ]
                    ]
                    Control.div [ Control.Props [ ] ] [
                        Button.a [ Button.IsStatic true ] [ str "kg"]
                    ]
                    if data.WasteStreamId = CalcSetting.constructionWasteStreamId && model.ManualConstructionWaste then
                        Button.button
                            [ Button.Props [Style [ Margin "auto"; MarginLeft "20px"] ]
                              Button.OnClick (fun _ -> SetModal (Some Modal.ConstructionWasteInput) |> dispatch)
                              Button.Color IsPrimary
                              Button.Size IsSmall ]
                            [ Icon.icon [
                                Icon.Size IsSmall
                                Icon.Modifiers [ Modifier.TextColor IsWhite ]
                              ] [
                                Fa.i [ Fa.Solid.Edit ] [ ]
                              ]
                            ]
                ]
            ]

        div [ ] [
            Columns.columns [
                Columns.IsMultiline
                Columns.IsCentered
                Columns.Props [ Style [ Margin "auto auto"; Width "fit-content" ] ]
            ] [
                for ws in nonResidualData do
                    userDataEntryField ws
            ]
        ]

    let private userDataEntryResidualWaste (model: Model) (dispatch : Msg -> unit) =
        let residualWasteData =
            model.WasteAmountGridData
            |> List.filter (fun value -> value.WasteStreamId = CalcSetting.residualWasteStreamId)
            |> List.tryExactlyOne

        let amount =
            residualWasteData
            |> Option.bind (fun data -> data.Amount |> Option.map string)
            |> Option.defaultValue ""

        let wasteStreamData = {|
            Name = "Restafval"
            IsManual = model.ManualResidualWaste
            Amount = amount
        |}

        Columns.columns [ Columns.IsCentered; Columns.IsVCentered ] [
            Column.column [ Column.Width (Screen.All, Column.IsNarrow) ] [
                Field.div [ Field.Modifiers [ Modifier.TextWeight TextWeight.Bold ] ]
                    [ str wasteStreamData.Name ]
            ]
            Column.column [ Column.Width (Screen.All, Column.IsNarrow) ] [
                Field.div [ Field.HasAddons ] [
                    Control.div [ ] [
                        Input.text
                            [ Input.Disabled wasteStreamData.IsManual
                              Input.Placeholder "Hoeveelheid"
                              Input.Value wasteStreamData.Amount
                              Input.Props [ onChange (UpdateResidualAmount >> dispatch)
                                            Style [ Width "140px"] ] ]
                        ]
                    Control.div [ ] [
                        Button.a [ Button.IsStatic true ] [ str "kg" ]
                    ]
                ]
            ]

            if wasteStreamData.IsManual then
                Column.column [ Column.Width (Screen.All, Column.IsNarrow) ] [
                    Button.button
                        [ Button.OnClick (fun _ -> SetModal (Some Modal.ResidualWasteInput) |> dispatch)
                          Button.Color IsPrimary
                          Button.Size IsSmall ]
                        [ Icon.icon [ Icon.Size IsSmall; Icon.Modifiers [ Modifier.TextColor IsWhite ] ]
                            [ Fa.i [ Fa.Solid.PencilAlt ] [ ] ] ]
                ]
        ]


    let private toggleNonResidualWaste (model: Model) (dispatch : Msg -> unit) =
        Field.div [ ] [
            Checkbox.checkbox [ ] [
                Checkbox.input [ Props [ Style [ Margin "auto .5em" ]
                                         if model.IsNonResidualWastes then Checked true
                                         OnChange (fun ev -> dispatch ToggleSeparatedWastes) ] ]
                Text.span [ Modifiers [ Modifier.TextWeight TextWeight.Bold ] ] [
                    str "Ons afval wordt al gescheiden"
                ]
            ]
        ]

    let private toggleManualResidualWaste (model: Model) (dispatch: Msg -> unit) =
        Field.div [ Field.Props [ Style [ MaxWidth "80vw"; Margin "auto" ] ] ] [
            Text.span [ ] [
                str """
                    De impact van jouw afvalstroom wordt berekend op basis van Nederlandse gemiddelden.
                    Heb je een recente analyse van de samenstelling van je restafval                    """
            ]
            if model.UserProfile.IsSome && model.IsNonResidualWastes && model.IsExpandedFullWasteEntries then
                Text.span [ ] [ str " en/of je bouw- en sloopafval" ]
            Text.span [ ] [ str "? " ]
            a [ OnClick (fun ev -> dispatch ToggleResidualWasteInput) ] [
                Text.span [ ] [
                    str "Pas dan hier handmatig de gemiddeldes aan."
                ]
            ]
        ]

    let private toggleExpandFullWasteEntries (model: Model) (dispatch : Msg -> unit) =
        let faIcon, text =
            model.IsExpandedFullWasteEntries
            |> function
               | false -> Fa.Solid.Plus, "Meer velden tonen"
               | true  -> Fa.Solid.Minus, "Minder velden tonen"
        Field.div [ Field.IsGroupedCentered ] [
            a [ OnClick (fun ev -> dispatch ToggleExpandedFullWasteEntries)
                Style [ Color "black" ] ] [
                Icon.icon [ ] [ Fa.i [ faIcon ] [ ] ]
                str text
            ]
        ]

    // let private selectSectorDropdown (model: Model) (dispatch : Msg -> unit) staticContent =
    //         Field.div [ Field.IsGroupedRight; Field.Props [ Style [ MarginRight "1em" ] ] ] [
    //             Field.label [ Field.Label.Props [ Style [ Margin "auto .5em auto auto" ] ] ]
    //                 [ str "Je sector"
    //                   span [ Style [ PaddingLeft ".35em"; FontWeight "initial"; Color "#666" ] ] [
    //                     infoTooltip "De vergelijking met de sector is nu gemaakt\n o.b.v. de gemiddelde samenstelling van \nNederlands huishoudelijk restafval. Binnen-\nkort is een sector-specifieke vergelijking \nmogelijk." ]
    //                   ]
    //             Field.div [ ]
    //                 [ sectorDropdown staticContent model.SectorId (ChooseSector >> dispatch) ]
    //         ]


    let buttonDownloadCsv (projectName: string) (ws: GridRowData list) (ownImpact: CalculatedImpact) staticContent =

        let filenameSuffix = if projectName= "" then "" else " - " + projectName

        Field.div [ Field.Props [ Style [ Padding "1em 0 0" ] ] ] [
            Button.a
                [ Button.Color IsPrimary
                  Button.Props [
                    Href (encodeWasteStreamData ws ownImpact staticContent)
                    Download ("Resultaten Impact Checker" + filenameSuffix + ".csv")
                  ] ]
                [ str "Download resultaten (CSV)" ]
        ]

    let buttonPrintPdf
        (projectData: string * GridRowData list)
        (comparisonData: (ProjectId option * string * WastePerStream) option)
        (sectorId: SectorId)
        (staticContent: StaticContent) =
        Field.div [ Field.Props [ Style [ Padding "1em 0 0" ] ] ] [
            Button.a
                [ Button.Color IsPrimary
                  Button.OnClick (fun _ -> PagePrint.printPage projectData comparisonData sectorId staticContent) ]
                [ str "Download resultaten (PDF)" ]
        ]

    let boardUserDataEntry (model: Model) (dispatch : Msg -> unit) staticContent =
        Field.div [] [
            Heading.h6 [
                Heading.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]
                Heading.Props [ Style [ MarginBottom "-20px"; MarginTop "40px" ] ]
            ] [ str "Stap 1 van 2" ]

            Container.container [ Container.CustomClass "container-with-border"
                                  Container.Props [ Style [ MarginTop "1.75rem" ] ] // make room for tooltip
                                  Container.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ] [
                Field.div [ ] [

                    // div [ Style [ Height "2rem" ] ] [
                    //     Heading.h6 [ ] [ str "Stap 1 van 2" ]
                    // ]
                    div [ Style [ Height "2rem" ] ] [
                        Heading.h4 [ ] [ str "Bereken je huidige impact" ]
                    ]
                    // div [ Style [ Position PositionOptions.Absolute; Top "1.5rem"; Left 0; Width "100%" ] ]
                    //     [ Heading.h4 [ ] [ str "Bereken je huidige impact" ] ]
                    // div [ Style [ Position PositionOptions.Absolute; Top "1.5rem"; Left 0; Width "100%" ] ]
                    //     [ selectSectorDropdown model dispatch staticContent ]
                ]
                userDataEntryResidualWaste model dispatch
                toggleNonResidualWaste model dispatch
                if model.IsNonResidualWastes then
                    userDataEntryNonResidualWastes model dispatch
                    if model.UserProfile.IsSome then
                        toggleExpandFullWasteEntries model dispatch
                toggleManualResidualWaste model dispatch
            ]
        ]


let view (model: Model) (dispatch : Msg -> unit) staticContent page =

    let ownWasteAmount : WastePerStream =
        model.WasteAmountGridData
        |> List.map (fun gridData -> gridData.WasteStreamId, gridData.ToWasteAmount)
        |> Map.ofList

    let ownImpact : CalculatedImpact =
        ownWasteAmount |> Calculation.calculateImpact staticContent

    let benchmarkImpact : CalculatedImpact Option =
        Calculation.tryCalculateSectorImpact staticContent model.SectorId staticContent.Sectors (Calculation.sumWastePerStream ownWasteAmount)

    let getComparisonData (benchmarkImpact: CalculatedImpact) =
        model.ComparisonWasteStreamDataBarchart
        |> Option.map (fun (projectId, title, data) ->
            title,
            data |> Calculation.calculateImpact staticContent
            )
        |> Option.defaultValue ("Sectorgemiddelde", benchmarkImpact)

    let projectComparisonDropdown (dataCategory: DataCategory) (model: Model) (dispatch: Msg -> unit) =
        match model.UserProfile with
        | None -> nothing
        | Some user ->
            let selectedComparisonProjectId =
                model.ComparisonWasteStreamDataBarchart
                |> Option.map (fun (projectId, title, data) -> projectId)
                |> Option.flatten
            Select.select [ ] [
                select [
                    OnChange (fun ev ->
                            SelectProjectComparisonData (ProjectId (int ev.Value)) |> dispatch)
                ] [
                    // Set ProjectId = 0 for Sectorgemiddelde
                    option [ Value 0 ] [ str "Sectorgemiddelde" ]

                    for KeyValue(projectId, project) in user.Projects do
                        match model.ProjectSelected with
                        | Some currentProject ->
                            // filter out the current user's project, not for comparison
                            if currentProject.Id <> Some projectId then
                                option [
                                    if selectedComparisonProjectId = Some projectId then Selected true
                                    Value projectId.Value
                                    ] [ str project.Name ]
                        | None ->
                            option [
                                if selectedComparisonProjectId = Some projectId then Selected true
                                Value projectId.Value
                                ] [ str project.Name ]
                ]
            ]

    let buttonsSwitchGraphOrTable (dataCategory: DataCategory) (model: Model) (dispatch: Msg -> unit) =
        let isShowingGraph =
            dataCategory
            |> function
               | PerImpact -> model.ToggleGraphOrTable.PerImpact
               | PerWasteStream -> model.ToggleGraphOrTable.PerWasteStream
        Field.div [ Field.HasAddonsRight ] [
            Control.div [ ] [
                Button.button [ Button.IsActive (isShowingGraph)
                                Button.OnClick (fun ev ->
                                    ToggleGraphOrTable {| ToggleSourceIsGraph = true; DataCategory = dataCategory |} |> dispatch
                                    ) ]
                    [ str "Grafiek" ]
            ]
            Control.div [ ] [
                Button.button [ Button.IsActive (not isShowingGraph)
                                Button.OnClick (fun ev ->
                                    ToggleGraphOrTable {| ToggleSourceIsGraph = false; DataCategory = dataCategory |} |> dispatch
                                    ) ]
                    [ str "Tabel" ]
            ]
        ]

    let resultsPerImpact =
        Columns.columns [ ] [
            match benchmarkImpact with
            | Some benchmarkImpact ->
                Column.column [ ] [
                    Columns.columns [ ] [
                        Column.column [ ] [
                            Heading.h4 [ ]
                                [ str "Negatieve milieu-impact in euro's per milieu-indicator" ]
                            Content.content [ ]
                                [ str """
                                    Hieronder zie je de milieu-impact van jouw afval uitgedrukt in euro's per milieu-indicator.
                                    Deze bedragen zijn gebaseerd op de maatschappelijke kosten om deze effecten te voorkomen en ongedaan te maken.
                                    Door met je muis over de grafiek te bewegen kan je zien wat deze cijfers concreet betekenen.
                                    """ ]
                        ]
                        Column.column [ ] [
                            Level.level [ ] [
                                Level.left [ ] [ ]
                                Level.right [ ] [
                                    Level.item [ ] [
                                        projectComparisonDropdown PerImpact model dispatch
                                    ]
                                    Level.item [ ] [
                                        buttonsSwitchGraphOrTable PerImpact model dispatch
                                    ]
                                ]
                            ]
                        ]
                    ]

                    /// ToggleGraphOrTable: true -> show graph; false -> show table
                    match model.ToggleGraphOrTable.PerImpact with
                    | true ->
                        let titleOwnImpact =
                            model.ProjectName
                            |> function
                                | "" -> "Jouw impact"
                                | name -> "Jouw impact - " + name
                        Fable.Recharts.ComparisonData.barChartPerImpact
                            {|
                                OwnImpact = titleOwnImpact, ownImpact
                                ComparisonImpact = getComparisonData benchmarkImpact
                            |}
                            staticContent
                            (fun st _ -> dispatch (SetModal None))
                    | false ->
                        Components.tableResultPerImpact ownImpact staticContent
                ]
            | None -> nothing

            ]


    let resultsPerStream =
        Columns.columns [ ] [
            match benchmarkImpact with
            | Some benchmarkImpact ->
                Column.column [ ] [
                    Columns.columns [ ] [
                        Column.column [ ] [
                            Heading.h4 [ ]
                                [ str "Negatieve milieu-impact in euro's per afvalstroom" ]
                            Content.content [ ]
                                [ str """
                                    Hieronder zie je de milieu-impact van jouw afval uitgedrukt in euro's per afvalstroom.
                                    Je kunt hier zien hoe jouw afvalstromen zich onderling verhouden.
                                    Ook afvalstromen met een klein volume kunnen een grote impact hebben.
                                    """ ]
                        ]
                        Column.column [ ] [
                            Level.level [ ] [
                                Level.left [ ] [ ]
                                Level.right [ ] [
                                    Level.item [ ] [ projectComparisonDropdown PerWasteStream model dispatch ]
                                    Level.item [ ] [ buttonsSwitchGraphOrTable PerWasteStream model dispatch ]
                                ]
                            ]
                        ]
                    ]



                    /// ToggleGraphOrTable: true -> show graph; false -> show table
                    match model.ToggleGraphOrTable.PerWasteStream with
                    | true ->
                        let titleOwnImpact =
                            model.ProjectName
                            |> function
                                | "" -> "Jouw impact"
                                | name -> "Jouw impact - " + name
                        Fable.Recharts.ComparisonData.barChartPerWasteStream
                            {|
                                OwnImpact = titleOwnImpact, CalculatedImpact.filterOutInseparableWaste staticContent.WasteStreams ownImpact
                                ComparisonImpact = CalculatedImpact.filterOutInseparableWaste staticContent.WasteStreams benchmarkImpact |> getComparisonData
                            |}
                            staticContent
                            (fun st _ -> dispatch (SetModal None))
                    | false ->
                        Components.tableResultPerWastestream
                            (model.ProjectName, CalculatedImpact.filterOutInseparableWaste staticContent.WasteStreams ownImpact)
                            (CalculatedImpact.filterOutInseparableWaste staticContent.WasteStreams benchmarkImpact |> getComparisonData )
                            staticContent

                    if model.UserProfile.IsSome then
                        Level.level [] []
                        Content.content
                            [ Content.Size IsSmall ]
                            [ str """
                                  * Uitgaande van verbranding met/zonder energieterugwinning bij een gemiddelde samenstelling van het gevaarlijk afval.
                                  Wil je een gedetailleerde analyse op jouw gevaarlijk afvalstromen?
                                  """
                              a [ Navigation.Contact.ToHref ] [ str "Neem dan contact met ons op" ]
                              str "." ]
                    Field.div [ ] [
                        Sections.buttonDownloadCsv model.ProjectName model.WasteAmountGridData ownImpact staticContent
                        Sections.buttonPrintPdf
                            (model.ProjectName, model.WasteAmountGridData)
                            model.ComparisonWasteStreamDataBarchart
                            model.SectorId staticContent
                        div [ Id PagePrint.printElementId; Hidden true ] [ ]
                    ]
                ]

            | _ -> nothing
            ]


    let sectionJouwImpact =
        div [ ClassName "section-jouw-impact" ] [
            div [ ClassName "section-overlay"

                  // show the visuals by setting overlay hidden=true, or hide the visuals at overlay hidden=false
                  Hidden (not model.IsHiddenSectionVisuals) ] [ ]

            // cards result summary
            Heading.h3 [ ] [ str "Jouw impact" ]
            Components.cardsResultSummary ownImpact ownWasteAmount

            // button to go to step 2
            Columns.columns [ ] [
                Column.column [ Column.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Right) ] ] [ buttonToStepTwo true ]
            ]

            // results per impact
            Container.container [ ] [
                resultsPerImpact
            ]

            // results per stream
            Container.container [ ] [
                resultsPerStream
            ]
        ]

    let sectionNavigation =
        Columns.columns [ ] [
            Column.column [ ] [
                Heading.h4 [ ] [ str "Jouw milieu-impact verkleinen" ]
                Field.div [ ] [
                    Text.p [ ] [ str """
                        Duurzaam afvalmanagement heb je zelf in de hand.
                        Door afval te reduceren en afvalstromen gescheiden en schoon aan te leveren voor recycling,
                        minimaliseer je als organisatie of gemeente de milieu-impact van jullie afval.
                        Zo draag je bij aan het beperken van klimaatverandering en grondstoffenschaarste.
                        """
                    ]
                ]
                Field.div [ ] [
                    Text.p [ ] [ str """
                    Wist je dat PreZero een analyse kan doen van jouw restafval?
                    """
                    ]
                    a [ Navigation.Route.Contact.ToHref ] [
                        Text.p [ ] [ str """
                        Neem contact met ons op voor meer informatie.
                        """
                        ]
                    ]
                ]
            ]
            Column.column [ Column.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Right) ] ] [ buttonToStepTwo false ]
        ]

    let sectionResults =
        match benchmarkImpact with
        | Some _ ->
            [
                PreZeroSection
                    {|
                        Class = BackgroundWhite
                        Content = sectionJouwImpact
                        SectionId = None
                    |}
            ]

        | None ->
            [
                PreZeroSection
                    {|
                        Class = CompanyIntro
                        Content =
                            Section.section
                                [ Section.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ]
                                [ str "Selecteer een sector om je impact te zien" ]
                        SectionId = None
                    |}
            ]

    let preZeroSections =
        [
            PreZeroSection
                {|
                    Class = CompanyIntro
                    Content = Sections.sectionLanding model dispatch
                    SectionId = None
                |}

            PreZeroSection
                {|
                    Class = CompanyIntro
                    Content =
                        Field.div [ ] [
                            Sections.boardProjectManagementPreData model dispatch
                            modal model dispatch staticContent
                            Sections.boardUserDataEntry model dispatch staticContent
                            Sections.boardProjectManagementPostData model dispatch
                        ]
                    SectionId = None
                |}
        ]
        @ sectionResults
        @ [
            PreZeroSection
                {|
                    Class = BackgroundWhite
                    Content = sectionNavigation
                    SectionId = None
                |}
          ]

    { Landing = None; Sections = preZeroSections }