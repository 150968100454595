module Components.Layouts

open Components.Helpers
open Components.Button

open Fulma
open Fable.React
open Fable.React.Props



module Images =

    let central (filename : string) =
        Image.image [
            // Class "central center"
            // Style [ PaddingTop "3vh" ]
            Image.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ]
        ] [
            img [ Src (sprintf "images/%s.png" filename) ]
        ]


    let side (image : string) (leftOrRight : FloatOptions) (imageOptions : Image.Option list) =
        Field.div [] [
            Image.image imageOptions [
                img [ Class "center"
                      Style [ ObjectFit "cover" ; Width "100%"; Float leftOrRight]
                      Src  (sprintf "images/%s.png" image) ] ] ]


    let sideDouble (image1 : string) (image2 : string) (leftOrRight : FloatOptions) (imageOptions : Image.Option list) =
        let imageHelper (imageLocation : string) =
            Image.image imageOptions [
                    img [ Class "center"
                          Style [ PaddingTop "75px"; ObjectFit "cover" ; Width "100%"; Float leftOrRight]
                          Src  (sprintf "images/%s.png" imageLocation) ]
            ]

        Field.div [] [
            imageHelper image1
            imageHelper image2
        ]




let centralCard title content =
    Section.section [
        Section.Modifiers [Modifier.TextAlignment (Screen.All, TextAlignment.Centered)]] [
        Heading.h1 [  ] [str title]
        Container.container [ Container.Props [ Style [ PaddingTop "25px" ] ] ] content
    ]


let standardTile className content size =
    Tile.parent [
        Tile.CustomClass className
        Tile.Size size
        Tile.Props [ Style [ Height "100%" ] ]
        Tile.IsVertical
    ] [
        for element in content do
            Tile.child [] [element]
    ]


let twoTileSection contentLeft contentRight =
    Section.section [] [
        Container.container [] [
          Tile.ancestor [] [
              standardTile "left" contentLeft Tile.Is6
              standardTile "right" contentRight Tile.Is6
          ]
        ]
      ]


let threeTileContainer contentLeft contentMiddle contentRight =
    Container.container [ Container.Props [ Style [ PaddingTop "25px"; PaddingBottom "50px"] ] ] [
        Tile.ancestor [] [
            standardTile "left" contentLeft Tile.Is4
            standardTile "middle" contentMiddle Tile.Is4
            standardTile "right" contentRight Tile.Is4
        ]
    ]


let twoTileSectionSkew contentLeft contentRight =
     Section.section [] [
        Container.container [] [
          Tile.ancestor [] [
            Tile.parent [
                Tile.CustomClass "facts-layout"
                Tile.Modifiers [Modifier.TextAlignment (Screen.All, TextAlignment.Centered)]
                Tile.Size Tile.Is3
                Tile.Props [Style [Height "100%" ]]
                Tile.IsVertical
            ] [
                for element in contentLeft do
                    Tile.child [
                        Tile.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]
                        Tile.CustomClass "facts-layout"
                    ] [
                        element
                    ]
            ]
            Tile.parent [
                Tile.CustomClass "right"
                Tile.Size Tile.Is9
                Tile.Props [ Style [ Height "100%" ] ]
                Tile.IsVertical
            ] [
                for element in contentRight do
                    Tile.child [] [ element ]
            ]
          ]
        ]
      ]


let twoTileContainerSkewLeft contentLeft contentRight =
    Container.container [ Container.Props [ Style [ PaddingTop "25px"; PaddingBottom "50px"] ] ] [
      Tile.ancestor [] [
        Tile.parent [
            Tile.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Justified) ]
            Tile.Size Tile.Is3
            Tile.Props [Style [Height "100%" ]]
            Tile.IsVertical
        ] [
            for element in contentLeft do
                Tile.child [
                    Tile.Modifiers [Modifier.TextAlignment (Screen.All, TextAlignment.Justified)]
                ] [element]
        ]
        Tile.parent [
            Tile.CustomClass "right"
            Tile.Size Tile.Is9
            Tile.Props [ Style [ Height "100%" ] ]
            Tile.IsVertical
        ] [
            for element in contentRight do
                Tile.child [] [element]
        ]
      ]
    ]


let twoTileSectionSkewRight contentLeft contentRight =
    Container.container [] [
      Tile.ancestor [] [
        Tile.parent [
            Tile.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]
            Tile.Size Tile.Is9
            Tile.Props [ Style [ Height "100%" ] ]
            Tile.IsVertical
        ] [
            for element in contentLeft do
                Tile.child [
                    Tile.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Justified) ]
                ] [
                    element
                ]
        ]
        Tile.parent [
            Tile.CustomClass "right"
            Tile.Size Tile.Is3
            Tile.Props [ Style [ Height "100%" ] ]
            Tile.IsVertical
        ] [
            for element in contentRight do
                Tile.child [] [element]
        ]
      ]
    ]



let bigSection (content : ReactElement list) =
    Section.section [] [
        Container.container [] [
            for element in content do
            element
        ]
    ]



let textRight (header: string) (content: ReactElement) (image: string) =
    twoTileSection
    <| [ Images.side image FloatOptions.Left [Image.Is2by1] ]
    <| [ CustomHeaders.cardHeader header ; content ]


let textLeft (header: string) (content: ReactElement) (image: string) =
    twoTileSection
    <| [ CustomHeaders.cardHeader header ; content ]
    <| [ Images.side image FloatOptions.Right [Image.Is2by1] ]


let textRightWithButton (header: string) (content: ReactElement) (buttonContent: HTMLAttr * string) (image: string) =
    twoTileSection
    <| [Images.side image FloatOptions.Left [Image.Is2by1]]
    <| ([hrefButton buttonContent] |> List.append [ CustomHeaders.cardHeader header ; content ])


let textLeftWithButton (header: string) (content: ReactElement) (buttonContent: HTMLAttr * string) (image: string) =
    twoTileSection
    <| ([hrefButton buttonContent] |> List.append [ CustomHeaders.cardHeader header ; content ])
    <| [Images.side image FloatOptions.Right [Image.Is2by1]]
