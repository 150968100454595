module ImpactChecker.Texts

open Fable.React
open Fable.React.Props
open Fulma
open Feliz

let explanationHowItWorks (dispatch : Msg -> unit) = [
    Text.p [ ] [ str "Om je impact te berekenen doorloop je de volgende stappen:" ]
    Content.content [ ] [
        Content.Ol.ol [ ] [
            li [ ] [
                str "Vul de hoeveelheid restafval in die vrijkomt bij jouw bedrijf"
                Content.Ol.ol [ Content.Ol.IsLowerAlpha; Content.Ol.Props [ Style [ MarginTop "auto"]] ] [
                    li [ ] [
                        str "Indien je de samenstelling weet van je restafval kan je die "
                        a [ OnClick (fun ev -> dispatch ToggleResidualWasteInput) ] [ str "hier" ]
                        str " aanpassen" ]
                ]
            ]
            li [ ] [ str "Vul de hoeveelheid afval van de overige stromen in die al gescheiden worden ingezameld" ]
            li [ ] [ str "Scroll omlaag om je impact te bekijken" ]
        ]
    ]
    Html.div [
        Html.iframe [
            prop.width (length.percent 100)
            prop.height (length.em 400 )
            prop.title "Instructievideo Impact Checker"
            prop.src "https://www.youtube.com/embed/McLenEnDT50"
            prop.controls true // show controls on hover
        ]
    ]
]

let impactExplained =
    Text.span [Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Justified) ]] [
        Text.p [] [
            str "Duurzaam afvalmanagement heb je zelf in de hand. Door afval te reduceren en afvalstromen gescheiden en schoon aan te leveren voor recycling, minimaliseer je als organisatie of gemeente de milieu-impact van jullie afval. Zo draag je bij aan het beperken van klimaatverandering en grondstoffenschaarste."
        ]
    ]

let goToImproveImpact (refToImproveImpactPage : HTMLAttr ) =
    Text.span [Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Justified) ]] [
        Text.p [] [
            str "We dagen je graag uit: hoe kun je je impact verkleinen door slimmer om te gaan met je restafval? Schuif met je afvalkilo's in de Impact Checker en ontdek hoe je impact verlaagt als je bijvoorbeeld meer plastic uit je restafval scheidt. Benieuwd naar ideeën en mogelijkheden om jouw bedrijf te helpen? "
            a [refToImproveImpactPage] [ str "Ontdek hier hoe jij je impact kunt verkleinen" ]
            str "." ]
    ]