module Faq.View

open Fulma
open Fable.FontAwesome
open Fable.React
open Fable.React.Props

open Components.Layouts
open PreZero
open Faq

let view (model: Model) (dispatch : Msg -> unit) staticContent page =

    let content =
        model.Faq
        |> List.collect (fun qa ->

            let icon =
                qa.Visible
                |> function
                   | true  -> Fa.Solid.Minus
                   | false -> Fa.Solid.Plus

            [
                Message.message
                    [ Message.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Left) ] ]
                    [
                        Message.header
                            [ Props [ OnClick (fun _ -> (dispatch (ToggleActive qa.Id))) ] ]
                            [ str qa.Question
                              Icon.icon [  ] [ Fa.i [ icon ] [ ] ]  ]
                        Message.body
                            [ Props [ Hidden (not qa.Visible) ] ]
                            [ Content.content [ ] qa.Answer ]
                    ]
            ]
        )

    let preZeroSections =
        let content =
            centralCard
                "Veelgestelde vragen"
                <| [
                    Columns.columns [ Columns.IsCentered ] [
                    Column.column [ Column.Width (Screen.All, Column.Is8) ]
                        [
                            Field.div [ ] [
                                Text.p [ ] [ str """
                                             Hieronder kan je het antwoord vinden op de meest gestelde vragen.
                                             Staat jouw vraag er niet tussen? We horen het graag!
                                             """ ]
                            ]
                            Level.level [] []
                            Field.div [ ] [
                                Button.a
                                    [ Button.Color IsPrimary
                                      Button.Size IsMedium
                                      Button.Props [ Navigation.Contact.ToHref ] ]
                                    [ str "Neem contact op" ]
                            ]

                            Section.section [ ] content
                        ]
                    ]
                ]
        [
            PreZeroSection
                {|
                    Class = PreZeroSectionClass.CompanyIntro
                    Content = content
                    SectionId = None
                |}
        ]

    { Landing = None; Sections = preZeroSections }
