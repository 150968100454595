module Components.Button

open Shared

open Fulma
open Fulma.Extensions.Wikiki
open Fable.React
open Fable.React.Props


let hrefButton ((href, name): HTMLAttr * string) =
    Level.level [ ] [
        Button.a [
            Button.Color IsPrimary
            Button.Props [ href ]
        ] [
            str name
        ]
    ]


let hrefButtonCentered ((href, name): HTMLAttr * string) =
    Level.level [] [
        Level.item [Level.Item.HasTextCentered] [
            Button.a [
                Button.Color IsPrimary
                Button.Props [ href ]
            ] [
                str name
            ]
        ]
    ]

let saveProjectButton (project : Project option) (onClick : Project -> unit) : ReactElement =

    let buttonText = "Project opslaan"

    Button.a [
        Button.Color IsPrimary

        match project with
        | Some proj ->
            Button.Props [ OnClick (fun ev -> onClick proj) ]
        | None ->
            Button.Props [ Tooltip.dataTooltip "Controleer of je een projectnaam hebt ingevuld"]
            Button.Disabled true
            Button.CustomClass (Tooltip.ClassName + " " + Tooltip.IsTooltipRight )
        ] [
            str buttonText
        ]


let deleteAccountButton (user : UserProfile) (onClick : UserProfile -> unit) : ReactElement =

    let buttonText = sprintf "Verwijder account met email %s" user.Email

    Button.a [
        Button.Color IsPrimary
        Button.Props [ OnClick (fun ev -> onClick user) ]
    ] [
        str buttonText
    ]
