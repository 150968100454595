module Home.Texts

open Fable.React
open Fable.React.Props
open Fulma
open Fable.FontAwesome

let private externalLink (href: string) (content: ReactElement list) =
    a [ Href href; Target "_blank" ]
      content

let links =
    {|
        whitepaper = "./whitepaper-june2022.pdf"
        frameworkForImpactStatements = "https://www.impactinstitute.com/framework-for-impact-statements/"
        monetisationFactorsForTruePricing = "https://trueprice.org/monetisation-factors-for-true-pricing/"
    |}

let introduction = """
    Kun jij minder impact op het milieu maken?
    Wij dagen je uit!
    Wij helpen je graag op weg door te kijken waar voor jouw bedrijf de kansen liggen.
    Sorteer je afval in de Impact Checker en ontdek welk verschil jij kan maken voor het klimaat.
    """

let factsIntro = """
    Ga je meer afval scheiden?
    Dat betekent dat het recyclen van je afval minder impact heeft op onze lucht, bodem, CO2-uitstoot en meer!
    Je draagt dus bij aan het omlaag halen van deze cijfers:
    """

let facts = [
    {| Number = "50%"; Description = "van het GFT (swill) eindigt nog bij het bedrijfsafval (restafval)" |}
    {| Number = "77%"; Description = "van het kantoorafval wordt nog helemaal niet gescheiden" |}
    {| Number = "80%"; Description = "van de kartonnen koffiebekers belandt nog bij het bedrijfsafval (restafval)" |}
]

let verkleinImpact = """
    Verklein je impact door slimmer om te gaan met restafval.
    Schuif met je afvalkilo’s in de Impact Checker en ontdek hoe je impact verlaagt als je bijvoorbeeld meer plastic uit je restafval haalt.
    """

let whatIsImpact = [
    """
    De milieu-impact van afvalverwerking omvat alle effecten van het gehele verwerkingsproces op het milieu.
    Het kan gaan om positieve effecten, zoals het behoud van biodiversiteit, maar óók om schadelijke effecten, zoals de uitstoot van broeikasgassen die bijdraagt aan klimaatverandering of andere vormen van lucht-, water- of bodemvervuiling.
    """
    |> fun pp -> Text.p [ ] [ str pp ]

    """
    In de Impact Checker krijgen bedrijven inzicht in de milieu-impact van hun afval én kunnen ze zien welke mogelijkheden er zijn om deze impact te verkleinen.
    """
    |> fun pp -> Text.p [ ] [ str pp ]

    Level.level [ ] [ ]

    a [ Href links.frameworkForImpactStatements ; Target "_blank" ] [
        Text.span [ ] [ str "Wil je meer weten over milieu-impact? Bekijk dan de whitepaper" ]
        Icon.icon [ ] [ Fa.i [ Fa.Solid.ArrowRight ] [ ] ]
    ]
]

let howToCalculate = [
    """
    Het berekenen van je impact gebeurt in twee stappen. Eerst bepalen we de impact in emissies en effecten per afvalstroom.
    Dit doen we via de data van EcoInvent en het ReCiPe 2016 life cycle impact assessment.
    Daarna berekenen we aan de hand van Monetisation Factors for True Pricing wat de maatschappelijke kosten zijn om de veroorzaakte negatieve impact te herstellen.
    """
    |> Components.Helpers.parseParagraphWithLinks [
            {| Texts = "Monetisation Factors for True Pricing"; Href = links.monetisationFactorsForTruePricing |}
        ]

    """
    In de berekening kijken we specifiek naar de effecten van de verwerking van afval.
    Transport naar de verwerkingslocatie wordt hier niet in meegenomen.
    Daarnaast houden we rekening met een referentiescenario.
    We vergelijken op de Impact Checker de impact van het recyclen van gescheiden afvalstromen met de impact van verbranding als deze afvalstroom in het restafval had gezeten.
    """
    |> fun pp -> Text.p [ ] [ str pp ]

    Level.level [ ] [ ]

    a [ Href links.monetisationFactorsForTruePricing ; Target "_blank" ] [
        Text.span [ ] [ str "Meer over Monetisation Factors for True Pricing" ]
        Icon.icon [ ] [ Fa.i [ Fa.Solid.ArrowRight ] [ ] ]
    ]
]

let whitePaperReference =
    """
    Lees hier in ons whitepaper meer over hoe we milieu-impact meten, de maatschappelijke kosten en hoe dit in de praktijk gebruikt wordt.
    """