module PreZero

open Shared

type CalcSetting =
    static member dangerousWasteStreamName = "Gevaarlijk afval"
    static member residualWasteStreamId = WasteStreamId 12
    static member constructionWasteStreamId = WasteStreamId 14
    static member otherConstructionWasteStreamId = WasteStreamId 20

    static member climateWasteId : ImpactId = ImpactId 3
    static member waterWasteId : ImpactId = ImpactId 6

    static member defaultSectorId = SectorId 1

    static member climateMonetisationFactor = 0.157<eur/kg>

type PreZeroPage =
    { Landing : PreZeroLanding option
      Sections: PreZeroSection list }
and PreZeroLanding = PreZeroLanding of {| Props : Fable.React.Props.CSSProp list; Content : Fable.React.ReactElement |}
and PreZeroSection = PreZeroSection of {| Class: PreZeroSectionClass; Content: Fable.React.ReactElement; SectionId: string option |}
    with
        member this.ContentRoot =
            this |> function | PreZeroSection s -> s.Content
        member this.Class =
            this |> function | PreZeroSection s -> s.Class
        member this.Id =
            this |> function | PreZeroSection s -> s.SectionId
        member this.ClassName = this.Class.ClassName
and PreZeroSectionClass =
    | CompanyIntro
    | BackgroundWhite
    | Hero
    | NoClass with
    member this.ClassName =
        match this with
        | CompanyIntro -> "company-intro"
        | BackgroundWhite -> "background-white"
        | Hero -> "hero"
        | NoClass -> ""

type IsRoundedCorner =
    | IsLanding
    | IsBottomRightCorner
    | IsTwoCorners
    with
        member this.ClassName =
            sprintf "rounded-corner %s" <|
            (match this with
             | IsLanding -> "is-landing"
             | IsBottomRightCorner -> "is-bottom-right-corner"
             | IsTwoCorners -> "is-two-corners")

/// PreZero color scheme. See also src\Client\stylesheet\colors.sass
[<RequireQualifiedAccess>]
type PreZeroColor =
    // primary colors
    | Petrol
    | BrightGreen
    | RoyalBlue

    // secondary colors
    | Green
    | Mint
    | LightBlue
    | Blue

    member this.HexCode =
        match this with
        | Petrol      -> "#00343d"
        | BrightGreen -> "#97d700"
        | RoyalBlue   -> "#003cc1"
        | Green       -> "#6ba43a"
        | Mint        -> "#a8d7ce"
        | LightBlue   -> "#5ac5f2"
        | Blue        -> "#008ff2"

    member this.CSSProp =
        Fable.React.Props.CSSProp.Color this.HexCode
    member this.HTMLAttr =
        Fable.React.Props.Style [ this.CSSProp ]

// @TODO expand
type ModelError = string

type ModelStatus =
    | Idle
    | NewCommand
    | FinishedCommand
    | ModelError of ModelError list

type AppStatus =
    | Idle
    | CommandsInProcess of int
    | AppError of ModelError list
    with
        static member (+) (appStatus : AppStatus, modelStatus : ModelStatus) =

            match appStatus, modelStatus with

            // App or model is Idle
            | _, ModelStatus.Idle   -> appStatus
            | Idle, NewCommand      -> CommandsInProcess 1
            | Idle, FinishedCommand -> AppError [ "Finished non-existent command (AppStatus was Idle)"]

            // App is processing commands
            | CommandsInProcess n, NewCommand       -> CommandsInProcess (n + 1)
            | CommandsInProcess 1, FinishedCommand  -> Idle
            | CommandsInProcess n, FinishedCommand  -> CommandsInProcess (n - 1)

            // In case of errors, combine and send through
            | Idle, ModelError e | CommandsInProcess _, ModelError e -> AppError e
            | AppError e, NewCommand | AppError e, FinishedCommand   -> AppError e
            | AppError appErrors, ModelError modelErrors             -> appErrors |> List.append modelErrors |> AppError

type MonetizedImpact = MonetizedImpact of float<eur>
with
    static member (+) (monImpact1, monImpact2) =
        match monImpact1, monImpact2 with
            | MonetizedImpact a, MonetizedImpact b -> MonetizedImpact (a + b)
    static member (-) (monImpact1, monImpact2) =
        match monImpact1, monImpact2 with
            | MonetizedImpact a, MonetizedImpact b -> MonetizedImpact (a - b)
    static member Zero =
            MonetizedImpact 0.<eur>
    member self.InEuro =
        match self with
            | MonetizedImpact impact -> impact
    member self.Float : float =
        match self with
            | MonetizedImpact impact -> impact / 1.<eur>

type CalculatedImpact = {
    SeparatedWasteImpact    : Map<WasteStreamId*ImpactId,MonetizedImpact>
    ResidualWasteImpact     : Map<WasteStreamId*ImpactId,MonetizedImpact>
}
with
    static member filterOutInseparableWaste (wasteStreams : Map<WasteStreamId, WasteStream>) (calculatedImpact : CalculatedImpact) =
        let filteredSeparatedWasteImpact =
            calculatedImpact.SeparatedWasteImpact
            |> Map.filter (fun (wasteStreamId, impactId) monetizedImpact -> 
                wasteStreams 
                |> Map.tryFind wasteStreamId
                |> function 
                    | Some wasteStream -> wasteStream.IsSeparatable || wasteStream.Id = CalcSetting.residualWasteStreamId
                    | None -> false )
        { calculatedImpact with SeparatedWasteImpact = filteredSeparatedWasteImpact } 
    member self.SeparatedPlusResidualImpact=
        self.SeparatedWasteImpact
        |> Map.map (fun separatedWasteId separatedWasteImpact ->

            let residualWasteImpact =
                self.ResidualWasteImpact
                |> Map.tryFind separatedWasteId
                |> Option.defaultValue (MonetizedImpact 0.<eur>)

            separatedWasteImpact + residualWasteImpact )

    member self.ByWasteStreamId =
        self.SeparatedWasteImpact
        |> Map.map (fun (wasteId,impactId) impact ->
            if wasteId = CalcSetting.residualWasteStreamId then
                    self.ResidualWasteImpact
                    |> Map.toArray
                    |> Array.groupBy (fst >> snd) // group per waste stream
                    |> Array.map (fun (wasteStreamId, result) ->
                        wasteStreamId, result |> Array.sumBy snd ) // sum over monetized impact values
                    |> Map.ofArray
                    |> Map.tryFind impactId
                    |> Option.defaultValue (MonetizedImpact 0.<eur>)
            else impact
        )
        |> Map.toArray
        |> Array.groupBy (fst >> fst)
        |> Array.map (fun (wasteId, result) ->
            let totalImpact = result |> Array.sumBy snd
            (wasteId, totalImpact))

    member self.ByImpactId =
        self.SeparatedPlusResidualImpact
        |> Map.toArray
        |> Array.groupBy (fst >> snd)
        |> Array.map (fun (impactId, result) ->
            let totalImpact = result |> Array.sumBy snd
            (impactId, totalImpact))

    member self.MaxWasteStream =
        self.ByWasteStreamId
        |> Array.maxBy (fun (wasteId,monetizedImpact) -> monetizedImpact.Float )

    member self.MaxImpact =
        self.ByImpactId
        |> Array.maxBy (fun (wasteId,monetizedImpact) -> monetizedImpact.Float )


type GridRowData = {
    RowId               : int
    WasteStreamId       : WasteStreamId
    Name                : string
    Description         : string
    Unit                : Unit
    Amount              : float option
    UnitResidual        : Unit
    AmountResidual      : float option
    UnitConstruction    : Unit
    AmountConstruction  : float option
    Public              : bool
    IsSeparatable       : bool
    IsConstruction      : bool
}
with
    member this.ToWasteAmount =

        let amountWithDefault =
            WasteAmount.CreateFromOption this.Amount this.Unit

        let amountResidualWithDefault =
            WasteAmount.CreateFromOption this.AmountResidual this.UnitResidual

        let amountConstructionWithDefault =
            WasteAmount.CreateFromOption this.AmountConstruction this.UnitConstruction

        { Separated     = amountWithDefault
          Residual      = amountResidualWithDefault
          Construction  =  amountConstructionWithDefault }

    member this.Empty =
        this.Amount.IsNone && this.AmountResidual.IsNone

    member this.IsConstructionOnly =
        this.IsConstruction && not this.IsSeparatable

    static member InitialiseWasteStreamInput (wasteStreams: Map<WasteStreamId, WasteStream>) =

        // Since maps do not support the mapi function, we have to swap to a list to assign row numbers to the waste streams
        wasteStreams
        |> Map.toList
        |> List.sortBy (fun (_, wasteStream) -> wasteStream.Order)
        |> List.mapi (fun i (id, wasteStream) ->

            let rowId = i + 1 // leave room for header on row 0

            { RowId                 = rowId
              WasteStreamId         = id
              Name                  = wasteStream.Name
              Description           = wasteStream.Description
              Unit                  = Unit.Kg
              Amount                = None
              UnitResidual          = Unit.Kg
              AmountResidual        = None
              UnitConstruction      = Unit.Kg
              AmountConstruction    = None
              Public                = wasteStream.Public
              IsSeparatable         = wasteStream.IsSeparatable
              IsConstruction        = wasteStream.IsConstruction }
        )

/// The data of current result and the intervented (scenario) result, stored in record type.
type InterventionData<'A> = {
    Current : 'A
    Scenario : 'A
}

type Intervention = {
    WasteStreamId       : WasteStreamId
    Name                : string
    Description         : string
    Unit                : Unit
    Amount              : InterventionData<float option>
    UnitResidual        : Unit
    AmountResidual      : InterventionData<float option>
    UnitConstruction    : Unit
    AmountConstruction  : InterventionData<float option>
    Public              : bool
    IsSeparated         : InterventionData<bool>
    IsSeparatable       : bool
    IsConstruction      : bool
}
with
    // @TODO: This assumes that scenarioData is always in the same unit as currentData
    static member CreateFromGridRowDataList (currentData : GridRowData list) (scenarioData : GridRowData list) =
        currentData
        |> List.map (fun current ->

            let scenario =
                scenarioData
                |> List.tryFind (fun s -> s.WasteStreamId = current.WasteStreamId)
                |> Option.defaultValue current

            let isSeparated =
                current.Amount
                |> Option.map (fun amount -> amount > 0.) |> Option.defaultValue false

            {
                WasteStreamId       = current.WasteStreamId
                Name                = current.Name
                Description         = current.Description
                Unit                = current.Unit
                Amount              = { Current = current.Amount; Scenario = scenario.Amount}
                UnitResidual        = current.UnitResidual
                AmountResidual      = { Current = current.AmountResidual; Scenario = scenario.AmountResidual}
                UnitConstruction    = current.UnitConstruction
                AmountConstruction  = { Current = current.AmountConstruction; Scenario = scenario.AmountConstruction}
                Public              = current.Public
                IsSeparated         = { Current = isSeparated; Scenario = isSeparated }
                IsSeparatable       = current.IsSeparatable
                IsConstruction      = current.IsConstruction
            }
        )

    static member ConvertUnit (unit : Unit) (intervention : Intervention) =

        let newCurrentSeparatedAmount =
            intervention.ToCurrentWasteAmount.Separated
            |> WasteAmount.ConvertUnit unit

        let newScenarioSeparatedAmount =
            intervention.ToScenarioWasteAmount.Separated
            |> WasteAmount.ConvertUnit unit

        let newCurrentResidualAmount =
            intervention.ToCurrentWasteAmount.Residual
            |> WasteAmount.ConvertUnit unit

        let newScenarioResidualAmount =
            intervention.ToScenarioWasteAmount.Residual
            |> WasteAmount.ConvertUnit unit

        { intervention with
            Amount =
                { Current  = Some newCurrentSeparatedAmount.Value
                  Scenario = Some newScenarioSeparatedAmount.Value }
            AmountResidual =
                { Current  = Some newCurrentResidualAmount.Value
                  Scenario = Some newScenarioResidualAmount.Value }
            Unit         = unit
            UnitResidual = unit }

    member this.ToCurrentWasteAmount =
        { Separated     = WasteAmount.CreateFromOption this.Amount.Current this.Unit
          Residual      = WasteAmount.CreateFromOption this.AmountResidual.Current this.UnitResidual
          Construction  = WasteAmount.CreateFromOption this.AmountConstruction.Current this.UnitConstruction
        }

    member this.ToScenarioWasteAmount =
        { Separated     = WasteAmount.CreateFromOption this.Amount.Scenario this.Unit
          Residual      = WasteAmount.CreateFromOption this.AmountResidual.Scenario this.UnitResidual
          Construction  = WasteAmount.CreateFromOption this.AmountConstruction.Scenario this.UnitConstruction
        }

    member this.ToCurrentGridRowData =
        let currentWasteAmount = this.ToCurrentWasteAmount
        {
            RowId              = 0
            WasteStreamId      = this.WasteStreamId
            Name               = this.Name
            Description        = this.Description
            Unit               = this.Unit
            Amount             = Some currentWasteAmount.Separated.Value
            UnitResidual       = this.UnitResidual
            AmountResidual     = Some currentWasteAmount.Residual.Value
            UnitConstruction   = this.UnitConstruction
            AmountConstruction = Some currentWasteAmount.Construction.Value
            Public             = this.Public
            IsSeparatable      = this.IsSeparatable
            IsConstruction     = this.IsConstruction
        }

    member this.IsConstructionOnly =
        this.IsConstruction && not this.IsSeparatable

    member this.ToggleWasteStreamSeparated =
        // switch from separated to disposal of this waste stream
        if this.IsSeparated.Scenario then
            let newSeparatedScenario = WasteAmount.Zero
            let newResidualScenario =
                this.ToScenarioWasteAmount.Residual + this.ToScenarioWasteAmount.Separated
            { this with
                IsSeparated     = { this.IsSeparated with Scenario = false }
                Amount          = { this.Amount with Scenario = Some newSeparatedScenario.Value }
                AmountResidual  = { this.AmountResidual with Scenario = Some newResidualScenario.Value }
            }
        // switch from disposal to separating this waste stream
        else
            let newResidualScenario =
                // depending on the current status, separating a stream means something different
                if this.ToCurrentWasteAmount.Separated > WasteAmount.Zero then
                    this.ToCurrentWasteAmount.Residual
                else
                    WasteAmount.Zero
            let differenceResidualScenario = this.ToScenarioWasteAmount.Residual - newResidualScenario
            let newSeparatedScenario =
                max WasteAmount.Zero (this.ToScenarioWasteAmount.Separated + differenceResidualScenario)
            { this with
                IsSeparated     = { this.IsSeparated with Scenario = true }
                Amount          = { this.Amount with Scenario = Some newSeparatedScenario.Value }
                AmountResidual  = { this.AmountResidual with Scenario = Some newResidualScenario.Value }
            }

    member this.UpdateScenario newValue =
        if this.IsSeparated.Scenario then
            { this with
                Amount = { this.Amount with Scenario = Some newValue } }
        else
            { this with
                AmountResidual = { this.AmountResidual with Scenario = Some newValue } }
