[<RequireQualifiedAccess>]
module ImpactChecker.PagePrint

open Shared

open PreZero
open External
open Fable.React
open Fable.React.Props
open Fable.Recharts
open Fable.Recharts.Props
open Fulma

type private PrintResults =
    {
        OwnName          : string
        OwnWasteAmount   : WastePerStream
        OwnImpact        : CalculatedImpact
        ComparisonName   : string
        ComparisonImpact : CalculatedImpact
    }

module private PrintContent =
    let calculatedResult (projectData: string * GridRowData list) (comparisonData: (ProjectId option * string * WastePerStream) option) (sectorId: SectorId) (staticContent: StaticContent) =
        let ownProjectName, ownWsData = projectData

        let ownWasteAmount : WastePerStream =
            ownWsData
            |> List.map (fun gridData -> gridData.WasteStreamId, gridData.ToWasteAmount)
            |> Map.ofList

        let ownImpact : CalculatedImpact =
            ownWasteAmount
            |> Calculation.calculateImpact staticContent

        let comparedProjectName, comparedImpact =
            match comparisonData with
            | Some (projectId, projectName, wsData) ->
                let comparedProjectImpact =
                    wsData
                    |> Calculation.calculateImpact staticContent

                projectName, comparedProjectImpact
            | None ->
                // if no comparison project is specified, use sector benchmark data for comparison
                let benchmarkImpact =
                    Calculation.calculateSectorImpact
                        staticContent
                        sectorId
                        staticContent.Sectors
                        (Calculation.sumWastePerStream ownWasteAmount)

                "Sectorgemiddelde", benchmarkImpact

        {
            OwnName          = ownProjectName
            OwnWasteAmount   = ownWasteAmount
            OwnImpact        = ownImpact |> CalculatedImpact.filterOutInseparableWaste staticContent.WasteStreams
            ComparisonName   = comparedProjectName
            ComparisonImpact = comparedImpact |> CalculatedImpact.filterOutInseparableWaste staticContent.WasteStreams
        }

    let chartResultByImpact (results: PrintResults) (staticContent: StaticContent) =
        let impactNames = staticContent.Impacts
        let comparisonImpact (impactId: ImpactId) =
            results.ComparisonImpact.ByImpactId
            |> Array.find (fun (i, v) -> i = impactId)
            |> snd
            |> fun m -> m.Float

        let data =
            results.OwnImpact.ByImpactId
            |> Array.map (fun (i, m) ->
                 {|
                     impactName = (impactNames.Item i).Name
                     ownImpact = m.Float
                     comparison = comparisonImpact i
                 |} )
            |> Array.sortByDescending (fun d -> d.ownImpact)

        let titleOwnImpact = results.OwnName |> function | "" -> "Jouw impact" | name -> name
        let titleComparison = results.ComparisonName |> function | "" -> "Sectorgemiddelde" | name -> name

        div [ Style [ PaddingTop "0.5cm"; Margin "auto auto"; FontSize "10px" ] ] [
            barChart
                [ Chart.Margin { top = 5.; bottom = 150.; right = 5.; left = 0. }
                  Chart.Width 700.
                  Chart.Height 400.
                  Chart.Data data ]
                [ xaxis [ Cartesian.DataKey "impactName"
                          Cartesian.Type "category"
                          Cartesian.TickLine false
                          Cartesian.Height 20.
                          Cartesian.Custom ("angle", -37.)
                          Cartesian.Custom ("textAnchor", "end")
                          Cartesian.Custom ("interval", 0) ] []
                  yaxis [ Cartesian.Type "number"
                          Cartesian.TickLine false
                          Cartesian.Tick true
                          Cartesian.AxisLine false
                          Cartesian.Unit "€" ] []
                  legend [ Legend.Align "right"
                           Legend.VerticalAlign "top"
                           Legend.IconType ShapeType.Circle ] []
                  bar [ Cartesian.DataKey "ownImpact"
                        Cartesian.BarSize 10.
                        Cartesian.IsAnimationActive false
                        Cartesian.Name (titleOwnImpact + " (EUR)")
                        Fable.React.Props.Fill PreZeroColor.RoyalBlue.HexCode ] []
                  bar [ Cartesian.DataKey "comparison"
                        Cartesian.BarSize 10.
                        Cartesian.IsAnimationActive false
                        Cartesian.Name (titleComparison + " (EUR)")
                        Fable.React.Props.Fill PreZeroColor.LightBlue.HexCode ] []
                ]
        ]

    let chartResultByWasteStream
        (results: PrintResults)
        (staticContent: StaticContent) =
        let wsNames = staticContent.WasteStreams
        let comparisonImpact (wsId: WasteStreamId) =
            results.ComparisonImpact.ByWasteStreamId
            |> Array.find (fun (i, v) -> i = wsId)
            |> snd
            |> fun m -> m.Float

        let data =
            results.OwnImpact.ByWasteStreamId
            |> Array.map (fun (i, m) ->
                 {|
                     wsName = (wsNames.Item i).Name
                     ownImpact = m.Float
                     comparison = comparisonImpact i
                 |} )
            |> Array.sortByDescending (fun d -> d.ownImpact)

        let titleOwnImpact = results.OwnName |> function | "" -> "Jouw impact" | name -> name
        let titleComparison = results.ComparisonName |> function | "" -> "Sectorgemiddelde" | name -> name

        div [ Style [ PaddingTop "0.5cm"; Margin "auto auto"; FontSize "10px" ] ] [
            barChart
                [ Chart.Margin { top = 5.; bottom = 80.; right = 5.; left = 0. }
                  Chart.Width 700.
                  Chart.Height 250.
                  Chart.Data data ]
                [ xaxis [ Cartesian.DataKey "wsName"
                          Cartesian.Type "category"
                          Cartesian.TickLine false
                          Cartesian.Height 20.
                          Cartesian.Custom ("angle", -37.)
                          Cartesian.Custom ("textAnchor", "end")
                          Cartesian.Custom ("interval", 0) ] []
                  yaxis [ Cartesian.Type "number"
                          Cartesian.TickLine false
                          Cartesian.Tick true
                          Cartesian.AxisLine false
                          Cartesian.Unit "€" ] []
                  legend [ Legend.Align "right"
                           Legend.VerticalAlign "top"
                           Legend.IconType ShapeType.Circle ] []
                  bar [ Cartesian.DataKey "ownImpact"
                        Cartesian.BarSize 10.
                        Cartesian.IsAnimationActive false
                        Cartesian.Name (titleOwnImpact + " (EUR)")
                        Fable.React.Props.Fill PreZeroColor.RoyalBlue.HexCode ] []
                  bar [ Cartesian.DataKey "comparison"
                        Cartesian.BarSize 10.
                        Cartesian.IsAnimationActive false
                        Cartesian.Name (titleComparison + " (EUR)")
                        Fable.React.Props.Fill PreZeroColor.LightBlue.HexCode ] []
                ]
        ]

    let tableInputData (clientData: GridRowData list) =
        Table.table [ Table.IsStriped; Table.IsFullWidth ] [
            thead [ ] [
                tr [ ] [
                    th [ Class "has-text-left" ] [ str "AFVALSTROOM" ]
                    th [ Class "has-text-right" ] [ str "HOEVEELHEID GESCHEIDEN" ]
                    th [ Class "has-text-right" ] [ str "HOEVEELHEID IN BEDRIJFSAFVAL" ]
                ]
            ]
            tbody [ ] [
                for row in clientData do
                    tr [ ] [
                        td [ ] [ str row.Name ]
                        td [ Style [ TextAlign TextAlignOptions.Right ] ]
                            [ str (sprintf "%A %A" (row.Amount |> Option.defaultValue 0.) row.Unit) ]
                        td [ Style [ TextAlign TextAlignOptions.Right ] ]
                            [ str (sprintf "%A %A" (row.AmountResidual |> Option.defaultValue 0.) row.UnitResidual) ]
                    ]
            ]
        ]

    let resultCard value units =
        Card.card [ CustomClass "box info-card"
                    Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ]
                    Props [ Style [ MaxWidth "250px"; Margin "auto" ] ] ]
            [ h2 [ Style [ MarginBottom "unset"; FontSize "20px" ] ]
                [ str value ]
              Content.content [ Content.Size IsSmall ]
                [ str units ] ]


    // the input variables are messy here; ideally projectName can be combined into calculatedImpacts (with a new field attribute) etc.
    let fullPage
        (projectName: string)
        (clientData: GridRowData list)
        (results: PrintResults)
        (staticContent: StaticContent) =

        let title, subtitle =
            match projectName with
            | "" ->
                "PreZero Impact Checker",
                "Datum: " + System.DateTime.Now.ToShortDateString()
            | name ->
                "PreZero Impact Checker: " + name,
                "vs. " + results.ComparisonName

        // result cards summary
        let resultSummary =
            let resultEmissions =
                Calculation.co2Values results.OwnImpact
                |> fun v -> v * 1.</kg>
                |> System.Math.Round |> Rounding.formatNL
            let fractionRecycled (data : WastePerStream) : Percentage =
                let totalWeight =
                    data
                    |> Map.map (fun _ waste -> waste.Separated)
                    |> Map.fold (fun state key value -> state + value) (Kg 0.<kg>)
                let recycledWeight =
                    data
                    |> Map.filter (fun key _ -> key <> PreZero.CalcSetting.residualWasteStreamId)
                    |> Map.fold (fun state key waste -> state + waste.Separated) (Kg 0.<kg>)
                let fraction= (recycledWeight / totalWeight)
                if System.Double.IsNaN fraction then Percentage 0. else Percentage fraction

            let resultFractionRecylcled =
                fractionRecycled results.OwnWasteAmount
                |> fun (Percentage x) -> Rounding.roundTwoSignificantDigits (x * 100.)
                |> Rounding.formatNL
                |> sprintf "%s%%"

            let resultImpactMonetised =
                results.OwnImpact
                |> Calculation.totalImpact
                |> System.Math.Round
                |> Rounding.formatNL
                |> sprintf "€%s"

            {|
                Emissions        = resultEmissions
                FractionRecycled = resultFractionRecylcled
                ImpactMonetised  = resultImpactMonetised
            |}

        div [ Style [ Padding "0.5cm" ] ] [

            // Page title
            div [ Style [ Padding "0.5cm" ] ] [
                h1 [ Style [ FontSize "26px" ] ]
                   [ str title ]
                h2 [ Style [ FontSize "20px" ] ]
                   [ str subtitle ]
            ]

            // Input data
            div [ Style [ Padding "0.5cm" ] ] [
                h2 [ Style [ FontSize "20px" ] ]
                    [ str "1. Ingevoerde gegevens" ]
                tableInputData clientData
            ]

            br [ ]

            // Cards result summary
            div [ ClassName "print-full-page"; Style [ Padding "0.5cm" ] ] [
                h2 [ Style [ FontSize "20px" ] ]
                    [ str "2. Jouw impact" ]
                div [ Style [ Padding "1cm" ] ] [
                    Columns.columns [ Columns.Props [ Style [ JustifyContent "space-between" ] ] ] [
                        Column.column [ ] [ resultCard resultSummary.Emissions "kg CO2-uitstoot" ]
                        Column.column [ ] [ resultCard resultSummary.FractionRecycled "van je afval gerecycled" ]
                        Column.column [ ] [ resultCard resultSummary.ImpactMonetised "Totale impact in euro’s" ]
                    ]
                ]
            ]

            // Results per impact
            div [ ClassName "print-full-page"; Style [ Padding "0.5cm" ] ] [
                h2 [ Style [ FontSize "20px" ] ]
                    [ str "3. Negatieve milieu-impact in euro's per milieu-indicator" ]
                h3 [ Style [ FontSize "20px" ]  ] [ str "3.1 Grafiek" ]
                chartResultByImpact results staticContent
                h3 [ Style [ FontSize "20px" ]  ] [ str "3.2 Tabel" ]
                ImpactChecker.Components.tableResultPerImpact results.OwnImpact staticContent
            ]

            // Results per wastestream
            div [ Style [ Padding "0.5cm" ] ] [
                h2 [ Style [ FontSize "20px" ] ]
                    [ str "4. Negatieve milieu-impact in euro's per afvalstroom" ]
                h3 [ Style [ FontSize "20px" ]  ] [ str "4.1 Grafiek" ]
                chartResultByWasteStream results staticContent
                h3 [ Style [ FontSize "20px" ]  ] [ str "4.2 Tabel" ]
                ImpactChecker.Components.tableResultPerWastestream
                    (projectName, results.OwnImpact)
                    (results.ComparisonName, results.ComparisonImpact)
                    staticContent
            ]


        ]

open PrintContent

/// The id of the HTML element that is to be mounted with contents for pdf print.
let printElementId = "elementprint"

let printPage (projectData: string * GridRowData list) (comparisonData: (ProjectId option * string * WastePerStream) option) (sectorId: SectorId) (staticContent: StaticContent) =

    let projectName, wasteAmountData = projectData
    let wasteAmountDataExclConstruction =
        wasteAmountData |> List.filter (fun ws -> not ws.IsConstructionOnly)
    let calculatedImpacts = calculatedResult projectData comparisonData sectorId staticContent

    let elementPrint =
        Browser.Dom.document.getElementById printElementId

    let contents = fullPage projectName wasteAmountDataExclConstruction calculatedImpacts staticContent


    printfn "contents = %A" (contents.ToString())

    // TODO
    // replace with just simple <style>'s
    let head = Browser.Dom.document.head.outerHTML
    let window = Browser.Dom.window.``open``("", "")
    ReactDom.render (contents, elementPrint,
        fun () ->
            window.document.write (sprintf "<html>%s<body>" head)
            window.document.write elementPrint.innerHTML
            window.document.write "</body></html>"
            window.document.close()

            Fable.Core.JS.setTimeout (fun () -> // make a small delay allow css to render
                window.focus()
                window.print()
                window.close()
                ) 2000 |> ignore

            // this does not seem to work yet :(
            elementPrint.removeChild |> ignore
        )
