namespace Shared

type [<Measure>] kg
type [<Measure>] ton
type [<Measure>] kton
type [<Measure>] eur
type [<Measure>] item
type [<Measure>] l //only for water use

type Unit =
| Kg
| Ton
| Kton
with
    member this.ToString =
        match this with
        | Kg -> "kg"
        | Ton -> "ton"
        | Kton -> "kiloton"
    member this.Abbr =
        match this with
        | Kg -> "kg"
        | Ton -> "ton"
        | Kton -> "kton"

module Units =
    let all = [Kg; Ton; Kton]

    let unitFromString string : Unit option =
        match string with
        | "kg"      -> Some Kg
        | "ton"     -> Some Ton
        | "kton" | "kiloton" -> Some Kton
        | _ -> None

    let unitFromStringDefaultKg string : Unit =
        unitFromString string |> Option.defaultValue Kg

type WasteStreamId  = WasteStreamId of int
    with member this.Value = this |> function | WasteStreamId id -> id

type SectorId       = SectorId of int
    with member this.Value = this |> function SectorId id -> id
         /// Default sector: Nederland (gemiddeld), SectodId 1
         static member DefaultSectorId = SectorId 1

type ImpactId       = ImpactId of int
    with member this.Value = this |> function ImpactId id -> id

type DriverId       = DriverId of int
    with member this.Value = this |> function DriverId id -> id

type ProjectId      = ProjectId of int
    with member this.Value = this |> function ProjectId id -> id

type ImpactPerKg = {
    ImpactId    : ImpactId
    ImpactPerKg : float<eur/kg>
}

type WasteStreamMultipliers = Map<WasteStreamId, (ImpactPerKg*ImpactPerKg) list>

type StaticContent = {
    WasteStreams              : Map<WasteStreamId, WasteStream>
    Impacts                   : Map<ImpactId, Impact>
    Drivers                   : Driver list
    Sectors                   : Map<SectorId, SectorData>
    WasteStreamMultipliers    : WasteStreamMultipliers
    }
and WasteStream = {
    Id                        : WasteStreamId
    Name                      : string
    Description               : string
    Public                    : bool
    Order                     : int
    IsSeparatable             : bool
    IsConstruction            : bool
    }
with
    member this.IsConstructionOnly =
        this.IsConstruction && not this.IsSeparatable
and Impact = {
    Id                        : ImpactId
    Name                      : string
    Interpretation            : InterpretationOptions
    }
and Driver = {
    ImpactId                  : ImpactId
    Name                      : string
    Description               : string
    }
and SectorData = {
    Id                        : SectorId
    Name                      : string
    WastePerStream            : WastePerStream
    }

and WastePerStream            = Map<WasteStreamId, WasteGroupTriple<WasteAmount>>

/// Interpretation of result
and InterpretationOptions = {
    Small    : Interpretation
    Medium   : Interpretation
    Large    : Interpretation
    IconCode : string
} with
    /// Interpretate the calculated result (to, say, 3x flight AMS to London) based on its value.
    member this.ResultInterpretated (resultValue: float) =
        match resultValue * 1.<eur>, this with
        | v, i when v * i.Large.ConversionFactorFromEur > 1.0<item> ->
            {|
                NrOfItem = v * i.Large.ConversionFactorFromEur * 1.</item>
                Description = i.Large.Name
            |}
        | v, i when v * i.Medium.ConversionFactorFromEur > 1.0<item> ->
            {|
                NrOfItem = v * i.Medium.ConversionFactorFromEur * 1.</item>
                Description = i.Medium.Name
            |}
        | v, i ->
            {|
                NrOfItem = v * i.Small.ConversionFactorFromEur * 1.</item>
                Description = i.Small.Name
            |}

and Interpretation = {
    Name                    : string
    ConversionFactorFromEur : float<item/eur>
}

and WasteAmount =
    | Kg    of float<kg>
    | Ton   of float<ton>
    | Kton  of float<kton>
    member this.Value =
        match this with
        | Kg x      -> x/1.<kg>
        | Ton x     -> x/1.<ton>
        | Kton x    -> x/1.<kton>
    member this.Unit =
        match this with
        | Kg _      -> Unit.Kg
        | Ton _     -> Unit.Ton
        | Kton _    -> Unit.Kton
    static member (+) (wasteAmount1, wasteAmount2) =
        match wasteAmount1, wasteAmount2 with
        | Kg x, Kg y     -> Kg (x + y)
        | Kg x, Ton y    -> Ton (x / (1000.<kg/ton>) + y)
        | Kg x, Kton y   -> Kton (x / (1000000.<kg/kton>) + y)
        | Ton x, Kg y    -> Ton (x + y / (1000.<kg/ton>))
        | Ton x, Ton y   -> Ton (x + y)
        | Ton x, Kton y  -> Kton (x / (1000.<ton/kton>) + y)
        | Kton x, Kg y   -> Kton (y / (1000000.<kg/kton>) + x)
        | Kton x, Ton y  -> Kton (y / (1000.<ton/kton>) + x)
        | Kton x, Kton y -> Kton (x + y)
    static member (-) (wasteAmount1 : WasteAmount, wasteAmount2 : WasteAmount) =
        let negativeWasteAmount2 = wasteAmount2.multiply (-1.)
        wasteAmount1 + negativeWasteAmount2
    static member (/) (wasteAmount1, wasteAmount2) : float =
        match wasteAmount1, wasteAmount2 with
        | Kg x, Kg y     -> x / y
        | Kg x, Ton y    -> x / (1000.<kg/ton>*y)
        | Kg x, Kton y   -> (x / (1000000.<kg/kton>* y))
        | Ton x, Kg y    -> (x * 1000.<kg/ton>/y)
        | Ton x, Ton y   -> (x / y)
        | Ton x, Kton y  -> (x / (1000.<ton/kton>* y))
        | Kton x, Kg y   -> (x * (1000000.<kg/kton>) /y)
        | Kton x, Ton y  -> (x * (1000.<ton/kton>) / y)
        | Kton x, Kton y -> (x / y)
    member this.multiply (factor : float) : WasteAmount =
        match this with
        | Kg x      -> Kg (x * factor)
        | Ton x     -> Ton (x * factor)
        | Kton x    -> Kton (x * factor)
    static member Zero with get() = (Kg 0.<kg>)
    static member convertToWasteAmount (unit : Unit) (amount: float) =
        match unit with
        | Unit.Kg   -> (amount * 1.<kg> |> Kg)
        | Unit.Ton  -> (amount * 1.<ton> |> Ton)
        | Unit.Kton -> (amount * 1.<kton> |> Kton)
    static member CreateFromOption (amountOption: float option) (unit : Unit) =
        let amount = amountOption |> Option.defaultValue 0.
        WasteAmount.convertToWasteAmount unit amount
    static member ConvertUnit (newUnit : Unit) (wasteAmount : WasteAmount) =
        let factor =
            match wasteAmount.Unit, newUnit with
            | Unit.Kg, Unit.Kton -> 1./1000000.
            | Unit.Kg, Unit.Ton | Unit.Ton, Unit.Kton -> 1./1000.
            | Unit.Kg, Unit.Kg | Unit.Ton, Unit.Ton | Unit.Kton, Unit.Kton -> 1.
            | Unit.Kton, Unit.Kg -> 1000000.
            | Unit.Ton, Unit.Kg | Unit.Kton, Unit.Ton -> 1000.
        wasteAmount.Value * factor |> WasteAmount.convertToWasteAmount newUnit
    static member ConvertToKg (wasteAmount : WasteAmount) =
        match wasteAmount with
        | Kg amount     -> amount
        | Ton amount    -> amount * 1000.<kg/ton>
        | Kton amount   -> amount * 1000000.<kg/kton>

and SeparatedResidualPair<'A> = {
    Separated   : 'A
    Residual    : 'A
}

and WasteGroupTriple<'A> = {
    Separated       : 'A
    Residual        : 'A
    Construction    : 'A
}

and Percentage = Percentage of float
    with member this.ToString = match this with Percentage value -> (value * 100. |> string) + "%"


module Interpretation =
    let create name conversion = {
        Name = name
        ConversionFactorFromEur = conversion * 1.<item/eur>
    }


type Email = {
    Name            : string
    EmailAddress     : string
    Message         : string
}


type UserInput = {
    Name                : string
    Email               : string
}

type User = {
    Id                  : int
    Name                : string
    Email               : string
    Password            : string
    EmailVerified       : bool
}
with
    member self.Input = {
        Name    = self.Name
        Email   = self.Email
    }

type Project = {
    Id                  : ProjectId option // option because initially no Id is generated
    Name                : string
    Sector              : SectorId
    WasteData           : WastePerStream
    CustomResidualWaste : bool
}

type ProjectInfo = {
    Name                    : string
    Sector                  : SectorId
    CustomResidualWaste     : bool
    IsManualConstruction    : bool
}

type UserProfile = {
    UserId      : int
    Name        : string
    Email       : string
    Projects    : Map<ProjectId, ProjectInfo>
}

/// A type that specifies the communication protocol between client and server
/// to learn more, read the docs at https://zaid-ajaj.github.io/Fable.Remoting/src/basics.html
type IStaticContentApi =
    { getStaticContent : unit -> Async<StaticContent> }

type ISendEmailApi =
    { sendEmail : Email -> Async<unit> }

type IUserProfileApi =
    { tryGetUserProfile : unit -> Async<UserProfile option>
      deleteProfile     : UserProfile -> Async<Result<unit,exn>>}

type IAuthorisedApi =
    { doSomethingAuthorised : unit -> Async<unit> }

type IProjectApi =
    { saveProject    : Project -> Async<Result<Project,exn>>
      getProjectList : unit -> Async<Result<Map<ProjectId,ProjectInfo>, exn>>
      getProjectData : ProjectId -> Async<Result<Project, exn>>
    }