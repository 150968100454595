module Components.FormField

open Fulma
open Fable.React
open Fable.React.Props


let standardField (labelOption : string option) content =
    let label =
          match labelOption with
          | Some label' -> Label.label [] [str label']
          | None -> nothing
    Field.div [] [
           label
           Control.div [] [ content ]
        ]

let string
    (name : string option)
    (placeHolder : string)
    (input : string)
    (iHTMLPropsList : IHTMLProp list) =

    let content =
        Input.text [
            Input.Value input
            Input.Placeholder placeHolder
            Input.Props iHTMLPropsList
        ]

    standardField name content

let email
    (name : string option)
    (placeHolder : string)
    (input : string)
    (iHTMLPropsList : IHTMLProp list) =

    let content =
        Input.email [
            Input.Value input
            Input.Placeholder placeHolder
            Input.Props iHTMLPropsList
        ]

    standardField name content

let number
    (name : string option)
    (placeHolder : string)
    (input : string)
    (iHTMLPropsList : IHTMLProp list) =

    let content =
        Input.number [
            Input.Value input
            Input.Placeholder placeHolder
            Input.Props iHTMLPropsList
        ]

    standardField name content


let textArea
    (name : string option)
    (placeHolder : string)
    (input : string)
    (iHTMLPropsList : IHTMLProp list) =

    let content =
        Textarea.textarea [
            Textarea.Value input
            Textarea.Placeholder placeHolder
            Textarea.Props iHTMLPropsList
        ] []

    standardField name content

