module Shared.Route

/// Defines how routes are generated on server and mapped from client
let builder typeName methodName =
    sprintf "/api/%s/%s" typeName methodName

// login and logout functionality, only update when strictly necessary!
let login    = "/login"
let register = "/registratie"
let logout   = "/logout"
